<template>
  <collection-schedule-query
    :collection-id="$route.params.id"
    @data="setSchedule"
  >
    <template slot-scope="{}">
      <div>
        <flyte-sticky-notification v-if="showDateNotification" warning>
          <span slot="text">
            Uh oh! Looks like you don't have any upcoming dates in your
            schedule.
          </span>
          <el-button
            slot="button"
            class="button-blue"
            :disabled="!$can('pub_edit_mediakit_settings_or_schedules')"
            @click="$emit('isEditing', 'schedule')"
          >
            Add to schedule
          </el-button>
        </flyte-sticky-notification>

        <flyte-sticky-notification v-if="showReachesNotification" warning>
          <span slot="text">
            Uh oh! Looks like you haven't entered a reach number.
          </span>
          <el-button
            slot="button"
            class="button-blue"
            :disabled="!$can('pub_edit_mediakit_settings_or_schedules')"
            @click="$emit('isEditing', true)"
          >
            Add Reach
          </el-button>
        </flyte-sticky-notification>

        <flyte-sticky-notification
          v-if="productsMissingFulfillments.length > 0"
          warning
        >
          <span slot="text">
            Uh oh! Looks like you're missing a fulfillment method on an enabled
            variant in&nbsp;
            <span class="font-bold">
              {{ productsMissingFulfillmentsToString }}
            </span>
            .
          </span>

          <el-button
            slot="button"
            class="button-blue"
            @click="$emit('view-product', productsMissingFulfillments[0])"
          >
            View
          </el-button>
        </flyte-sticky-notification>

        <flyte-sticky-notification
          v-if="productsMissingRates.length > 0"
          warning
        >
          <span slot="text">
            Uh oh! Looks like you're missing rates on an enabled variant
            in&nbsp;
            <span class="font-bold">
              {{ productsMissingRatesToString }}
            </span>
            .
          </span>

          <el-button
            slot="button"
            class="button-blue"
            @click="$emit('view-product', productsMissingRates[0])"
          >
            View
          </el-button>
        </flyte-sticky-notification>
      </div>
    </template>
  </collection-schedule-query>
</template>

<script>
import FlyteStickyNotification from '@/components/Core/FlyteStickyNotification';
import { attention } from '@/vendor/icons';
import { CollectionScheduleQuery } from '@/components/Queries';
import { map } from 'lodash';

export default {
  components: {
    CollectionScheduleQuery,
    FlyteStickyNotification
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    reaches: {
      type: Array,
      required: true
    },

    collection: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      dates: null,
      attention
    };
  },

  computed: {
    productsMissingFulfillmentsToString() {
      let str = '';

      let productNames = map(this.productsMissingFulfillments, 'name');

      for (let i = 0; i < productNames.length; i++) {
        if (i > 0) {
          if (i === productNames.length - 1) {
            str += ' and ';
          } else if (i > 0) {
            str += ', ';
          }
        }

        str += productNames[i];
      }

      return str;
    },

    productsMissingRatesToString() {
      let str = '';

      let productNames = map(this.productsMissingRates, 'name');

      for (let i = 0; i < productNames.length; i++) {
        if (i > 0) {
          if (i === productNames.length - 1) {
            str += ' and ';
          } else if (i > 0) {
            str += ', ';
          }
        }

        str += productNames[i];
      }

      return str;
    },

    productsMissingFulfillments() {
      if (!this.collection) {
        return [];
      }

      let products = [];

      for (const product of this.collection.products) {
        if (
          !product.variants ||
          !product.is_enabled ||
          !product.fulfillmentMethods.length
        ) {
          continue;
        }

        for (const variant of product.variants) {
          // If variant is enabled and fulfillments array is unset or empty
          if (
            variant.is_enabled &&
            (!variant.attributes.Fulfillments ||
              !variant.attributes.Fulfillments.length)
          ) {
            products.push(product);
            // we only need 1 of this product's variants to be missing a fulfillment to qualify
            break;
          }
        }
      }

      return products;
    },

    productsMissingRates() {
      if (!this.collection) {
        return [];
      }

      let products = [];

      for (const product of this.collection.products) {
        if (!product.variants || !product.is_enabled) {
          continue;
        }

        for (const variant of product.variants) {
          if (
            variant.is_enabled &&
            (!variant.rateSheets || !variant.rateSheets.length)
          ) {
            products.push(product);
            // we only need 1 of this product's variants to be missing a fulfillment to qualify
            break;
          }
        }
      }

      return products;
    },

    showDateNotification() {
      return this.dates && this.dates.length === 0;
    },

    showReachesNotification() {
      return (
        this.reaches &&
        this.collection.is_reach_applicable &&
        !this.property.is_digital_content &&
        !this.hasReach
      );
    },

    hasReach() {
      if (this.reaches.length === 0) return false;

      for (let reach of this.reaches) {
        if (reach.value > 0) return true;
      }

      return false;
    }
  },

  methods: {
    setSchedule(data) {
      this.dates = data?.upcomingDates;
    }
  }
};
</script>
