<template>
  <div class="variant-default-creative-column">
    <template v-if="variant.id">
      <a @click="isShowingCreativeDialog = true">
        <span v-if="variant.defaultCreative" class="text-green">
          <icon :icon="checkIcon" />
          Default Creative Configured
        </span>
        <span v-else>Configure Default Creative</span>
      </a>

      <variant-mutation>
        <template #default="{isSaving, saveVariantDefaultCreative}">
          <confirm-dialog
            v-if="isShowingCreativeDialog"
            :is-saving="isSaving"
            title="Configure Default Ad"
            disabled
            cancel-text="Close"
            @close="isShowingCreativeDialog = false"
          >
            <div class="mb-5 text-bold text-dark-silver text-base">
              <icon :icon="infoIcon" class="mr-1" />
              Your default ad will serve if there are no higher priority ads
              available.
            </div>
            <div v-if="$can('manage_digital_properties')" class="my-5">
              <h3>Ad</h3>
              <template v-if="ad">
                <div>
                  <a :href="adNovaUrl" target="_blank">
                    View {{ ad.ref }} in Nova
                  </a>
                </div>
                <template v-if="ad.digitalVendorFlight">
                  <div>
                    <a target="_blank" :href="ad.digitalVendorFlight.nova_url">
                      View External {{ ad.digitalVendorFlight.id }} in Nova
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      :href="ad.digitalVendorFlight.resource_url"
                    >
                      View Flight {{ ad.digitalVendorFlight.external_id }} in
                      Kevel
                    </a>
                  </div>
                </template>
                <template v-else>
                  Not yet synced to Kevel
                </template>
              </template>
              <template v-else>
                N/A (Refresh page after saving to view)
              </template>
            </div>
            <ad-custom-form
              v-model="formInput.formResponse.fieldResponses"
              :form="mediumType.defaultCreativeForm"
              :is-saving="isSaving"
              @save="saveVariantDefaultCreative(variant.id, formInput)"
            />
          </confirm-dialog>
        </template>
      </variant-mutation>
    </template>
    <template v-else>
      <span class="italic text-dark-silver">First save this variant</span>
    </template>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import AdCustomForm from '@/components/Ads/AdCustomForm';
import VariantMutation from '@/components/Mutations/Supplier/Mediakit/VariantMutation';

import { infoCircled as infoIcon, ok as checkIcon } from '@/vendor/icons';

export default {
  components: {
    VariantMutation,
    AdCustomForm,
    ConfirmDialog
  },
  props: {
    variant: {
      type: Object,
      required: true
    },
    mediumType: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowingCreativeDialog: false,

      formInput: {
        formResponse: {
          form_id: this.mediumType.defaultCreativeForm.id,
          fieldResponses:
            (this.variant.defaultCreative &&
              this.variant.defaultCreative.formResponse &&
              this.variant.defaultCreative.formResponse.fieldResponses) ||
            []
        }
      },

      // Icons
      checkIcon,
      infoIcon
    };
  },

  computed: {
    ad() {
      return this.variant.defaultCreative?.ad;
    },
    adNovaUrl() {
      return `${window._env.API_URL}/nova/resources/ads/${this.ad.id}`;
    }
  }
};
</script>
