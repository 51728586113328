<script>
import { saveVariantDefaultCreative } from '@/graphql/mediakit/variantMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';
import { formatFormResponseInput } from '@/utils/helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        saveVariantDefaultCreative(id, input) {
          input = {
            ...input,
            formResponse: formatFormResponseInput(input.formResponse)
          };

          return this.post(saveVariantDefaultCreative, { id, input });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
