<template>
  <div class="mediakit-product-settings">
    <div class="fulfillment-methods">
      <label-content
        label="Fulfillment Methods"
        help="Add fulfillment methods to indicate requirements for the buyer after purchasing this product. For example, submitting a piece of creative for a newspaper ad, or shipping flyers to a particular address for distribution."
        label-class="font-semibold text-lg"
      />

      <div class="fulfillment-list margin-top">
        <transition-group
          name="slideLeft"
          class="flex flex-wrap animate-position"
        >
          <div
            v-for="fulfillment in fulfillmentList"
            :key="`fulfillment-${fulfillment.id}`"
            class="fulfillment-item block p-3"
          >
            <fulfillment-method
              :is-disabled="isDisabled"
              :fulfillment="fulfillment"
              @edit="fulfillmentToEdit = fulfillment"
              @remove="removeFulfillmentMethod(fulfillment)"
            />
          </div>
        </transition-group>
      </div>

      <div class="margin-top">
        <el-popover
          trigger="manual"
          placement="right"
          :value="!!fulfillmentToEdit"
          @hide="fulfillmentToEdit = false"
        >
          <el-button
            slot="reference"
            class="button-white-blue"
            :disabled="isDisabled"
            @click="fulfillmentToEdit = {}"
          >
            Add New Method
          </el-button>

          <fulfillment-method-settings
            v-if="fulfillmentToEdit"
            v-click-outside="() => (fulfillmentToEdit = false)"
            :is-disabled="isDisabled"
            :fulfillment="fulfillmentToEdit"
            @change="saveFulfillmentMethod"
            @close="fulfillmentToEdit = false"
          />
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, extend, find, uniqBy } from 'lodash';
import { remove } from '@/utils/helpers';

import FulfillmentMethod from './FulfillmentMethod';
import FulfillmentMethodSettings from './FulfillmentMethodSettings';
import LabelContent from '@/components/Core/LabelContent';

export default {
  components: {
    FulfillmentMethod,
    FulfillmentMethodSettings,
    LabelContent
  },
  props: {
    isDisabled: Boolean,
    property: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      fulfillmentToEdit: false,
      fulfillmentList: cloneDeep(this.product.fulfillmentMethods)
    };
  },

  methods: {
    change(data) {
      this.$emit('update:product', { ...this.product, ...data });
    },

    removeFulfillmentMethod(fulfillment) {
      remove(this.fulfillmentList, { id: fulfillment.id });
      this.change({ fulfillmentMethods: this.fulfillmentList });
    },

    saveFulfillmentMethod(fulfillment) {
      // If we are editing an existing fulfillment method, overwrite the one in the list
      if (this.fulfillmentToEdit.id) {
        let originalFulfillment = find(this.fulfillmentList, {
          id: this.fulfillmentToEdit.id
        });

        extend(originalFulfillment, fulfillment);
      } else {
        // If we were creating a new fulfillment method, append the new one to the list
        this.fulfillmentList.push(fulfillment);
      }

      this.fulfillmentList = uniqBy(this.fulfillmentList, 'id');

      // Notify the parent of the change
      this.change({ fulfillmentMethods: this.fulfillmentList });

      this.fulfillmentToEdit = false;
    },

    validate(callback) {
      return this.$refs.form.validate(callback);
    }
  }
};
</script>
