<script>
import { getCollectionInventoryReport } from '@/graphql/booking/getInventory.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    collectionId: {
      type: String,
      required: true
    }
  },

  query() {
    return {
      query: getCollectionInventoryReport,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        collection_id: this.collectionId
      }
    };
  }
};
</script>
