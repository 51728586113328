<template>
  <div id="the-mediakit-sidebar" class="standard-sidebar z-40">
    <div class="sidebar-header">
      <h2>Media Kit</h2>
      <div class="header-nav">
        <router-link to="/supplier/mediakit">
          <icon
            :icon="arrowCircleODown"
            :rotation="90"
            class="text-dark-silver"
          />
          <span class="text text-dark-silver ml-2">All Media</span>
        </router-link>
      </div>
    </div>

    <mediakit-query @data="mediums = $event.mediums">
      <template slot-scope="{ isLoading }">
        <el-menu
          v-if="!isLoading"
          class="sidebar-menu"
          :collapse="isCollapsed"
          :collapse-transition="false"
          :unique-opened="true"
          :default-active="active.index"
          :default-openeds="active.opened"
        >
          <template v-for="(item, index) in nav">
            <nav-bar-item
              :key="`sidebar-menu-${item.name}`"
              :item="item"
              :index="'' + index"
              :collapsed="isCollapsed"
            />
          </template>
        </el-menu>

        <loader-menu v-else class="pad" />
      </template>
    </mediakit-query>
  </div>
</template>

<script>
import NavBarItem from '@/components/Core/Layout/NavBarItem';
import { LoaderMenu } from '@/components/Core/Loaders';
import { MediakitQuery } from '@/components/Queries';

import {
  arrowCircleODown,
  ban,
  billboard,
  caretRight,
  digital,
  flytedesk,
  folderEmpty,
  folderOpenO,
  outdoor,
  print,
  social
} from '@/vendor/icons';

export default {
  components: {
    LoaderMenu,
    NavBarItem,

    // Queries
    MediakitQuery
  },

  data() {
    return {
      mediums: [],
      arrowCircleODown,
      isCollapsed: false
    };
  },

  computed: {
    /**
     * returns the index of the active menu item and an array of its parent submenu indexes that should be opened
     *
     * @returns {{index: string, opened: Array}}
     */
    active() {
      let index = '';
      let opened = [];

      for (let [m, medium] of this.nav.entries()) {
        if (medium.isActivePage) {
          let mid = `${m}`;

          index = mid;
          opened.push(mid);

          for (let [p, property] of medium.children.entries()) {
            if (property.isActivePage) {
              let pid = `${m}-${p}`;

              index = pid;
              opened.push(pid);

              for (let [c, collection] of property.children.entries()) {
                if (collection.isActivePage) {
                  let cid = `${m}-${p}-${c}`;

                  index = cid;
                  opened.push(cid);

                  break;
                }
              }

              // break property loop
              break;
            }
          }

          // break medium loop
          break;
        }
      }

      index += '-item';

      return {
        index,
        opened
      };
    },

    nav() {
      let menu = [];

      if (this.mediums) {
        let pageId = this.$route.params.id;

        for (let medium of this.mediums) {
          let mediumMenu = {
            name: medium.name,
            icon: folderEmpty,
            iconState: medium.is_enabled
              ? null
              : { icon: ban, class: 'text-red ml-2' },
            iconOpen: folderOpenO,
            iconPrefix: caretRight,
            isActivePage: medium.id === pageId,
            route: {
              name: 'supplier.mediakit.medium',
              params: { id: medium.id }
            },
            stateLabel: medium.is_enabled ? null : 'Disabled',
            children: []
          };

          for (let property of medium.properties) {
            let propertyMenu = {
              name: property.name,
              icon: folderEmpty,
              iconState: property.is_enabled
                ? null
                : { icon: ban, class: 'text-red ml-2' },
              iconOpen: folderOpenO,
              iconPrefix: caretRight,
              isActivePage: property.id === pageId,
              route: {
                name: 'supplier.mediakit.property',
                params: { id: property.id }
              },
              stateLabel: property.is_enabled ? null : 'Disabled',
              children: []
            };

            for (let collection of property.collections) {
              let collectionMenu = {
                name: collection.name,
                icon: this.getCollectionIcon(medium.typeEdges),
                iconState: collection.is_enabled
                  ? null
                  : { icon: ban, class: 'text-red ml-2' },
                isActivePage: collection.id === pageId,
                // If the menu item is on the third level without an expander icon,
                // we need a bit more margin
                isChild: true,
                route: {
                  name: 'supplier.mediakit.collection',
                  params: { id: collection.id }
                },
                stateLabel: collection.is_enabled ? null : 'Disabled'
              };

              propertyMenu.children.push(collectionMenu);

              // If this collection is active, set its parent property to active too
              if (collectionMenu.isActivePage) {
                propertyMenu.isActivePage = true;
              }
            }

            mediumMenu.children.push(propertyMenu);

            // If this property is active, set its parent medium to active too
            if (propertyMenu.isActivePage) {
              mediumMenu.isActivePage = true;
            }
          }

          menu.push(mediumMenu);
        }
      }

      return menu;
    }
  },

  methods: {
    getCollectionIcon(typeEdges) {
      switch (typeEdges[0] ? typeEdges[0].type.value : '') {
        case 'Print':
          return print;

        case 'Digital':
          return digital;

        case 'Social':
          return social;

        case 'Street Team':
          return outdoor;

        case 'Out of Home':
          return billboard;

        default:
          return flytedesk;
      }
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/components/sidebar';
</style>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.sidebar-header {
  padding: 2em 2em 1em 2em;
  border-bottom: 1px solid $color-gray;

  .header-nav {
    margin-top: 1em;
  }
}

.sidebar-menu {
  border: none;
}
</style>
