var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"the-mediakit page-content"},[_c('div',{staticClass:"flex items-center"},[_c('the-page-header',[_c('template',{slot:"title"},[_vm._v(" Media Kit ")]),_c('template',{slot:"subtitle"},[_vm._v(" Add and organize media options offered to advertisers. ")])],2),(_vm.$can('manage_mediakit_templates'))?_c('div',{staticClass:"ml-5"},[_c('supplier-mutation',{staticClass:"w-56",on:{"done":function($event){_vm.showConfirmApplyTemplateDialog = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var applyMediakitTemplate = ref.applyMediakitTemplate;
return [(!isSaving)?_c('el-button',{staticClass:"button-green",on:{"click":function($event){_vm.showConfirmApplyTemplateDialog = true}}},[_c('icon',{attrs:{"icon":_vm.uploadIcon}}),_vm._v(" Apply Mediakit Template ")],1):_c('loading-button',{staticClass:"w-full"}),(_vm.showConfirmApplyTemplateDialog)?_c('confirm-dialog',{attrs:{"title":"Are you sure you want to Apply the Media Kit Template?","is-saving":isSaving,"confirm-text":"Apply Template","width":"30em"},on:{"close":function($event){_vm.showConfirmApplyTemplateDialog = false},"confirm":applyMediakitTemplate}},[_vm._v(" Are you sure you want to apply the changes to the Media Kit template for this Publisher? "),_c('el-alert',{staticClass:"mt-5",attrs:{"type":"warning","title":"Careful!"}},[_vm._v(" This will remove unmodified Media Kit items and alter existing items to match them to the template. ")])],1):_vm._e()]}}],null,false,3910002504)})],1):_vm._e()],1),_c('div',{staticClass:"mediakit-content margin-top-xxl"},[_c('mediakit-query',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var data = ref.data;
return [(data)?[_c('mediakit-card-list',{attrs:{"items":data.mediums},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var item = ref.item;
return _c('medium-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var isDeleting = ref.isDeleting;
                var createMedium = ref.createMedium;
                var updateMedium = ref.updateMedium;
                var deleteMedium = ref.deleteMedium;
return [_c('mediakit-medium-card',{class:{ 'opacity-30': isDeleting },attrs:{"medium":item},on:{"view":function($event){return _vm.goToMedium(item)},"create":createMedium,"delete":function($event){return deleteMedium(item.id)},"update":function($event){return updateMedium(item, $event)}}})]}}],null,true)})}}],null,true)},[_c('template',{slot:"enabled-text"},[_vm._v(" Enabled Mediums ")]),_c('template',{slot:"disabled-text"},[_vm._v(" Disabled Mediums ")]),_c('mediakit-medium-card',{attrs:{"slot":"new-card","is-new":""},slot:"new-card"})],2)]:(isLoading)?_c('loader-grid',{attrs:{"item-width":19,"column-class":"w-1/4"}}):_c('el-alert',{staticClass:"text-center",attrs:{"closable":false,"title":"Mediakit was not found","type":"error"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }