<template>
  <medium-mutation @done="$emit('close')">
    <template
      slot-scope="{
        isSaving,
        createMedium,
        updateMedium,
        applyTemplate,
        saveTemplate
      }"
    >
      <edit-dialog
        :title="title"
        :is-saving="isSaving"
        @save="
          isCreating ? createMedium(changes) : updateMedium(medium, changes)
        "
        @close="$emit('close')"
      >
        <mediakit-medium-settings
          :medium="medium"
          :is-applying-template="isSaving"
          :is-saving-as-template="isSaving"
          @change="changes = $event"
          @apply-template="applyTemplate(medium)"
          @save-template="saveTemplate(medium)"
        />
      </edit-dialog>
    </template>
  </medium-mutation>
</template>

<script>
import EditDialog from '@/components/Core/EditDialog';
import MediakitMediumSettings from './MediakitMediumSettings';
import { MediumMutation } from '@/components/Mutations';

export default {
  components: {
    EditDialog,
    MediakitMediumSettings,
    MediumMutation
  },
  props: {
    medium: { type: Object, default: null }
  },

  data() {
    return {
      changes: []
    };
  },

  computed: {
    isCreating() {
      return !this.medium.id;
    },

    title() {
      return this.isCreating
        ? 'Create New Medium'
        : this.medium.name + ' Settings';
    }
  }
};
</script>
