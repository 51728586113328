<template>
  <el-form class="date-generator">
    <custom-el-form-item
      label="Beginning and End Dates"
      label-class="font-semibold"
    >
      <el-date-picker
        v-model="options.dateRange"
        type="daterange"
        range-separator="-"
        start-placeholder="Start date"
        end-placeholder="End date"
        :picker-options="{ disabledDate }"
        format="MM/dd/yyyy"
        class="date-range-input"
        @change="changeDateRange"
      />
    </custom-el-form-item>

    <custom-el-form-item
      label="Frequency"
      class="margin-top-xl"
      label-class="font-semibold"
    >
      <el-select v-model="options.frequency">
        <el-option
          v-for="frequency in frequencies"
          :key="`frequency-${frequency.value}`"
          :value="frequency.value"
          :label="frequency.label"
        >
          {{ frequency.label }}
        </el-option>
      </el-select>
    </custom-el-form-item>

    <custom-el-form-item
      label="Days of the Week"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
    >
      <el-checkbox-group v-model="options.daysOfWeek">
        <el-checkbox-button
          v-for="dayOfWeek of daysOfWeek"
          :key="`day-of-week-${dayOfWeek.value}`"
          :label="dayOfWeek.value"
        >
          {{ dayOfWeek.label }}
        </el-checkbox-button>
      </el-checkbox-group>
    </custom-el-form-item>

    <custom-el-form-item
      label="Months"
      class="margin-top-xl months-form-item"
      label-class="font-semibold"
      no-click-focus
    >
      <el-checkbox-group v-model="options.months">
        <div class="month-group">
          <el-checkbox-button
            v-for="month of earlierMonths"
            :key="`month-${month.value}`"
            :label="month.value"
          >
            {{ month.label }}
          </el-checkbox-button>
        </div>

        <div class="month-group">
          <el-checkbox-button
            v-for="month of laterMonths"
            :key="`month-${month.value}`"
            :label="month.value"
          >
            {{ month.label }}
          </el-checkbox-button>
        </div>
      </el-checkbox-group>
    </custom-el-form-item>

    <div class="margin-top-xl">
      <div class="flex items-center">
        <div class="block p-3">
          <el-button class="button-blue" @click="generate"
            >Generate Dates
          </el-button>
        </div>
        <div class="block">
          <el-alert
            v-if="alertMsg"
            show-icon
            :title="alertMsg"
            type="success"
            @close="alertMsg = ''"
          />
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import { filter } from 'lodash';
import { buildSchedule, removeDatesBetween } from '@/utils/helpers';
import moment from 'moment';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';

export default {
  components: {
    CustomElFormItem
  },
  props: {
    dates: { type: Array, default: () => [] },
    config: { type: Object, default: null },
    minDate: {
      type: [String, Object],
      default() {
        return moment();
      }
    },
    maxDate: {
      type: [String, Object],
      default() {
        return moment().add(1, 'year');
      }
    }
  },

  data() {
    let minDate = moment();
    let maxDate = moment().add(1, 'year');

    const config = this.config || {};

    return {
      alertMsg: '',
      options: {
        dateRange: [minDate, maxDate],
        minDate,
        maxDate,
        frequency: 'weekly',
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        daysOfWeek: [1, 2, 3, 4, 5, 6, 7],

        ...config
      }
    };
  },

  computed: {
    frequencies() {
      return [
        { value: 'weekly', label: 'Daily or Weekly' },
        { value: 'bi-weekly', label: 'Bi-Weekly' }
      ];
    },

    daysOfWeek() {
      return [
        { value: 1, label: 'Sun' },
        { value: 2, label: 'Mon' },
        { value: 3, label: 'Tue' },
        { value: 4, label: 'Wed' },
        { value: 5, label: 'Thu' },
        { value: 6, label: 'Fri' },
        { value: 7, label: 'Sat' }
      ];
    },

    earlierMonths() {
      return filter(this.months, v => v.value <= 6);
    },

    laterMonths() {
      return filter(this.months, v => v.value > 6);
    },

    months() {
      return [
        { value: 1, label: 'Jan' },
        { value: 2, label: 'Feb' },
        { value: 3, label: 'Mar' },
        { value: 4, label: 'Apr' },
        { value: 5, label: 'May' },
        { value: 6, label: 'Jun' },
        { value: 7, label: 'Jul' },
        { value: 8, label: 'Aug' },
        { value: 9, label: 'Sep' },
        { value: 10, label: 'Oct' },
        { value: 11, label: 'Nov' },
        { value: 12, label: 'Dec' }
      ];
    }
  },

  methods: {
    disabledDate(date) {
      let mDate = moment(date);

      return mDate.isBefore(this.minDate) || mDate.isAfter(this.maxDate);
    },

    generate() {
      let newDates = removeDatesBetween(
        this.dates,
        this.options.minDate,
        this.options.maxDate
      );

      let generatedDates = buildSchedule(this.options);

      newDates = newDates.concat(generatedDates);

      // grab just the dates after the min date (today)
      const newDatesLength = newDates.filter(d =>
        moment(d).isAfter(this.options.minDate)
      ).length;

      this.alertMsg = `Successfully added ${newDatesLength} dates.`;

      this.$emit('update:dates', newDates);
      this.$emit('change', newDates);
    },

    changeDateRange(range) {
      [this.options.minDate, this.options.maxDate] = range;
    },

    change() {
      this.$emit('options', this.options);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.months-form-item {
  max-width: 30em;
}

.month-group {
  font-size: 16px;
  width: 100%;

  & + .month-group {
    margin-top: 0.5em;
  }

  .el-checkbox-button {
    width: 3.8em;
  }
}

.date-range-input {
  max-width: 24em;
}
</style>
