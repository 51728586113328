<template>
  <div class="mediakit-card-list">
    <transition-group
      tag="div"
      name="fadeLeft"
      class="relative flex flex-wrap items-stretch animate-position"
    >
      <h4
        v-if="disabled.length > 0"
        key="enabled-header"
        class="pad-bottom-xl w-full pad-left-sm"
      >
        <slot name="enabled-text">Enabled</slot>
      </h4>

      <div
        key="new-medium"
        class="block mediakit-column p-3 w-1/4 flex items-stretch"
      >
        <slot name="new-card" />
      </div>

      <div
        v-for="item in enabled"
        :key="`mediakit-card-${item.id}`"
        class="block mediakit-column p-3 w-1/4"
      >
        <slot name="enabled" :item="item">
          <slot name="card" :item="item" />
        </slot>
      </div>

      <div
        v-if="disabled.length > 0"
        key="break-disabled"
        class="pad-xxl w-full"
      ></div>

      <h4
        v-if="disabled.length > 0"
        key="heading-disabled"
        class="pad-bottom-xl w-full pad-left-sm"
      >
        <slot name="disabled-text">Disabled</slot>
      </h4>

      <div
        v-for="item in disabled"
        :key="`mediakit-card-${item.id}`"
        class="block mediakit-column p-3 w-1/4"
      >
        <slot name="disabled" :item="item">
          <slot name="card" :item="item" />
        </slot>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { filter } from 'lodash';

export default {
  props: {
    items: { type: Array, default: () => [] }
  },

  computed: {
    enabled() {
      return filter(this.items, { is_enabled: true });
    },

    disabled() {
      return filter(this.items, { is_enabled: false });
    }
  }
};
</script>
