<template>
  <prism-editor
    :value="value"
    class="prism-code"
    :highlight="highlighter"
    :class="'language-' + language"
    @input="$emit('input', $event)"
    @blur="format"
  />
</template>

<script>
import 'vue-prism-editor/dist/prismeditor.min.css';
import '@/vendor/prism/prism.css';

import { languages } from 'prismjs';
import { PrismEditor } from 'vue-prism-editor';
import cssb from 'cssbeautify';

export default {
  components: {
    PrismEditor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.format();
  },
  methods: {
    format() {
      this.$emit(
        'input',
        cssb(this.value, {
          indent: '  ',
          autosemicolon: true
        })
      );
    },
    highlighter(code) {
      return Prism.highlight(code, languages.css);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/scss/_variables';

.prism-code /deep/ {
  border: 1px solid $color-dark-silver;
  padding: 0.5em;
  background: $color-dark-gray;
  color: white;

  textarea {
    outline: none;
  }

  .prism-editor__editor {
    color: $color-blue;

    .token {
      &.punctuation {
        color: $color-silver;
      }
    }
  }
}
</style>
