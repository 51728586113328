<template>
  <div
    ref="staticHeader"
    class="sticky-header z-30 bg-white relative px-8"
    :class="{ 'sticky top-0 shadow-md': isSticky }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    offset: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      parentOffsetTop: null,
      header: null
    };
  },

  computed: {
    scrollContext() {
      let parent = this.$refs.staticHeader.parentNode;

      while (parent) {
        if (this.hasScrollEnabled(parent)) {
          return parent;
        }

        parent = parent.parentNode;
      }

      return null;
    },

    headerTop() {
      return this.header ? this.header.getBoundingClientRect().top : 0;
    },

    isSticky() {
      return this.parentOffsetTop - this.headerTop > this.offset;
    }
  },

  mounted() {
    this.header = this.$refs.staticHeader;
    if (this.scrollContext) {
      this.$nextTick(() => {
        this.scrollContext.onscroll = e => {
          window.requestAnimationFrame(() => {
            this.parentOffsetTop = e.target.scrollTop;
          });
        };
      });
    }
  },

  methods: {
    hasScrollEnabled(el) {
      if (el.nodeName) {
        const style = window.getComputedStyle(el);

        if (style) {
          return ['auto', 'scroll', 'overlay'].includes(style.overflowY);
        }
      }
    }
  }
};
</script>
