var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mediakit-collection pt-5 relative"},[(_vm.collection && _vm.$can('manage_national_inventory'))?_c('collection-inventory-report-query',{attrs:{"collection-id":_vm.collection.id},on:{"data":function($event){_vm.inventoryReport = $event.supplier.collection.inventoryReport}}}):_vm._e(),_c('mediakit-query',{attrs:{"collection-id":_vm.$route.params.id},on:{"medium":function($event){_vm.medium = $event},"data":function($event){_vm.collection = $event.collection}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.collection)?_c('div',[_c('mediakit-collection-notifications',{attrs:{"collection":_vm.collection,"property":_vm.property,"reaches":_vm.reaches},on:{"isEditing":function($event){_vm.isEditing = $event},"view-product":function($event){_vm.productToEdit = $event}}}),_c('sticky-header',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"block p-3 flex-1"},[_c('h2',[_vm._v(" "+_vm._s(_vm.collection.name)+" "),(_vm.collection.is_super)?_c('mediakit-super-icon'):_vm._e(),_c('span',{staticClass:"pad-left-xxs"},[_c('tooltip-icon',{attrs:{"is-disabled":!_vm.$can('pub_edit_mediakit_settings_or_schedules'),"icon":_vm.cog1,"icon-class":"icon-md text-dark-silver cursor-pointer","reference-class":"pl-2"},on:{"click":function($event){_vm.isEditing = 'general'}}})],1),_c('span',{staticClass:"pad-left-xxs"},[_c('tooltip-icon',{attrs:{"is-disabled":!_vm.$can('pub_edit_mediakit_settings_or_schedules'),"icon":_vm.calendar,"icon-class":"icon-md text-dark-silver is-clickable","reference-class":"pl-2"},on:{"click":function($event){_vm.isEditing = 'schedule'}}})],1)],1),_c('div',{staticClass:"sub-header"},[_c('ul',{staticClass:"horizontal-list text-md"},[_c('li',[_vm._v(_vm._s(_vm.productCount)+" Products")])])])]),_c('div',{staticClass:"block p-3 flex-1 text-right"},[_c('collection-mutation',{attrs:{"property-id":_vm.property.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateCollection = ref.updateCollection;
return [_c('el-tooltip',{attrs:{"effect":"light","disabled":_vm.disabledParents.length === 0}},[_c('div',{staticClass:"status-messages",attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.disabledParents),function(msg){return _c('div',{key:("msg-" + (msg.msg))},[_c('a',{on:{"click":function($event){return _vm.$router.push(msg.route)}}},[_vm._v(" "+_vm._s(msg.msg)+" ")])])}),0),_c('custom-el-switch',{class:_vm.disabledParents.length > 0
                            ? 'toggle-yellow-red'
                            : 'toggle-green-red',attrs:{"left":"","disabled":!_vm.$can('pub_disable_anything_in_mediakit'),"value":_vm.collection.is_enabled,"active-text":_vm.disabledParents.length > 0
                            ? 'This collection is enabled but not visible to buyers'
                            : 'This collection is enabled and visible to buyers',"inactive-text":"This collection is disabled and not visible to buyers"},on:{"change":function($event){return updateCollection(_vm.collection, { is_enabled: $event })}}})],1)]}}],null,true)})],1)])]),_c('div',{staticClass:"mediakit-content m-8"},[_c('mediakit-card-list',{attrs:{"items":_vm.collection.products},scopedSlots:_vm._u([{key:"card",fn:function(ref){
                            var item = ref.item;
return _c('product-mutation',{key:("mpc-" + (item.id)),attrs:{"collection-id":_vm.collection.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var isSaving = ref.isSaving;
                  var isCopying = ref.isCopying;
                  var isDeleting = ref.isDeleting;
                  var createProduct = ref.createProduct;
                  var copyProduct = ref.copyProduct;
                  var updateProduct = ref.updateProduct;
                  var deleteProduct = ref.deleteProduct;
return [_c('mediakit-product-card',{class:{
                      'opacity-30 grayscale': isDeleting,
                      'blur-20': isCopying
                    },attrs:{"saving":isSaving,"collection-id":_vm.collection.id,"collection":_vm.collection,"product":item,"inventory-report":_vm.getProductInventoryReport(item)},on:{"copy":function($event){return copyProduct(item, {
                        name: item.name + ' - Copy'
                      })},"delete":function($event){return deleteProduct(item.id)},"edit":function($event){_vm.productToEdit = item},"update":function($event){return updateProduct(item, $event)}}})]}}],null,true)})}}],null,true)},[_c('template',{slot:"enabled-text"},[_vm._v(" Enabled Products ")]),_c('template',{slot:"disabled-text"},[_vm._v(" Disabled Products ")]),_c('mediakit-product-card',{key:"mpc-new",attrs:{"slot":"new-card","collection-id":_vm.collection.id,"is-new":""},on:{"edit":function($event){_vm.productToEdit = {}}},slot:"new-card"})],2)],1)],1):(isLoading)?_c('loader-content',{staticClass:"p-5"},[_c('loader-heading',{staticClass:"is-width-xl p-3",attrs:{"thickness":1.2}}),_c('loader-grid',{staticClass:"margin-top",attrs:{"item-width":16.7,"column-class":"w-1/4"}})],1):_c('el-alert',{staticClass:"text-center",attrs:{"type":"error","title":"Collection was not found","closable":false}})],1)]}}])}),(_vm.isEditing)?_c('mediakit-collection-dialog',{attrs:{"medium":_vm.medium,"property":_vm.property,"collection":_vm.collection,"tab":_vm.isEditing === 'schedule' ? 'schedule' : 'general'},on:{"close":function($event){_vm.isEditing = false}}}):_vm._e(),(_vm.productToEdit)?_c('mediakit-product',{attrs:{"is-new":!_vm.productToEdit.id,"medium":_vm.medium,"collection":_vm.collection,"product":_vm.productToEdit},on:{"close":function($event){_vm.productToEdit = false}},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }