<template>
  <collection-schedule-query :collection-id="collection.id" @data="setSchedule">
    <template slot-scope="{ isLoading }">
      <transition name="fade" mode="out-in">
        <div v-if="schedule" class="mediakit-collection-schedule">
          <div class="heading">
            <h4>{{ ScheduleTypeTitle[scheduleType.value] }}</h4>
            <div class="help text-dark-silver margin-top-sm">
              {{ scheduleType.help }}
            </div>
          </div>

          <div class="schedule-content margin-top-xl">
            <div class="scheduler-heading">
              <div class="flex p-3">
                <div class="flex-grow">
                  <el-popover trigger="click" placement="bottom">
                    <a
                      slot="reference"
                      class="generate-dates text-dark-gray"
                      @click="showGenerateDates = true"
                    >
                      <icon :icon="arrowsCw" class="text-dark-silver" />
                      <span class="pad-left-sm">Generate New Dates</span>
                    </a>

                    <date-generator
                      :dates.sync="selectedDates"
                      class="pad"
                      @change="change"
                    />
                  </el-popover>
                </div>
                <transition name="fade">
                  <div>
                    <a
                      v-if="selectedDates && selectedDates.length > 0"
                      @click="showClearDatesDialog = true"
                      >Clear All Dates</a
                    >

                    <confirm-dialog
                      v-if="showClearDatesDialog"
                      title="Clear All Dates"
                      @close="showClearDatesDialog = false"
                      @confirm="clearDates"
                    >
                      <div>
                        <div>
                          Are you sure you want to clear all dates on this
                          schedule? You can’t undo this action.
                        </div>

                        <div class="mt-5">
                          NOTE: You can still click "Cancel" on the Schedule
                          Settings Dialog to revert your changes if you make a
                          mistake before clicking save.
                        </div>
                      </div>
                    </confirm-dialog>
                  </div>
                </transition>
              </div>
            </div>

            <div class="scheduler-body margin-top">
              <div class="scheduler-date-picker">
                <flyte-multi-picker v-model="selectedDates" @input="change" />
              </div>

              <el-form v-if="isContiguous" class="text-left text-dark-gray">
                <custom-el-form-item
                  label="Interval Length"
                  type="columns"
                  class="margin-top-xl"
                  label-class="font-semibold"
                  prop="name"
                >
                  <template slot="help">
                    A buyer will be able to book in intervals of this length of
                    time, beginning on a start date indicated above.
                  </template>

                  <div class="flex">
                    <div class="block p-3">
                      <el-input
                        v-model="quantityLimit.increment"
                        v-mask.integer="{ min: 1 }"
                        class="is-width-md"
                        @change="change"
                      />
                    </div>
                    <div class="block p-3">
                      <el-select v-model="quantityLimit.unit" @change="change">
                        <el-option
                          v-for="scheduleUnit in ScheduleUnits"
                          :key="`schedule-unit-${scheduleUnit}`"
                          :value="scheduleUnit"
                        />
                      </el-select>
                    </div>
                  </div>
                </custom-el-form-item>

                <custom-el-form-item
                  label="Minimum/Maximum"
                  type="columns"
                  class="margin-top-xl"
                  label-class="font-semibold"
                  prop="name"
                >
                  <template slot="help">
                    Set the minimum and/or maximum number of intervals that a
                    buyer can order at a time.
                  </template>

                  <div class="flex">
                    <div class="block p-3 flex-1">
                      <el-input
                        v-model="quantityLimit.min"
                        v-mask.integer="{ min: 0 }"
                        @change="change"
                      />
                    </div>
                    <div class="block p-3 flex-1">
                      <div>
                        <el-input
                          :key="`limit-${quantityLimit.min}`"
                          v-model="quantityLimit.max"
                          v-mask.integer="{
                            min: quantityLimit.min,
                            allowEmpty: true
                          }"
                          placeholder="Unlimited"
                          @change="change"
                        />
                      </div>
                      <div class="margin-top-sm">
                        <el-checkbox
                          :key="`unlimited-checked-${quantityLimit.max}`"
                          :checked="+quantityLimit.max === 0"
                          @change="
                            quantityLimit.max = $event
                              ? null
                              : quantityLimit.min
                          "
                        >
                          Unlimited
                        </el-checkbox>
                      </div>
                    </div>
                  </div>
                </custom-el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <loader-content v-else-if="isLoading">
          <loader-heading :thickness="1" />
          <loader-box width="100%" :height="25" class="margin-top-xxl" />
        </loader-content>

        <el-alert
          v-else
          type="error"
          title="Schedule was not found"
          class="text-center"
          :closable="false"
        />
      </transition>
    </template>
  </collection-schedule-query>
</template>

<script>
import { cloneDeep, uniqueId } from 'lodash';
import { dateDb } from '@/utils/filters';
import {
  ScheduleTypeTitle,
  ScheduleTypeValue,
  ScheduleUnit,
  ScheduleUnits
} from '@/constants';
import { arrowsCw } from '@/vendor/icons';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import DateGenerator from '@/components/Core/MediaKit/DateGenerator';
import FlyteMultiPicker from '@/components/Core/FlytePicker/FlyteMultiPicker';
import { CollectionScheduleQuery } from '@/components/Queries';
import {
  LoaderBox,
  LoaderContent,
  LoaderHeading
} from '@/components/Core/Loaders';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
    CollectionScheduleQuery,
    CustomElFormItem,
    DateGenerator,
    FlyteMultiPicker,
    LoaderContent,
    LoaderHeading,
    LoaderBox
  },
  props: {
    scheduleType: {
      type: Object,
      required: true
    },
    collection: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ScheduleUnits,
      ScheduleTypeTitle,
      selectedDates: [],
      showGenerateDates: false,
      showClearDatesDialog: false,
      schedule: null,
      quantityLimit: {
        min: 1,
        max: null,
        increment: 1,
        unit: ScheduleUnit.DAYS
      },

      // Icons
      arrowsCw
    };
  },

  computed: {
    isContiguous() {
      return this.scheduleType.value === ScheduleTypeValue.CONTIGUOUS;
    },

    scheduleState() {
      return {
        id: this.schedule.id || uniqueId('new-schedule-'),
        schedule_type_id: this.scheduleType.id,
        dates: this.selectedDates.map(d => dateDb(d)),
        quantityLimit: {
          ...this.quantityLimit,
          max: this.quantityLimit.max || null
        }
      };
    }
  },

  methods: {
    change() {
      this.$emit('change', this.scheduleState);
    },

    clearDates() {
      this.selectedDates = [];
      this.change();
      this.showClearDatesDialog = false;
    },

    setSchedule(schedule) {
      if (schedule) {
        this.schedule = schedule;

        this.selectedDates = cloneDeep(schedule.upcomingDates);
        this.quantityLimit = {
          ...this.quantityLimit,
          ...schedule.quantityLimit
        };
      } else {
        this.schedule = {};
        this.selectedDates = [];
      }

      // When the schedule is loaded the first time, the Collection object will not have it associated,
      // so lets associate the schedule object so it isn't missing if the user updates data (GraphQL will throw
      // an error for a missing schedule)
      // send a special init event
      this.$emit('init', this.scheduleState);
    }
  }
};
</script>

<style lang="scss" scoped>
.scheduler-body {
  .scheduler-date-picker {
    text-align: center;
    font-size: 1.25em;
    overflow: auto;
  }
}
</style>
