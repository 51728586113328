<template>
  <div class="mediakit-collection pt-5 relative">
    <collection-inventory-report-query
      v-if="collection && $can('manage_national_inventory')"
      :collection-id="collection.id"
      @data="inventoryReport = $event.supplier.collection.inventoryReport"
    />

    <mediakit-query
      :collection-id="$route.params.id"
      @medium="medium = $event"
      @data="collection = $event.collection"
    >
      <template slot-scope="{ isLoading }">
        <transition name="fade" mode="out-in">
          <div v-if="collection">
            <mediakit-collection-notifications
              :collection="collection"
              :property="property"
              :reaches="reaches"
              @isEditing="isEditing = $event"
              @view-product="productToEdit = $event"
            />
            <sticky-header>
              <div class="flex">
                <div class="block p-3 flex-1">
                  <h2>
                    {{ collection.name }}
                    <mediakit-super-icon v-if="collection.is_super" />
                    <span class="pad-left-xxs">
                      <tooltip-icon
                        :is-disabled="
                          !$can('pub_edit_mediakit_settings_or_schedules')
                        "
                        :icon="cog1"
                        icon-class="icon-md text-dark-silver cursor-pointer"
                        reference-class="pl-2"
                        @click="isEditing = 'general'"
                      />
                    </span>

                    <span class="pad-left-xxs">
                      <tooltip-icon
                        :is-disabled="
                          !$can('pub_edit_mediakit_settings_or_schedules')
                        "
                        :icon="calendar"
                        icon-class="icon-md text-dark-silver is-clickable"
                        reference-class="pl-2"
                        @click="isEditing = 'schedule'"
                      />
                    </span>
                  </h2>

                  <div class="sub-header">
                    <ul class="horizontal-list text-md">
                      <li>{{ productCount }} Products</li>
                    </ul>
                  </div>
                </div>

                <div class="block p-3 flex-1 text-right">
                  <collection-mutation :property-id="property.id">
                    <template slot-scope="{ updateCollection }">
                      <el-tooltip
                        effect="light"
                        :disabled="disabledParents.length === 0"
                      >
                        <div slot="content" class="status-messages">
                          <div
                            v-for="msg in disabledParents"
                            :key="`msg-${msg.msg}`"
                          >
                            <a @click="$router.push(msg.route)">
                              {{ msg.msg }}
                            </a>
                          </div>
                        </div>

                        <custom-el-switch
                          left
                          :disabled="!$can('pub_disable_anything_in_mediakit')"
                          :value="collection.is_enabled"
                          :active-text="
                            disabledParents.length > 0
                              ? 'This collection is enabled but not visible to buyers'
                              : 'This collection is enabled and visible to buyers'
                          "
                          inactive-text="This collection is disabled and not visible to buyers"
                          :class="
                            disabledParents.length > 0
                              ? 'toggle-yellow-red'
                              : 'toggle-green-red'
                          "
                          @change="
                            updateCollection(collection, { is_enabled: $event })
                          "
                        />
                      </el-tooltip>
                    </template>
                  </collection-mutation>
                </div>
              </div>
            </sticky-header>

            <div class="mediakit-content m-8">
              <mediakit-card-list :items="collection.products">
                <template slot="enabled-text">
                  Enabled Products
                </template>
                <template slot="disabled-text">
                  Disabled Products
                </template>

                <mediakit-product-card
                  slot="new-card"
                  key="mpc-new"
                  :collection-id="collection.id"
                  is-new
                  @edit="productToEdit = {}"
                />

                <product-mutation
                  slot="card"
                  :key="`mpc-${item.id}`"
                  slot-scope="{ item }"
                  :collection-id="collection.id"
                >
                  <template
                    slot-scope="{
                      isSaving,
                      isCopying,
                      isDeleting,
                      createProduct,
                      copyProduct,
                      updateProduct,
                      deleteProduct
                    }"
                  >
                    <mediakit-product-card
                      :class="{
                        'opacity-30 grayscale': isDeleting,
                        'blur-20': isCopying
                      }"
                      :saving="isSaving"
                      :collection-id="collection.id"
                      :collection="collection"
                      :product="item"
                      :inventory-report="getProductInventoryReport(item)"
                      @copy="
                        copyProduct(item, {
                          name: item.name + ' - Copy'
                        })
                      "
                      @delete="deleteProduct(item.id)"
                      @edit="productToEdit = item"
                      @update="updateProduct(item, $event)"
                    />
                  </template>
                </product-mutation>
              </mediakit-card-list>
            </div>
          </div>

          <loader-content v-else-if="isLoading" class="p-5">
            <loader-heading class="is-width-xl p-3" :thickness="1.2" />
            <loader-grid
              :item-width="16.7"
              column-class="w-1/4"
              class="margin-top"
            />
          </loader-content>

          <el-alert
            v-else
            type="error"
            title="Collection was not found"
            class="text-center"
            :closable="false"
          />
        </transition>
      </template>
    </mediakit-query>

    <mediakit-collection-dialog
      v-if="isEditing"
      :medium="medium"
      :property="property"
      :collection="collection"
      :tab="isEditing === 'schedule' ? 'schedule' : 'general'"
      @close="isEditing = false"
    />

    <mediakit-product
      v-if="productToEdit"
      :is-new="!productToEdit.id"
      :medium="medium"
      :collection="collection"
      :product="productToEdit"
      @close="productToEdit = false"
      @click.native.stop
    />
  </div>
</template>

<script>
import { findRootPath } from '@/utils/helpers';

import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import MediakitCardList from '../Common/MediakitCardList';
import MediakitCollectionDialog from './MediakitCollectionDialog';
import MediakitCollectionNotifications from './MediakitCollectionNotifications';
import MediakitProduct from '../Product/MediakitProduct';
import MediakitProductCard from '../Product/MediakitProductCard';
import TooltipIcon from '@/components/Core/TooltipIcon';
import StickyHeader from '@/components/Core/Layout/StickyHeader.vue';
import { CollectionMutation, ProductMutation } from '@/components/Mutations';
import { MediakitQuery } from '@/components/Queries';
import {
  LoaderContent,
  LoaderGrid,
  LoaderHeading
} from '@/components/Core/Loaders';

import { calendar, cog1 } from '@/vendor/icons';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';
import CollectionInventoryReportQuery from '@/components/Queries/Supplier/Mediakit/CollectionInventoryReportQuery';

export default {
  components: {
    CollectionInventoryReportQuery,
    MediakitSuperIcon,
    CollectionMutation,
    CustomElSwitch,
    LoaderContent,
    LoaderGrid,
    LoaderHeading,
    MediakitCardList,
    MediakitCollectionDialog,
    MediakitCollectionNotifications,
    MediakitProduct,
    MediakitProductCard,
    MediakitQuery,
    TooltipIcon,
    ProductMutation,
    StickyHeader
  },

  data() {
    return {
      medium: false,
      collection: false,
      isEditing: false,
      productToEdit: false,
      inventoryReport: null,

      // Icons
      calendar,
      cog1
    };
  },

  computed: {
    property() {
      return findRootPath(this.medium.properties, ['collections'], {
        id: this.collection.id
      });
    },

    productCount() {
      return this.collection.products.length;
    },

    disabledParents() {
      let statuses = [];

      if (!this.medium.is_enabled) {
        statuses.push({
          msg: 'The Medium ' + this.medium.name + ' is disabled.',
          route: {
            name: 'supplier.mediakit.medium',
            params: { id: this.medium.id }
          }
        });
      }

      if (!this.property.is_enabled) {
        statuses.push({
          msg: 'The Property ' + this.property.name + ' is disabled.',
          route: {
            name: 'supplier.mediakit.property',
            params: { id: this.property.id }
          }
        });
      }

      return statuses;
    },

    reaches() {
      return this.collection.reaches;
    }
  },
  methods: {
    getProductInventoryReport(product) {
      return this.inventoryReport && this.$can('manage_national_inventory')
        ? this.inventoryReport.find(ir => ir.id === product.id)
        : null;
    }
  }
};
</script>
