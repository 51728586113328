var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mediakit-property pt-5 relative"},[_c('mediakit-query',{attrs:{"property-id":_vm.$route.params.id},on:{"data":function($event){_vm.property = $event.property},"medium":function($event){_vm.medium = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.property)?_c('div',[_c('sticky-header',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"block p-3 flex-1"},[_c('h2',[_vm._v(" "+_vm._s(_vm.property.name)+" "),(_vm.property.is_super)?_c('mediakit-super-icon'):_vm._e(),_c('span',{staticClass:"pad-left-xxs"},[_c('tooltip-icon',{attrs:{"is-disabled":!_vm.$can('pub_edit_mediakit_settings_or_schedules'),"icon":_vm.cog1,"icon-class":"icon-md text-dark-silver cursor-pointer","reference-class":"pl-2"},on:{"click":function($event){_vm.isEditing = 'general'}}})],1)],1),_c('div',{staticClass:"sub-header"},[_c('ul',{staticClass:"horizontal-list text-md"},[_c('li',[_vm._v(_vm._s(_vm.collectionCount)+" Collection")]),_c('li',[_vm._v(_vm._s(_vm.productCount)+" Products")])])])]),_c('div',{staticClass:"block p-3 flex-1 text-right"},[_c('property-mutation',{attrs:{"medium-id":_vm.medium.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateProperty = ref.updateProperty;
return [_c('el-tooltip',{attrs:{"effect":"light","disabled":_vm.disabledParents.length === 0}},[_c('div',{staticClass:"status-messages",attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.disabledParents),function(msg){return _c('div',{key:("msg-" + (msg.msg))},[_c('a',{on:{"click":function($event){return _vm.$router.push(msg.route)}}},[_vm._v(" "+_vm._s(msg.msg)+" ")])])}),0),_c('custom-el-switch',{class:_vm.disabledParents.length > 0
                            ? 'toggle-yellow-red'
                            : 'toggle-green-red',attrs:{"disabled":!_vm.$can('pub_disable_anything_in_mediakit'),"left":"","value":_vm.property.is_enabled,"active-text":_vm.disabledParents.length > 0
                            ? 'This property is enabled but not visible to buyers'
                            : 'This property is enabled and visible to buyers',"inactive-text":"This property is disabled and not visible to buyers"},on:{"change":function($event){return updateProperty(_vm.property, { is_enabled: $event })}}})],1)]}}],null,true)})],1)])]),_c('div',{staticClass:"mediakit-content p-8"},[_c('mediakit-card-list',{attrs:{"items":_vm.property.collections},scopedSlots:_vm._u([{key:"card",fn:function(ref){
                            var item = ref.item;
return _c('collection-mutation',{attrs:{"property-id":_vm.property.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var isDeleting = ref.isDeleting;
                  var createCollection = ref.createCollection;
                  var updateCollection = ref.updateCollection;
                  var deleteCollection = ref.deleteCollection;
return [_c('mediakit-collection-card',{class:{ 'opacity-30': isDeleting },attrs:{"medium":_vm.medium,"property":_vm.property,"collection":item},on:{"view":function($event){return _vm.goToCollection(item)},"copy":function($event){_vm.collectionToCopy = item},"update":function($event){return updateCollection(item, $event)},"delete":function($event){return deleteCollection(item.id)}}})]}}],null,true)})}}],null,true)},[_c('template',{slot:"enabled-text"},[_vm._v(" Enabled Collections ")]),_c('template',{slot:"disabled-text"},[_vm._v(" Disabled Collections ")]),_c('mediakit-collection-card',{attrs:{"slot":"new-card","medium":_vm.medium,"property":_vm.property,"is-new":""},slot:"new-card"})],2)],1)],1):(isLoading)?_c('loader-content',{staticClass:"p-5"},[_c('loader-heading',{staticClass:"is-width-xl p-3",attrs:{"thickness":1.2}}),_c('loader-grid',{staticClass:"margin-top",attrs:{"item-width":19,"column-class":"w-1/4"}})],1):_c('el-alert',{staticClass:"text-center",attrs:{"type":"error","title":"Property was not found","closable":false}})],1),(_vm.isEditing)?_c('mediakit-property-dialog',{attrs:{"medium":_vm.medium,"property":_vm.property},on:{"close":function($event){_vm.isEditing = false}}}):_vm._e(),(_vm.collectionToCopy)?_c('mediakit-collection-dialog',{attrs:{"medium":_vm.medium,"property":_vm.property,"collection":_vm.collectionToCopy,"is-copying":""},on:{"close":function($event){_vm.collectionToCopy = false}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }