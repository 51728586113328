<template>
  <div
    class="flyte-multi-picker"
    :class="{ 'selection-disabled': disableSelection }"
  >
    <div
      v-if="outerControls"
      class="fp-control fp-left-arrow"
      @click="changeMonth(-1)"
    >
      <icon :icon="chevronUp" rotation="270" />
    </div>

    <div class="flyte-pickers">
      <div
        v-for="picker in pickers"
        :key="`picker-${picker.id}`"
        class="flyte-picker-wrap"
      >
        <flyte-picker
          v-if="showPickers"
          :id="picker.id"
          ref="pickers"
          :value="value"
          ignore-value-change
          :config="picker.config"
          :first-date="firstDate"
          @change="onDateSelected"
          @date-click="onDateClicked"
          @date-render="onRenderDate"
        />
      </div>
    </div>

    <div
      v-if="outerControls"
      class="fp-control fp-right-arrow"
      @click="changeMonth(1)"
    >
      <icon :icon="chevronUp" rotation="90" />
    </div>

    <template v-if="$slots.popover">
      <el-popover
        v-if="hasSelectedDate"
        ref="popover"
        :value="!!selectedDateEl"
        :reference="selectedDateEl"
        :popper-options="popperOptions"
        @hide="$emit('hide-popover')"
      >
        <div v-click-outside="clicked">
          <slot name="popover" />
        </div>
      </el-popover>
    </template>

    <template v-if="$scopedSlots.hover && !disableHover">
      <div
        v-if="hoveredDate"
        ref="hoverPopover"
        class="hover-popover el-popover el-popper"
        :style="hoverPopoverStyle"
        x-placement="top"
      >
        <slot name="hover" :date="hoveredDate" />
        <div class="popper__arrow"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { extend, uniqueId } from 'lodash';
import { chevronUp } from '@/vendor/icons';
import moment from 'moment';

import FlytePicker from './FlytePicker';

export default {
  components: {
    FlytePicker
  },
  props: {
    manualPopover: Boolean,
    disableHover: Boolean,
    hoverPopoverWidth: {
      type: Number,
      default: 20
    },
    config: { type: Object, default: null },
    count: { type: Number, default: null },
    firstDate: {
      type: String,
      default: null
    },
    outerControls: Boolean,
    value: { type: Array, default: () => [] }
  },

  data() {
    let fmp = this;
    let pickers = [];

    for (let i = 0; i < (this.count || 2); i++) {
      let defaults = {
        inline: true,
        // For now this needs to be disabled, things get broken when trying to correct for date selection and disabling animation on the fly
        animate: false,
        mode: 'multiple',
        defaultDate: this.value || [],
        onMonthChange() {
          fmp.syncMonthsToPicker(i);
        },
        onYearChange() {
          fmp.syncMonthsToPicker(i);
        }
      };

      pickers.push({
        id: uniqueId('picker-'),
        index: i,
        config: this.config ? extend(defaults, this.config) : defaults
      });
    }

    return {
      showPickers: true,
      isSyncing: false,
      cancelDateSelect: false,
      pickers,
      selectedDates: this.value,
      disableSelection: this.config ? !!this.config.disableSelection : false,

      // Popover
      selectedDateEl: null,
      hasSelectedDate: false,
      popperOptions: {
        positionFixed: true
      },
      hoveredDate: null,

      // Icons
      chevronUp
    };
  },

  computed: {
    hoverPopoverStyle() {
      return {
        width: this.hoverPopoverWidth + 'em',
        // TODO: Need to find the proper center of the date (only works for default 20em)
        left: -0.425 * this.hoverPopoverWidth + 'em'
      };
    }
  },

  watch: {
    value() {
      this.selectedDates = this.value;
      // Need to update all the pickers to have the new set of selected selectedDates selected
      this.resetPickers();
    },

    config: {
      deep: true,
      handler(newConfig) {
        this.applyConfig();

        // XXX: There is a bug w/ flatpickr where after changing the list of enabled
        // dates, the next date you click fails to trigger the click event, so as a
        // workaround, lets just reset the whole picker component
        if (newConfig.enable && this.showPickers) {
          this.showPickers = false;
          this.$nextTick(() => {
            this.showPickers = true;
            this.$nextTick(() => {
              this.initFlyteMultiPicker();
              this.resetPickers();
            });
          });
        }
      }
    }
  },
  mounted() {
    this.initFlyteMultiPicker();
  },

  methods: {
    clicked() {
      this.closePopover();
    },

    closePopover() {
      if (this.$refs.popover) {
        this.$refs.popover.doClose();
      }
    },

    /**
     * Sets up all the pickers to be synchronized with each other
     */
    initFlyteMultiPicker() {
      for (let picker of this.pickers) {
        picker.flatpickr = document.getElementById(
          picker.id + '-flyte-picker'
        )._flatpickr;
      }

      for (let picker of this.$refs.pickers) {
        picker.$el.onmouseenter = this.hoverAllPickers.bind(this, true);
        picker.$el.onmouseleave = this.hoverAllPickers.bind(this, false);
      }

      this.applyConfig();

      // set all the months based off the first picker
      this.syncMonthsToPicker(0);
    },

    hoverAllPickers(status) {
      for (let picker of this.$refs.pickers) {
        if (status) {
          if (!picker.$el.classList.contains('is-hovering-sibling')) {
            picker.$el.className += ' is-hovering-sibling';
          }
        } else {
          picker.$el.className = picker.$el.className.replace(
            /is-hovering-sibling/g,
            ''
          );
        }
      }
    },
    /**
     * When an individual date is clicked, pass the DOM element clicked and the flatpickr instance
     * in the date-clicked event
     */
    onDateClicked(date, selected) {
      this.$emit('date-click', date, selected);

      if (!this.manualPopover && this.$slots.popover) {
        this.openPopover(date, selected);
      }
    },

    /**
     * Opens the popover component over a given date in the calendar
     */
    openPopover(date, selected = true) {
      this.selectedDateEl = false;
      this.hasSelectedDate = false;

      date = moment(date).format('YYYY-MM-DD');

      this.$nextTick(() => {
        this.hasSelectedDate = true;
        this.$nextTick(() => {
          if (selected && this.$refs.pickers[0]) {
            // XXX: Just gotta get this working for date list sidebar
            setTimeout(() => {
              this.selectedDateEl = this.getDateEl(date);
              this.$refs.popover.doShow();
            }, 10);
          }
        });
      });
    },

    getDateEl(date) {
      return this.$refs.pickers[0].getDateElement(date);
    },

    /**
     * Set all the pickers to the selected Dates
     */
    onDateSelected(selectedDates) {
      // XXX: This is set to indicate a user clicked on a prev / next month day and we should ignore this selection
      if (this.cancelDateSelect || this.disableSelection) {
        this.cancelDateSelect = false;
        this.resetPickers();
        return;
      }

      this.selectedDates = selectedDates;

      // Setting selectedDates will change the month of the picker, so we need to specify what the selectedDates are, and reset to those selectedDates
      this.resetPickers();

      this.$emit('input', this.selectedDates);
    },

    /**
     * change the month for all the pickers (see flatpickr.changeMonth() for param details
     * Will actually only explicitly change the first month, the other flytepickers are updated
     * implicitly via the onChangeMonth event
     *
     * @param month
     * @param isOffset
     */
    changeMonth(month, isOffset) {
      // Change the first picker, all other pickers will get updated based on the first one's month change offset
      this.pickers[0].flatpickr.changeMonth(month, isOffset !== false);
    },

    /**
     * Reset all the pickers to their last known state (selectedDates selected, current month and year, etc...)
     */
    resetPickers() {
      this.isSyncing = true;

      for (let picker of this.pickers) {
        picker.flatpickr.setDate(this.selectedDates, false);
        picker.flatpickr.changeYear(picker.currentYear, false);
        picker.flatpickr.changeMonth(picker.currentMonth, false);
      }

      this.isSyncing = false;
    },

    /**
     * Ensure that all the pickers are sequentially 1 month apart relative to the specified picker
     *
     * @param pickerIndex - the index of the picker to use as a reference for updating the other pickers
     */
    syncMonthsToPicker(pickerIndex) {
      // avoid infinite looping
      if (this.isSyncing) return;

      this.isSyncing = true;

      let refPicker = this.pickers[pickerIndex];

      if (refPicker.flatpickr) {
        refPicker.currentMonth = refPicker.flatpickr.currentMonth;
        refPicker.currentYear = refPicker.flatpickr.currentYear;

        for (let picker of this.pickers) {
          if (picker.index !== pickerIndex) {
            let next = this.getMonthYearChange(
              refPicker,
              picker.index - pickerIndex
            );

            picker.flatpickr.changeYear(next.year, false);
            picker.flatpickr.changeMonth(next.month, false);

            picker.currentMonth = picker.flatpickr.currentMonth;
            picker.currentYear = picker.flatpickr.currentYear;
          }
        }
      }

      this.isSyncing = false;
    },

    /**
     * Calculate the month / year based on the # of months offset from the current given picker's month / year
     * eg: Dec 2017 + 13 months = January 2019
     */
    getMonthYearChange(picker, monthOffset) {
      let nextMonth = picker.currentMonth + monthOffset;
      let nextYear = picker.currentYear;

      while (nextMonth > 12) {
        nextMonth -= 12;
        nextYear += 1;
      }

      while (nextMonth < 1) {
        nextMonth += 12;
        nextYear -= 1;
      }

      return { month: nextMonth, year: nextYear };
    },

    applyConfig() {
      for (let c in this.config) {
        this.setConfig(c, this.config[c]);
      }
    },

    /**
     * Synchronizes the config option changes to all the pickers
     *
     * @param key
     * @param value
     */
    setConfig(key, value) {
      switch (key) {
        case 'enable':
          if (value === false) {
            value = ['0000-00-00'];
          }
          break;

        case 'disable':
          if (value === true) {
            key = 'enable';
            value = ['0000-00-00'];
          }
          break;
      }

      // Disable month sync, as we handle any changes manually here
      this.isSyncing = true;

      for (let picker of this.pickers) {
        let oldMonth = picker.flatpickr.currentMonth;

        picker.flatpickr.set(key, value);

        if (oldMonth !== picker.flatpickr.currentMonth) {
          picker.flatpickr.changeMonth(oldMonth, false);
        }
      }

      this.isSyncing = false;
    },

    /**
     * Customization for rendering each date
     * @param date
     */
    onRenderDate(date) {
      if (this.$scopedSlots.hover && !this.disableHover) {
        date.el.onmouseenter = () => {
          this.hoveredDate = date;
          this.$nextTick(() => {
            date.el.appendChild(this.$refs.hoverPopover);
          });
        };

        date.el.onmouseleave = () => {
          this.hoveredDate = null;
        };
      }

      this.$emit('date-render', date);
    }
  }
};
</script>

<style scoped>
.hover-popover {
  bottom: 110%;
  left: 0;
}
</style>
