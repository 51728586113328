<template>
  <div class="the-mediakit page-content">
    <div class="flex items-center">
      <the-page-header>
        <template slot="title">
          Media Kit
        </template>
        <template slot="subtitle">
          Add and organize media options offered to advertisers.
        </template>
      </the-page-header>

      <div v-if="$can('manage_mediakit_templates')" class="ml-5">
        <supplier-mutation
          class="w-56"
          @done="showConfirmApplyTemplateDialog = false"
        >
          <template v-slot="{ isSaving, applyMediakitTemplate }">
            <el-button
              v-if="!isSaving"
              class="button-green"
              @click="showConfirmApplyTemplateDialog = true"
            >
              <icon :icon="uploadIcon" />
              Apply Mediakit Template
            </el-button>
            <loading-button v-else class="w-full" />

            <confirm-dialog
              v-if="showConfirmApplyTemplateDialog"
              title="Are you sure you want to Apply the Media Kit Template?"
              :is-saving="isSaving"
              confirm-text="Apply Template"
              width="30em"
              @close="showConfirmApplyTemplateDialog = false"
              @confirm="applyMediakitTemplate"
            >
              Are you sure you want to apply the changes to the Media Kit
              template for this Publisher?

              <el-alert type="warning" title="Careful!" class="mt-5">
                This will remove unmodified Media Kit items and alter existing
                items to match them to the template.
              </el-alert>
            </confirm-dialog>
          </template>
        </supplier-mutation>
      </div>
    </div>

    <div class="mediakit-content margin-top-xxl">
      <mediakit-query>
        <template slot-scope="{ isLoading, data }">
          <template v-if="data">
            <mediakit-card-list :items="data.mediums">
              <template slot="enabled-text">
                Enabled Mediums
              </template>
              <template slot="disabled-text">
                Disabled Mediums
              </template>

              <mediakit-medium-card slot="new-card" is-new />

              <medium-mutation slot="card" slot-scope="{ item }">
                <template
                  slot-scope="{
                    isDeleting,
                    createMedium,
                    updateMedium,
                    deleteMedium
                  }"
                >
                  <mediakit-medium-card
                    :medium="item"
                    :class="{ 'opacity-30': isDeleting }"
                    @view="goToMedium(item)"
                    @create="createMedium"
                    @delete="deleteMedium(item.id)"
                    @update="updateMedium(item, $event)"
                  />
                </template>
              </medium-mutation>
            </mediakit-card-list>
          </template>

          <loader-grid
            v-else-if="isLoading"
            :item-width="19"
            column-class="w-1/4"
          />

          <el-alert
            v-else
            :closable="false"
            class="text-center"
            title="Mediakit was not found"
            type="error"
          />
        </template>
      </mediakit-query>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import MediakitCardList from './Common/MediakitCardList';
import MediakitMediumCard from './Medium/MediakitMediumCard';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import { LoaderGrid } from '@/components/Core/Loaders';
import { MediakitQuery } from '@/components/Queries';
import { MediumMutation, SupplierMutation } from '@/components/Mutations';
import { upload as uploadIcon } from '@/vendor/icons';

export default {
  components: {
    ConfirmDialog,
    LoaderGrid,
    LoadingButton,
    MediakitCardList,
    MediakitMediumCard,
    MediakitQuery,
    MediumMutation,
    SupplierMutation,
    ThePageHeader
  },

  data() {
    return {
      isSyncing: false,
      showConfirmApplyTemplateDialog: false,

      // Icons
      uploadIcon
    };
  },

  methods: {
    goToMedium(medium) {
      // If there is only 1 property, go directly to that property
      if (medium && medium.properties.length === 1) {
        let property = medium.properties[0];

        // If there is only 1 collection go directly to that collection
        if (property.collections.length === 1) {
          let collection = property.collections[0];

          this.$router.push({
            name: 'supplier.mediakit.collection',
            params: { id: collection.id }
          });
        } else {
          this.$router.push({
            name: 'supplier.mediakit.property',
            params: { id: property.id }
          });
        }
      } else {
        this.$router.push({
          name: 'supplier.mediakit.medium',
          params: { id: medium.id }
        });
      }
    }
  }
};
</script>
