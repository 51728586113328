<template>
  <el-card class="picture-card">
    <div class="header">
      <div class="banner is-position-top">
        <slot name="banner" />
      </div>

      <slot name="picture">
        <div v-if="primaryPicture" class="picture-list flex is-ancestor m-0">
          <div
            class="p-0 primary-picture"
            :class="{
              'has-siblings':
                additionalPictures && additionalPictures.length > 0
            }"
          >
            <div class="m-0">
              <loading-image
                :src="primaryPicture"
                class="picture"
                :height="pictureHeight"
              />
            </div>
          </div>

          <div
            v-if="additionalPictures"
            class="flex flex-1 flex-col p-0 additional-pictures"
          >
            <div
              v-for="(picture, index) in additionalPictures"
              :key="`picture-${picture.id}`"
              class="m-0"
              :class="{
                'pt-1/2':
                  index === additionalPictures.length - 1 &&
                  additionalPictures.length > 1
              }"
            >
              <loading-image
                :src="picture"
                class="picture"
                :height="additionalPictureHeight"
              />
            </div>
          </div>
        </div>

        <!-- No Pictures -->
        <template v-else>
          <slot name="empty">
            <loading-image src="" class="picture" />
          </slot>
        </template>
      </slot>

      <div class="header-overlay flex">
        <slot name="header-overlay" />
      </div>
    </div>

    <div class="content">
      <slot />
    </div>
  </el-card>
</template>

<script>
import { optimizedFile } from '@/utils/helpers';
import LoadingImage from '@/components/Core/Loading/LoadingImage';

export default {
  components: {
    LoadingImage
  },
  props: {
    pictures: { type: Array, default: () => [] },
    pictureHeight: {
      type: Number,
      default: 11
    }
  },

  computed: {
    primaryPicture() {
      return this.pictures && this.pictures.length > 0
        ? optimizedFile(this.pictures[0])
        : false;
    },

    additionalPictures() {
      return this.pictures && this.pictures.length > 1
        ? optimizedFile(this.pictures.slice(1))
        : false;
    },

    additionalPictureHeight() {
      if (this.additionalPictures && this.additionalPictures.length > 1) {
        return this.pictureHeight / 2 - 0.05;
      }

      return this.pictureHeight;
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

$picture-gap: 2px;

.picture-card {
  width: 100%;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.6);
  border: solid 1px $color-gray;

  .el-card__body {
    padding: 0;
  }

  .content {
    padding: 1em 0.8em;
  }

  .picture {
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .primary-picture {
    flex: 1;

    &.has-siblings {
      width: 62%;
      flex: none;
    }
  }

  .additional-pictures {
    margin-left: $picture-gap;
  }

  .header {
    position: relative;

    .header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s;
      z-index: 15;
    }

    &:hover {
      .header-overlay {
        opacity: 1;
      }
    }
  }

  .banner {
    z-index: 10;
  }
}
</style>
