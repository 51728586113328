<template>
  <div
    class="create-new has-content-centered parent-hover is-clickable"
    :class="[disabled ? 'cursor-default' : 'cursor-pointer']"
    @click="!disabled && $emit('edit')"
  >
    <el-tooltip placement="bottom" :disabled="!disabled" effect="light">
      <template #content>
        <div class="w-40">
          <a
            href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
            target="_blank"
            >Learn more about user permission roles
          </a>
        </div>
      </template>
      <section :class="{ 'cursor-not-allowed': disabled }">
        <div class="text-center">
          <icon :icon="plusCircle" />
        </div>

        <div class="text">
          <slot>Create New</slot>
        </div>
      </section>
    </el-tooltip>
  </div>
</template>

<script>
import { plusCircle } from '@/vendor/icons';

export default {
  name: 'MediakitNewCard',

  data() {
    return {
      plusCircle
    };
  },

  computed: {
    disabled() {
      return !this.$can('pub_create_mediakit_items');
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

.create-new {
  width: 100%;
  height: 100%;
  min-height: 15.6em;
  border: 1px dashed $color-gray;
  border-radius: 4px;
  color: $color-dark-silver;
}
</style>
