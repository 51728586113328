<template>
  <el-form
    ref="form"
    :model="editable"
    :rules="rules"
    class="mediakit-collection-settings text-dark-gray"
  >
    <custom-el-form-item
      type="columns"
      label="Collection Type"
      label-class="font-semibold"
      prop="typeEdges"
    >
      <template slot="help">
        Your collection type won’t be shown to buyers. This classification is to
        help flytedesk know how to sell your inventory nationally.
      </template>

      <mediakit-types-query collections :medium-types="medium.typeEdges">
        <template v-slot="{ data: collectionTypes }">
          <media-kit-select-type
            v-model="editable.typeEdges"
            :types="collectionTypes"
            @change="change"
          />
        </template>
      </mediakit-types-query>
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Collection Name"
      class="margin-top-xl"
      label-class="font-semibold"
      prop="name"
    >
      <template slot="help">
        This is what the buyer will see as a category when browsing your ad
        shop. This name represents a more specific subset of your property.
      </template>

      <el-input v-model="editable.name" @change="change" />
    </custom-el-form-item>

    <custom-el-form-item
      v-if="$can('manage_super_objects')"
      type="columns"
      prop="is_super"
      class="margin-top-xl"
      label="Toggle Super Media Kit"
      label-class="font-semibold text-md"
    >
      <custom-el-switch
        v-model="editable.is_super"
        green-red
        active-text="Super Media Kit"
        inactive-text="Standard Media Kit"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Collection Description (Optional)"
      class="margin-top-xl"
      label-class="font-semibold"
    >
      <template slot="help">
        This is what the buyer will see as a category when browsing your ad
        shop. This name represents a more specific subset of your property.
      </template>

      <el-input
        v-model="editable.description"
        type="textarea"
        rows="5"
        placeholder="Give your buyers more information about this particular collection"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Enabled Status"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
    >
      <template slot="help">
        Choose whether this collection is enabled.
        <br />Note: buyers will not be able to view this collection until at
        least one product is added.
      </template>

      <custom-el-switch
        key="collection-status"
        v-model="editable.is_enabled"
        class="toggle-green-red"
        active-text="This collection is enabled and visible to buyers"
        inactive-text="This collection is disabled and not visible to buyers"
        @change="change"
      />
    </custom-el-form-item>

    <digital-script-tag-settings
      v-if="property.is_digital_content"
      :property="property"
      :medium="medium"
    />

    <custom-el-form-item
      type="columns"
      label="Reach Type"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
      help="Choose whether a reach number is applicable to products in this property (e.g. followers, impressions, or audience)."
    >
      <custom-el-switch
        key="collection-reach"
        v-model="editable.is_reach_applicable"
        green-red
        :disabled="collection.is_template"
        :disabled-message="
          'This is a flytedesk curated setting, which you are unable to modify. This allows us to sell your products nationally.'
        "
        @change="change"
      >
        <template slot="active">
          Reach is enabled
        </template>
        <template slot="inactive">
          Reach is not applicable to this Collection
        </template>
      </custom-el-switch>
    </custom-el-form-item>

    <transition name="fadeDown">
      <custom-el-form-item
        v-if="editable.is_reach_applicable"
        type="columns"
        label="Reach"
        class="margin-top-xl"
        label-class="font-semibold"
        no-click-focus
      >
        <template slot="help">
          This number helps indicate the size of the audience that will be when
          they book an ad in this collection.
        </template>

        <reach-types-query @data="setReachTypes">
          <template slot-scope="{ isLoading }">
            <template v-if="!isLoading">
              <custom-el-form-item
                label="Reach Type"
                label-class="font-semibold"
              >
                <el-select
                  type="textarea"
                  class="pad-vert-xs w-full"
                  filterable
                  allow-create
                  rows="5"
                  :value="editable.reachType.id"
                  :disabled="
                    collection.is_template && !$can('manage_mediakit_templates')
                  "
                  @change="changeReachType"
                >
                  <curated-option-groups :options="reachTypes">
                    <el-option
                      :key="`reach-type-${option.id}`"
                      slot-scope="{ option }"
                      :label="option.value"
                      :value="option.id"
                    />
                  </curated-option-groups>

                  <curated-lock-icon
                    v-if="collection.is_template"
                    slot="suffix"
                    class="el-input__icon"
                  />
                </el-select>
              </custom-el-form-item>

              <custom-el-form-item
                label="Reach Number"
                class="margin-top"
                label-class="font-semibold"
                prop="reaches"
              >
                <div class="reach-numbers pad-vert-sm">
                  <collection-reach-number
                    v-for="reach in sortedReaches"
                    :key="`reach-${reach.id}`"
                    :reach="reach"
                    :reaches="editable.reaches"
                    :locked="!isReachEditable"
                    @change="changeReach"
                    @delete="deleteReach(reach.id)"
                  />
                </div>

                <div class="add-reach-number">
                  <a class="text-dark-silver" @click="addReachNumber">
                    + Add reach time period
                  </a>
                </div>
              </custom-el-form-item>
            </template>

            <div v-else class="relative">
              <loader-heading />
              <loader-heading />
            </div>
          </template>
        </reach-types-query>
      </custom-el-form-item>
    </transition>

    <custom-el-form-item
      v-if="property.has_dimensions"
      type="columns"
      label="Edition Dimensions"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
    >
      <template slot="help">
        We will use this information to determine particular ad dimensions,
        which will help you fill out your information faster.
      </template>

      <div class="flex items-end">
        <div class="block p-3">
          <dimension-input
            :dimension.sync="editable.dimension"
            @change="change"
          />
        </div>

        <transition name="slideLeft">
          <div v-if="canApplyDimensions" class="block p-3">
            <el-tooltip effect="light">
              <div slot="content">
                Apply the dimensions you set here to the known products in the
                {{ collection.name }} collection.<br />
                <br />
                For example, this will set Full Page to
                {{ editable.dimension.width }} x
                {{ editable.dimension.height }}
                {{ editable.dimension.unit }} and Half Page to
                {{ editable.dimension.width }} x
                {{ editable.dimension.height / 2 }}
                {{ editable.dimension.unit }}
              </div>

              <collection-mutation
                v-if="collection.id"
                :property-id="property.id"
              >
                <template slot-scope="{ isSaving, applyDimensions }">
                  <el-button
                    v-if="!isSaving"
                    class="button-blue"
                    @click="
                      applyDimensions(collection, {
                        dimension: editable.dimension
                      })
                    "
                  >
                    <span class="margin-right-xs">Apply</span>
                    <icon :icon="infoCircled" />
                  </el-button>
                  <loading-button v-else />
                </template>
              </collection-mutation>
            </el-tooltip>
          </div>
        </transition>
      </div>
    </custom-el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep, extend, find, sortBy, uniqueId } from 'lodash';
import { remove } from '@/utils/helpers';
import { iso8601 } from '@/utils/filters';
import { infoCircled } from '@/vendor/icons';

import CollectionReachNumber from './CollectionReachNumber';
import CuratedLockIcon from '@/components/Core/MediaKit/CuratedLockIcon';
import CuratedOptionGroups from '@/components/Core/CuratedOptionGroups';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import DimensionInput from '@/components/Supplier/Mediakit/Common/DimensionInput';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import MediaKitSelectType from '@/components/Core/MediaKit/MediaKitSelectType';
import { LoaderHeading } from '@/components/Core/Loaders';
import { MediakitTypesQuery, ReachTypesQuery } from '@/components/Queries';
import { CollectionMutation } from '@/components/Mutations';
import { ReachTypes } from '@/constants';
import DigitalScriptTagSettings from '@/components/Supplier/Mediakit/Common/DigitalScriptTagSettings';

export default {
  components: {
    DigitalScriptTagSettings,
    CollectionMutation,
    CollectionReachNumber,
    CuratedLockIcon,
    CuratedOptionGroups,
    CustomElFormItem,
    CustomElSwitch,
    DimensionInput,
    LoaderHeading,
    LoadingButton,
    MediaKitSelectType,
    MediakitTypesQuery,
    ReachTypesQuery
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    collection: { type: Object, default: null },
    isCopying: Boolean
  },

  data() {
    let editable = {
      is_template: false,
      typeEdges: [],
      name: '',
      products: [],
      is_enabled: true,
      is_reach_applicable: !this.property.is_digital_content,
      reaches: [],
      dimension: null,

      ...cloneDeep(this.collection)
    };

    if (!editable.reachType) {
      editable.reachType = {
        id: '',
        value: ''
      };
    }

    return {
      editable,
      collectionTypes: null,
      reachTypes: [],
      dimensionUnits: [],
      rules: {
        name: [
          { required: true, message: 'Please provide a name', trigger: 'blur' },
          {
            message: 'Must be less than 60 characters',
            max: 60,
            trigger: 'change'
          }
        ],
        typeEdges: [
          {
            required: true,
            message: 'You must select at least 1 Collection Type',
            trigger: 'change'
          }
        ],
        reaches: [{ validator: this.validateReaches, trigger: 'blur' }]
      },

      // Icons
      infoCircled
    };
  },

  computed: {
    isCreating() {
      return !this.collection.id;
    },

    /**
     * If the Reach type is set to the Population Reach Type
     */
    isPopulationReachType() {
      return this.editable.reachType.value === ReachTypes.POPULATION;
    },

    /**
     * If there are applicable dimensions set that can be applied to the Collection's products
     */
    canApplyDimensions() {
      if (!this.isCreating && !this.isCopying) {
        return (
          this.editable.dimension &&
          this.editable.dimension.width &&
          this.editable.dimension.height
        );
      }

      return false;
    },

    isTemplatePopulationReachType() {
      return this.collection.is_template && this.isPopulationReachType;
    },

    /**
     * Digital content cannot have it's reach editable
     */
    isReachEditable() {
      return (
        (!this.property.is_digital_content &&
          !this.isTemplatePopulationReachType) ||
        this.$can('manage_mediakit_templates')
      );
    },

    /**
     * Sorts the list of reaches by the start date. Always sorts the primary reach
     * at the top
     *
     * @returns {*}
     */
    sortedReaches() {
      return sortBy(this.editable.reaches, r => {
        // Primary is sorted to the top
        if (r.is_primary) return '0';

        if (r.date_start) return iso8601(r.date_start);

        // New date ranges are sorted to the bottom
        return 'z';
      });
    }
  },

  mounted() {
    // If this is for copying only, notify the parent the name is changed w/ - Copy appended by default
    if (this.isCopying) {
      this.editable.name += ' - Copy';
      this.change();
    }

    if (this.editable.reaches.length === 0) {
      this.addReachNumber();
    }
  },

  methods: {
    change() {
      this.$emit('change', this.editable);
    },

    changeReach(e) {
      let reach = find(this.editable.reaches, { id: e.id });

      extend(reach, e);

      this.change();
    },

    deleteReach(id) {
      remove(this.editable.reaches, { id });
    },

    changeReachType(id) {
      let type = find(this.reachTypes, { id });

      if (!type) {
        type = {
          id,
          value: id
        };
      }

      this.editable.reachType = type;

      this.change();
    },

    addReachNumber() {
      this.editable.reaches.push({
        id: uniqueId('reach-'),
        is_primary: this.editable.reaches.length === 0,
        value: 0,
        date_start: null,
        date_end: null
      });
    },

    setReachTypes(types) {
      this.reachTypes = types;

      if (!this.editable.reachType.id) {
        extend(this.editable.reachType, this.reachTypes[0]);
      }
    },

    /**
     * Reach cannot be 0 unless it is not editable by the user (ie: Digital content)
     */
    validateReaches(rule, value, callback) {
      if (this.isReachEditable) {
        for (let reach of value) {
          if (reach.is_primary && reach.value <= 0) {
            callback(new Error('Your Reach Number must be greater than 0'));
            return;
          }
        }
      }
      callback();
    },

    validate(callback) {
      return this.$refs.form.validate(callback);
    }
  }
};
</script>
