var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"form",staticClass:"mediakit-product-general",attrs:{"disabled":_vm.disabled,"model":_vm.product,"rules":_vm.rules}},[_c('div',{staticClass:"flex mt-3"},[_c('div',{staticClass:"flex-grow w-1/2"},[_c('custom-el-form-item',{attrs:{"label":"Product Name","tooltip":"This is what the buyer will see when browsing your Ad Shop. Choose a product name that will make sense to your buyers","label-class":"font-semibold text-md","prop":"name","disabled":_vm.disabled}},[_c('el-input',{staticClass:"w-full",attrs:{"value":_vm.product.name},on:{"input":function($event){return _vm.change({ name: $event })}}})],1)],1),_c('div',{staticClass:"flex-grow ml-8 w-1/2"},[_c('custom-el-form-item',{attrs:{"label":"Product Type","tooltip":"Your product type won't be shown to buyers. This classification is to help flytedesk know how to sell your inventory nationally.","label-class":"font-semibold text-md","prop":"typeEdges","disabled":_vm.disabled}},[_c('mediakit-types-query',{attrs:{"products":"","medium-types":_vm.medium.typeEdges},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var types = ref.data;
return [_c('mediakit-select-type',{attrs:{"types":types,"value":_vm.product.typeEdges},on:{"change":function($event){return _vm.change({ typeEdges: $event })}}})]}}])})],1)],1)]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-grow w-1/2"},[(_vm.$can('manage_super_objects'))?_c('custom-el-form-item',{attrs:{"label":"Toggle Super Media Kit","label-class":"font-semibold text-md","disabled":_vm.disabled}},[_c('custom-el-switch',{attrs:{"green-red":"","active-text":"Super Media Kit","inactive-text":"Standard Media Kit","value":_vm.product.is_super},on:{"change":function($event){return _vm.change({ is_super: $event })}}})],1):_vm._e()],1),(_vm.$can('manage_campuses'))?_c('div',{staticClass:"flex-grow w-1/2 ml-8"},[_c('custom-el-form-item',{attrs:{"label":"Campus","label-class":"font-semibold text-md","disabled":_vm.disabled}},[_c('el-select',{attrs:{"value":_vm.product.campus_id,"placeholder":"Choose Campus"},on:{"input":function($event){return _vm.change({ campus_id: $event })}}},_vm._l((_vm.campuses),function(campus){return _c('el-option',{key:("campus-" + (campus.id)),attrs:{"label":campus.name,"value":campus.id}})}),1)],1)],1):_vm._e()]),(_vm.$feature(_vm.FeatureFlags.PLACEMENT_SETTINGS))?[(_vm.$can('manage_digital_properties') && _vm.isDigitalContent)?_c('custom-el-form-item',{attrs:{"label":"Ad Unit Placement","label-class":"font-semibold text-md","disabled":_vm.disabled}},[_c('div',[_c('router-link',{attrs:{"to":{
            name: 'supplier.mediakit.placements',
            params: {
              property_id: _vm.property.id
            }
          }}},[_vm._v(" Manage Placement Settings ")])],1)]):_vm._e()]:_vm._e(),_c('custom-el-form-item',{staticClass:"mt-5",attrs:{"label":"Product Description","label-class":"font-semibold text-md","disabled":_vm.disabled}},[_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"Add more information about this product","value":_vm.product.description},on:{"input":function($event){return _vm.change({ description: $event })}}})],1),_c('div',{staticClass:"flex -mx-3 my-4"},[_c('div',{staticClass:"block p-3 flex-1"},[_c('custom-el-form-item',{attrs:{"label":"Product Photo(s)","tooltip":"Add photos to help your buyers get a better idea of the product you're offering. If your product has several variations, try adding examples of each.","label-class":"font-semibold text-md","no-click-focus":"","disabled":_vm.disabled}},[_c('upload-box',{attrs:{"disabled":_vm.disabled,"allow-primary":"","primary-file":_vm.product.primaryPhoto},on:{"change":_vm.changePhotos,"primary":_vm.changePrimary},model:{value:(_vm.photoList),callback:function ($$v) {_vm.photoList=$$v},expression:"photoList"}})],1)],1),_c('div',{staticClass:"block p-3 w-1/4"},[_c('custom-el-form-item',{attrs:{"label":"Edit Thumbnail","label-class":"font-semibold text-md","no-click-focus":""}},[_c('div',{staticClass:"primary-photo"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.product.primaryPhoto)?_c('img',{key:("photo-" + (_vm.product.primaryPhoto.id)),attrs:{"src":_vm.product.primaryPhoto.url}}):_c('el-tooltip',{key:"no-photo",attrs:{"content":"Choose a photo from the left to set your product thumbnail.","effect":"light","placement":"left"}},[_c('div',{staticClass:"no-primary-photo has-content-centered"},[_c('icon',{staticClass:"picture-icon",attrs:{"icon":_vm.picture}})],1)])],1)],1)])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }