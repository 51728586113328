<template>
  <div class="mediakit-product">
    <page-overlay
      ref="pageOverlay"
      location="right"
      manual-close
      :close-on-escape="!hasChanges"
      @click-outside="close"
      @close="$emit('close')"
    >
      <div slot="header" class="p-5">
        <div class="flex items-center">
          <div class="block p-3 flex-1">
            <a @click="close">
              <icon :icon="longArrowRight" :rotation="180" />
              <span class="pad-left-xs">Collection</span>
            </a>
          </div>

          <div class="block p-3 flex-1 text-right">
            <el-tooltip effect="light" :disabled="disabledParents.length === 0">
              <div slot="content" class="status-messages">
                <div v-for="msg in disabledParents" :key="`msg-${msg.msg}`">
                  <a @click="$router.push(msg.route)">{{ msg.msg }}</a>
                </div>
              </div>

              <product-mutation
                :collection-id="collection.id"
                @done="close(true)"
              >
                <template slot-scope="{ updateProduct }">
                  <custom-el-switch
                    v-model="editable.is_enabled"
                    left
                    :disabled="!$can('pub_disable_anything_in_mediakit')"
                    :class="
                      disabledParents.length > 0
                        ? 'toggle-yellow-red'
                        : 'toggle-green-red'
                    "
                    :active-text="
                      disabledParents.length > 0
                        ? 'This product is enabled but not visible to buyers'
                        : 'This product is enabled and visible to buyers'
                    "
                    inactive-text="This product is disabled and not visible to buyers"
                    @change="updateProduct(product, { is_enabled: $event })"
                  />
                </template>
              </product-mutation>
            </el-tooltip>
          </div>
        </div>
      </div>

      <div class="overlay-content pad-horiz-xxl">
        <div class="product-content">
          <div class="product-heading">
            <h2 class="product-name">
              {{ editable.name }}
              <mediakit-super-icon v-if="product.is_super" />
            </h2>
            <div class="pad-top-xs text-dark-silver text-md collection-name">
              {{ collection.name }}
              <mediakit-super-icon v-if="collection.is_super" />
            </div>
          </div>

          <div class="product-body pad-top">
            <el-tabs :value="activeTab">
              <el-tab-pane label="General" name="general">
                <div class="pad-top">
                  <mediakit-product-general
                    ref="general"
                    :disabled="!canEditProducts"
                    :is-digital-content="isDigitalContent"
                    :property="property"
                    :medium="medium"
                    :product.sync="editable"
                  />
                </div>
              </el-tab-pane>

              <el-tab-pane label="Pricing and Variants" name="variants">
                <div class="pad-top">
                  <mediakit-product-variants
                    ref="variants"
                    :disabled="!canEditProducts"
                    :medium="medium"
                    :property="property"
                    :collection="collection"
                    :product.sync="editable"
                    :is-new="isNew"
                  />
                </div>
              </el-tab-pane>

              <el-tab-pane label="Settings" name="settings">
                <div class="pad-top">
                  <mediakit-product-settings
                    ref="settings"
                    :is-disabled="!canEditProducts"
                    :medium="medium"
                    :product.sync="editable"
                    :property="property"
                  />
                </div>
              </el-tab-pane>

              <el-tab-pane
                v-if="$can('manage_digital_properties') && isDigitalContent"
                label="Auto Inject"
                name="auto-inject"
              >
                <div class="pad-top">
                  <label-content
                    label="Auto-Inject"
                    help="Automatically inject Ad Unit onto the property website"
                    label-class="font-semibold text-lg"
                  />
                  <auto-inject-settings
                    v-model="editable.auto_inject"
                    class="mt-2"
                    :property="property"
                    :product="editable"
                  />
                </div>
              </el-tab-pane>

              <el-tab-pane label="Additional Info" name="additional">
                <div class="pad-top">
                  <mediakit-product-additional
                    ref="additional"
                    :disabled="!canEditProducts"
                    :medium="medium"
                    :product.sync="editable"
                  />
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div slot="footer" class="pad-md box-shadow-left-sm">
        <product-mutation :collection-id="collection.id" @done="close(true)">
          <template slot-scope="{ loading, createProduct, updateProduct }">
            <div v-if="!loading" class="flex">
              <div class="block p-3 flex-1">
                <el-button class="button-white w-40" @click="close">
                  Cancel
                </el-button>
              </div>

              <div class="block p-3 flex-1 text-right">
                <el-button
                  :disabled="!canEditProducts || !hasChanges"
                  class="button-green"
                  @click="submit(createProduct, updateProduct)"
                >
                  Save Changes
                </el-button>
              </div>
            </div>

            <div v-else class="relative has-content-centered">
              <loading-button class="is-borderless" icon-class="icon-lg" />
            </div>
          </template>
        </product-mutation>
      </div>
    </page-overlay>

    <confirm-dialog
      v-if="showCancelConfirmDialog"
      title="Cancel Changing Product?"
      content="Are you sure you want to cancel the changes you have made to the product?"
      confirm-text="Cancel Changes"
      confirm-class="button-red"
      cancel-text="Keep Editing"
      width="23em"
      @confirm="closeOverlay"
      @close="showCancelConfirmDialog = false"
    />
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import { findRootPath } from '@/utils/helpers';
import { longArrowRight } from '@/vendor/icons';

import AutoInjectSettings from '@/components/Supplier/Mediakit/Product/AutoInjectSettings';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import LabelContent from '@/components/Core/LabelContent';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import MediakitProductAdditional from './MediakitProductAdditional';
import MediakitProductGeneral from './MediakitProductGeneral';
import MediakitProductSettings from './MediakitProductSettings';
import MediakitProductVariants from './MediakitProductVariants';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';
import PageOverlay from '@/components/Core/PageOverlay';
import { ProductMutation } from '@/components/Mutations';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
    AutoInjectSettings,
    CustomElSwitch,
    LabelContent,
    LoadingButton,
    MediakitProductAdditional,
    MediakitProductGeneral,
    MediakitProductSettings,
    MediakitProductVariants,
    MediakitSuperIcon,
    PageOverlay,

    // Queries
    ProductMutation
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    collection: {
      type: Object,
      required: true
    },
    product: { type: Object, default: null },
    isNew: Boolean
  },

  data() {
    let editable = {
      id: '',
      campus_id: this.$store.getters['auth/campuses'][0].id,
      typeEdges: [],
      name: '',
      is_template: false,
      is_enabled: true,
      auto_inject: [],
      description: '',
      primaryPhoto: null,
      photos: [],
      additionalFiles: [],
      additional_information: '',
      additional_attribute_columns: [],
      fulfillmentMethods: [],
      variants: [],

      ...cloneDeep(this.product)
    };

    return {
      activeTab: 'general',
      isSaving: false,
      editable,
      showCancelConfirmDialog: false,

      // Icons
      longArrowRight
    };
  },

  computed: {
    isDigitalContent() {
      return this.property.is_digital_content;
    },
    canEditProducts() {
      return this.$can('pub_edit_products');
    },
    hasChanges() {
      return !isEqual(this.product, this.editable);
    },
    property() {
      return findRootPath(this.medium.properties, ['collections'], {
        id: this.collection.id
      });
    },
    disabledParents() {
      let statuses = [];

      if (!this.medium.is_enabled) {
        statuses.push({
          msg: 'The Medium ' + this.medium.name + ' is disabled.',
          route: {
            name: 'supplier.mediakit.medium',
            params: { id: this.medium.id }
          }
        });
      }

      if (!this.property.is_enabled) {
        statuses.push({
          msg: 'The Property ' + this.property.name + ' is disabled.',
          route: {
            name: 'supplier.mediakit.property',
            params: { id: this.property.id }
          }
        });
      }

      if (!this.collection.is_enabled) {
        statuses.push({
          msg: 'The Collection ' + this.collection.name + ' is disabled.',
          route: {
            name: 'supplier.mediakit.collection',
            params: { id: this.collection.id }
          }
        });
      }

      return statuses;
    }
  },

  methods: {
    /**
     * Gracefully close the PageOverlay component
     */
    close(force) {
      if (this.hasChanges && force !== true) {
        this.showCancelConfirmDialog = true;
      } else {
        this.closeOverlay();
      }
    },

    closeOverlay() {
      this.showCancelConfirmDialog = false;
      this.$refs.pageOverlay.close();
    },

    handleError(tab, msg) {
      this.activeTab = tab;
      this.$message.error(msg);
    },

    async submit(create, update) {
      let general = this.$refs.general
        .validate()
        .catch(() =>
          this.handleError(
            'general',
            'Please resolve the issues in the General tab'
          )
        );

      let variants = this.$refs.variants
        .validate()
        .catch(() =>
          this.handleError(
            'variants',
            'Please resolve the issues in the Pricing and Variants tab'
          )
        );

      Promise.all([general, variants]).then(isValid => {
        if (isValid.indexOf(undefined) === -1) {
          if (this.isNew) {
            create(this.editable);
          } else {
            update(this.product, this.editable);
          }
        }
      });
    }
  }
};
</script>
