<template>
  <el-form class="mediakit-property-settings">
    <custom-el-form-item
      type="columns"
      label="Property Type"
      label-class="font-semibold"
      help="Your property type won’t be shown to buyers. This classification is to help flytedesk know how to sell your inventory nationally."
    >
      <mediakit-types-query properties :medium-types="medium.typeEdges">
        <template slot-scope="{ data: propertyTypes }">
          <media-kit-select-type
            v-model="editable.typeEdges"
            :types="propertyTypes"
            @change="change"
          />
        </template>
      </mediakit-types-query>
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Property Name"
      class="margin-top-xl"
      label-class="font-semibold"
      help="This is what the buyer will see as a category when browsing your ad shop. This may be a specific name of a publication or subset or products within your medium type."
    >
      <el-input v-model="editable.name" @input="change" />
    </custom-el-form-item>

    <custom-el-form-item
      v-if="$can('manage_super_objects')"
      type="columns"
      prop="is_super"
      class="margin-top-xl"
      label="Toggle Super Media Kit"
      label-class="font-semibold text-md"
    >
      <custom-el-switch
        v-model="editable.is_super"
        green-red
        active-text="Super Media Kit"
        inactive-text="Standard Media Kit"
        @change="change"
      />
    </custom-el-form-item>

    <digital-script-tag-settings
      v-if="property && property.id && editable.is_digital_content"
      :value="editable"
      :property="property"
      :medium="medium"
      @change="change"
    />

    <custom-el-form-item
      type="columns"
      label="Enabled Status"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
    >
      <template slot="help">
        Choose whether this property is enabled.
        <br />Note: buyers will not be able to view this property until at least
        one product is added.
      </template>

      <custom-el-switch
        key="property-status"
        v-model="editable.is_enabled"
        class="toggle-green-red"
        active-text="This property is enabled and visible to buyers"
        inactive-text="This property is disabled and not visible to buyers"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Product Photos"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
      help="Choose whether this property shows product photos to buyers."
    >
      <custom-el-switch
        key="property-photos"
        v-model="editable.are_photos_visible"
        class="toggle-green-red"
        active-text="Product photos are visible for this property"
        inactive-text="Product photos are not visible for this property"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Schedule Type"
      class="margin-top-xl"
      label-class="font-semibold"
      no-click-focus
    >
      <template slot="help">
        Do buyers book for one day at a time, or by time block?
        <a href="https://www.flytedesk.com/faq" target="_blank">
          Learn more about scheduling
        </a>
      </template>

      <schedule-types-query @data="setScheduleTypes">
        <template slot-scope="{ isLoading }">
          <el-radio-group
            v-if="!isLoading"
            :value="editable.scheduleType.id"
            @input="changeScheduleType"
            @change="change"
          >
            <el-radio
              v-for="scheduleType in scheduleTypes"
              :key="`schedule-type-${scheduleType.id}`"
              :label="scheduleType.id"
              class="radio-block"
            >
              <span class="text-dark-gray pad-right-xs">{{
                ScheduleTypeLabel[scheduleType.value]
              }}</span>

              <div
                class="help text-dark-silver pad-top-xs pad-bottom-lg pad-left-xxl"
              >
                {{ scheduleType.description }}
              </div>
            </el-radio>
          </el-radio-group>

          <loading-page v-else />
        </template>
      </schedule-types-query>
    </custom-el-form-item>
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import DigitalScriptTagSettings from '@/components/Supplier/Mediakit/Common/DigitalScriptTagSettings';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import MediaKitSelectType from '@/components/Core/MediaKit/MediaKitSelectType';
import { MediakitTypesQuery, ScheduleTypesQuery } from '@/components/Queries';

import { cloneDeep, find, isEmpty } from 'lodash';
import { MediumTypes, ScheduleTypeLabel } from '@/constants';
import { hasType } from '@/utils/helpers';
import { attention } from '@/vendor/icons';

export default {
  components: {
    CustomElFormItem,
    CustomElSwitch,
    DigitalScriptTagSettings,
    LoadingPage,
    MediaKitSelectType,

    // Queries
    MediakitTypesQuery,
    ScheduleTypesQuery
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    property: { type: Object, default: null }
  },

  data() {
    // Initialize an editable property
    let editable = {
      is_template: false,
      typeEdges: [],
      name: '',
      collections: [],
      is_enabled: true,
      is_digital_content: hasType(this.medium, MediumTypes.TYPE_DIGITAL),
      has_dimensions: false,
      are_photos_visible: true,
      website_url: '',
      min_days: 1,
      min_impressions: 0,
      blocked_content: [],
      scheduleType: {},

      ...cloneDeep(this.property)
    };

    return {
      editable,
      scheduleTypes: [],
      ScheduleTypeLabel,

      // Icons
      attention
    };
  },

  methods: {
    change() {
      this.$emit('change', this.editable);
    },

    changeScheduleType(id) {
      this.editable.scheduleType = find(this.scheduleTypes, { id });

      this.change();
    },

    setScheduleTypes(types) {
      this.scheduleTypes = types;

      if (isEmpty(this.editable.scheduleType)) {
        // Set the default schedule type if not set
        this.changeScheduleType(types[0].id);
      }
    }
  }
};
</script>
