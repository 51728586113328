<template>
  <el-container class="page-mediakit relative">
    <template v-if="$feature(FeatureFlags.MEDIA_KIT_BUILDER)">
      <transition name="fadeLeft">
        <router-view name="sidebar" />
      </transition>

      <div
        ref="mediakit"
        class="page-body h-full overflow-x-hidden overflow-y-auto"
      >
        <transition name="fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>

        <mediakit-types-query>
          <template slot-scope="{ isLoading }">
            <div v-if="isLoading"></div>
          </template>
        </mediakit-types-query>
      </div>
    </template>
    <template v-else>
      <page-mediakit-under-construction />
    </template>
  </el-container>
</template>

<script>
// We want to preload this data for a better user experience
import { MediakitTypesQuery } from '@/components/Queries';
import PageMediakitUnderConstruction from '@/components/Supplier/Mediakit/PageMediakitUnderConstruction';
import { FeatureFlags } from '@/constants';

export default {
  components: { PageMediakitUnderConstruction, MediakitTypesQuery },
  computed: {
    FeatureFlags() {
      return FeatureFlags;
    }
  },

  mounted() {
    if (this.$refs.mediakit) {
      this.$refs.mediakit.onscroll = e => {
        window.requestAnimationFrame(() => {
          this.$store.commit('common/setMediakitScrollY', e.target.scrollTop);
        });
      };
    }
  }
};
</script>

<style lang="scss">
.page-mediakit {
  height: 100%;

  .page-body {
    height: 100%;
    width: 100%;
  }

  .mediakit-column {
    min-width: 20em;
  }
}
</style>
