<template>
  <el-form
    ref="form"
    :model="product"
    :rules="rules"
    class="mediakit-product-variants"
  >
    <custom-el-form-item
      help="Define the options of your product. Each row below represents a variant of this product available for purchase. Add additional options by adding columns. "
      label="Product Variants"
      label-class="font-semibold text-md"
      no-click-focus
      prop="variants"
      :disabled="disabled"
      :class="{ 'cursor-not-allowed': disabled }"
    >
      <attribute-columns-query
        @data="attributeColumns = $event.attributeColumns"
      >
        <el-table
          slot-scope="{ isLoading }"
          :data="variants"
          border
          class="w-full margin-top-xxl el-table-slim"
          :class="{ 'pointer-events-none': disabled }"
        >
          <table-empty-slot
            slot="empty"
            :empty-message="'Please add a ' + product.name + ' variant'"
            :has-data="!!attributeColumns"
            :is-loading="isLoading"
            error-message="There was a problem loading your variants. Please try again later."
          />

          <el-table-column align="center" class-name="p-0" label="" width="50">
            <template slot-scope="{ row }">
              <custom-el-popover placement="right">
                <a slot="reference" class="open-menu pad">
                  <mediakit-super-icon v-if="row.is_super" />
                  <icon v-else :icon="ellipsisV" class="text-dark-silver" />
                </a>

                <div class="variant-menu">
                  <div v-if="$can('manage_super_objects')" class="menu-item">
                    <el-tooltip effect="light">
                      <template slot="content">
                        <div class="text-md">
                          Toggle Variant
                          <code>{{ row.id }}</code>
                          to be accessible only in the Super Media Kit
                        </div>
                      </template>
                      <a v-if="row.is_super" @click="setSuper(row, false)">
                        <mediakit-super-icon icon-class="text-dark-silver" />
                        <span class="ml-3">Make Standard</span>
                      </a>
                      <a v-else @click="setSuper(row, true)">
                        <mediakit-super-icon />
                        <span class="text-blue ml-3">Make Super</span>
                      </a>
                    </el-tooltip>
                  </div>
                  <div class="menu-item">
                    <a
                      v-if="row.is_bookable_ad_shop"
                      @click="setBookableAdShop(row, false)"
                    >
                      <icon :icon="enabledIcon" class="text-green" />
                      <span class="text-green">Ad Shop Enabled</span>
                    </a>
                    <a v-else @click="setBookableAdShop(row, true)">
                      <icon :icon="disabledIcon" class="text-dark-silver" />
                      <span class="text-dark-silver"
                        >Ad Shop Disabled (Only Selling Nationally)</span
                      >
                    </a>
                  </div>
                  <div class="menu-item">
                    <a v-if="!row.is_template" @click="removeVariant(row)">
                      <icon :icon="trashEmpty" />
                      Delete
                    </a>

                    <div v-else class="is-low-opacity">
                      <curated-lock-icon>
                        <span class="pad-left">Delete</span>
                      </curated-lock-icon>
                    </div>
                  </div>

                  <div class="menu-item">
                    <a @click="copyVariant(row)">
                      <icon :icon="docs" />
                      Copy
                    </a>
                  </div>
                </div>
              </custom-el-popover>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="Enabled"
            prop="is_enabled"
            width="80"
          >
            <template slot-scope="{ row }">
              <el-switch v-model="row.is_enabled" @change="changeVariants" />
            </template>
          </el-table-column>

          <el-table-column label="Name" min-width="200" prop="name">
            <div slot-scope="{ row }" class="hover-input">
              <el-input
                v-model="row.name"
                :class="{ 'input-error': !row.name }"
                @change="changeVariants"
              />
            </div>
          </el-table-column>

          <custom-el-table-column label="SKU" min-width="200" prop="sku">
            <template v-slot:header>
              SKU
              <info-tooltip class="ml-1">
                <sku-tooltip-content />
              </info-tooltip>
            </template>
            <div slot-scope="{ row }" class="hover-input">
              <div class="flex items-center">
                <div v-if="row.migrateSku !== null">
                  <el-tooltip
                    effect="light"
                    :content="
                      row.migrateSku ? 'Migrate Existing SKU' : 'Create New SKU'
                    "
                  >
                    <a
                      :class="
                        row.migrateSku
                          ? 'text-green hover:text-dark-green'
                          : 'text-blue'
                      "
                      class="mr-3"
                      @click="row.migrateSku = !row.migrateSku"
                    >
                      <icon :icon="row.migrateSku ? migrateIcon : plus" />
                    </a>
                  </el-tooltip>
                </div>
                <el-input
                  v-model="row.sku"
                  :class="{ 'input-error': !row.sku && row.id }"
                  placeholder="Auto Generated..."
                  @change="row.id ? (variantToChangeSku = row) : ''"
                />
              </div>
            </div>
          </custom-el-table-column>

          <el-table-column label="Rates" width="150">
            <template slot-scope="{ row }">
              <variant-rates-column
                :variant="row"
                :collection="collection"
                :enabled-rate-classes.sync="row.enabled_rate_classes"
                :impressions="hasImpressions"
                :rate-sheets.sync="row.rateSheets"
                @change="changeVariants"
              />
            </template>
          </el-table-column>

          <el-table-column label="Quantity" width="120">
            <template slot-scope="{ row }">
              <quantity-column
                :quantity.sync="row.quantity"
                :unit.sync="row.unit"
                @change="changeVariants"
              />
            </template>
          </el-table-column>

          <el-table-column label="Fulfillments" width="250">
            <div slot-scope="{ row }" class="hover-input">
              <variant-fulfillment-column
                :product="product"
                :value="row.attributes[AttributeColumnName.FULFILLMENTS] || []"
                @input="
                  changeAttributeValue(
                    row,
                    { name: AttributeColumnName.FULFILLMENTS },
                    $event
                  )
                "
              />
            </div>
          </el-table-column>

          <el-table-column
            v-if="mediumType.defaultCreativeForm && $feature('default-ads')"
            label="Default Ads"
            width="250"
          >
            <div slot-scope="{ row }" class="hover-input">
              <variant-default-creative-column
                :variant="row"
                :medium-type="mediumType"
              />
            </div>
          </el-table-column>

          <custom-el-table-column
            v-for="column in activeAttributeColumns"
            :key="`custom-column-${column.name}`"
            :label="column.name"
            :min-width="column.width || 140"
          >
            <template slot="header" slot-scope="{ column: columnData }">
              <span class="header-label">{{ columnData.label }}</span>
              <a
                v-if="!column.is_required"
                class="remove-column pad text-red"
                @click="removeColumn(column.name)"
              >
                <icon :icon="minus" />
              </a>
            </template>

            <div slot-scope="{ row }" class="hover-input">
              <el-input
                :is="column.component"
                :collection="collection"
                :medium="medium"
                :product="product"
                :property="property"
                :value="getAttributeValue(row, column)"
                :variant="row"
                v-bind="getColumnProps(column)"
                @input="changeAttributeValue(row, column, $event)"
              />
            </div>
          </custom-el-table-column>

          <custom-el-table-column align="center" class-name="p-0" width="50">
            <template slot="header">
              <a class="pad inline-block" @click="showAddColumnDialog = true">
                <icon :icon="plus" />
              </a>
            </template>
          </custom-el-table-column>
        </el-table>
      </attribute-columns-query>
    </custom-el-form-item>

    <div class="margin-top-xl">
      <el-button
        class="button-white-blue"
        :disabled="disabled"
        @click="addVariant"
      >
        Add New Variant
      </el-button>
    </div>

    <add-attribute-column-dialog
      v-if="showAddColumnDialog"
      :columns="inactiveAttributeColumns"
      @add="addColumn"
      @close="showAddColumnDialog = false"
    />

    <fulfillment-methods-query
      v-if="property.is_digital_content"
      @data="setFulfillmentMethods"
    />

    <edit-dialog
      v-if="variantToChangeSku"
      :key="'edit-sku-' + variantToChangeSku.id"
      title="Change Variant SKU"
      secondary-class="button-blue"
      secondary-text="Create New SKU"
      has-secondary
      primary-class="button-green"
      save-text="Migrate Existing SKU"
      @close="cancelSku"
      @secondary="changeSku(false)"
      @save="changeSku(true)"
    >
      <div>
        This SKU is used to identify ads that have been booked for stock
        keeping. You can either
        <span class="font-bold text-blue">Create New SKU</span>
        or
        <span class="font-bold text-green">Migrate Existing SKU</span>
      </div>
      <div class="mt-5">
        <div>
          <div class="font-bold text-blue">
            <icon :icon="plus" />
            Create a New SKU:
          </div>
          <div class="text-dark-silver ml-3">
            Do this if you're creating a brand new variant unrelated to the
            previous one. The old SKU ({{ variantToChangeSku.originalSku }})
            will still be used to reference previously booked ads associated to
            this variant, and treated as a legacy ad option. (You can create a
            new variant with the old SKU to associate it with the previously
            booked ads)
          </div>
        </div>
        <div class="mt-3">
          <div class="font-bold text-green">
            <icon :icon="migrateIcon" />
            Migrate Existing SKU: (most common option)
          </div>
          <div class="text-dark-silver ml-3">
            Do this if you simply want to rename this SKU to change how it
            appears across your account. All previously booked ads will be
            updated to reflect the new SKU ({{ variantToChangeSku.sku }}).
          </div>
        </div>
      </div>
    </edit-dialog>
  </el-form>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import EditDialog from '@/components/Core/EditDialog';
import CuratedLockIcon from '@/components/Core/MediaKit/CuratedLockIcon';

import {
  DimensionsColumn,
  FulfillmentColumn,
  MultiSelectColumn,
  QuantityColumn,
  ScriptTagColumn,
  SelectColumn,
  VariantFulfillmentColumn,
  VariantRatesColumn
} from '@/components/Core/Table/Columns';
import VariantDefaultCreativeColumn from '@/components/Core/Table/Columns/Mediakit/VariantDefaultCreativeColumn';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import TooltipIcon from '@/components/Core/TooltipIcon';
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';
import SkuTooltipContent from '@/components/Core/Tooltips/SkuTooltipContent';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElPopover from '@/components/Custom/CustomElPopover';
import CustomElTableColumn from '@/components/Custom/CustomElTableColumn';

import {
  AttributeColumnsQuery,
  FulfillmentMethodsQuery
} from '@/components/Queries';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';

import { AttributeColumnName, RateClass } from '@/constants';
import { remove } from '@/utils/helpers';

import {
  arrowsCw as migrateIcon,
  cancel as disabledIcon,
  docs,
  ellipsisV,
  minus,
  ok as enabledIcon,
  plus,
  trashEmpty
} from '@/vendor/icons';

import { cloneDeep, extend, find } from 'lodash';
import AddAttributeColumnDialog from './AddAttributeColumnDialog';

export default {
  components: {
    VariantDefaultCreativeColumn,
    InfoTooltip,
    SkuTooltipContent,
    EditDialog,
    ConfirmDialog,
    TooltipIcon,
    MediakitSuperIcon,
    AddAttributeColumnDialog,
    AttributeColumnsQuery,
    CuratedLockIcon,
    CustomElFormItem,
    CustomElPopover,
    CustomElTableColumn,
    FulfillmentMethodsQuery,
    TableEmptySlot,

    // Columns
    DimensionsColumn,
    FulfillmentColumn,
    MultiSelectColumn,
    ScriptTagColumn,
    SelectColumn,
    QuantityColumn,
    VariantFulfillmentColumn,
    VariantRatesColumn
  },
  props: {
    disabled: Boolean,
    isNew: {
      type: Boolean,
      required: true
    },
    medium: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    collection: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    let variants = cloneDeep(this.product.variants);

    for (let variant of variants) {
      if (!variant.attributes || Array.isArray(variant.attributes)) {
        variant.attributes = {};
      }

      if (!variant.rates) {
        variant.rates = {};
      }

      if (!variant.quantities) {
        variant.quantities = {};
      }

      variant.originalSku = variant.sku;
      variant.migrateSku = null;
    }

    const variantsValidator = (rule, variants, callback) => {
      for (let variant of variants) {
        if (!variant.name) {
          return callback(new Error('All Product Variants must have a name'));
        }
      }

      callback();
    };

    return {
      AttributeColumnName,
      variants,
      variantToChangeSku: null,
      attributeColumns: [],
      digitalFulfillmentMethodId: '',
      showAddColumnDialog: false,
      rules: {
        variants: [
          {
            validator: variantsValidator,
            message: 'Please provide a name',
            trigger: 'change'
          }
        ]
      },

      // Icons
      disabledIcon,
      docs,
      ellipsisV,
      enabledIcon,
      migrateIcon,
      minus,
      plus,
      trashEmpty
    };
  },

  computed: {
    attributes() {
      let attributes = {};
      this.product.typeEdges.forEach(element => {
        extend(attributes, element.type.attributes);
      });
      return attributes;
    },
    mediumType() {
      return this.medium.typeEdges[0] && this.medium.typeEdges[0].type;
    },
    isEmail() {
      return ['Email', 'Email Ad Serving'].includes(this.mediumType.value);
    },
    hasImpressions() {
      return this.property.is_digital_content;
    },

    inactiveAttributeColumns() {
      let columns = [];

      for (let col of this.attributeColumns) {
        if (!find(this.activeAttributeColumns, { name: col.name })) {
          columns.push(col);
        }
      }

      return columns;
    },

    activeAttributeColumns() {
      let columns = [];

      // Add all the Required Attribute columns from the Product Type
      for (let typeEdge of this.product.typeEdges) {
        if (typeEdge.type.required_attribute_columns) {
          for (let col of typeEdge.type.required_attribute_columns) {
            // Must guarantee unique columns!
            if (!find(columns, { name: col })) {
              let attrCol = cloneDeep(
                find(this.attributeColumns, { name: col })
              );

              if (attrCol) {
                attrCol.is_required = true;
                columns.push(attrCol);
              }
            }
          }
        }
      }

      // Add all the attribute columns added by this product
      for (let col of this.product.additional_attribute_columns) {
        // If the column doesn't already exist
        if (!find(columns, { name: col })) {
          let attrCol = cloneDeep(find(this.attributeColumns, { name: col }));

          if (attrCol) {
            attrCol.is_required = false;
            columns.push(attrCol);
          }
        }
      }

      if (this.isEmail) {
        columns.push(cloneDeep(find(this.attributeColumns, { name: 'Tag' })));
      }

      return columns.filter(col => !!col);
    }
  },

  mounted() {
    // TODO: Only add Variant with the correct IAB dimensions after Product Type is selected
    // When you create a product, if there exists no Product Variant, then instantiate one
    // if (!this.product.variants || !this.product.variants.length) {
    //   this.addVariant();
    // }
  },

  methods: {
    change(data) {
      this.$emit('update:product', { ...this.product, ...data });
    },

    cancelSku() {
      this.variantToChangeSku.sku = this.variantToChangeSku.originalSku;
      this.variantToChangeSku.migrateSku = null;
      this.variantToChangeSku = null;
    },
    changeSku(migrate) {
      this.variantToChangeSku.migrateSku = migrate;
      this.variantToChangeSku = null;
      this.changeVariants();
    },

    changeVariants() {
      this.change({ variants: this.variants });
    },

    setSuper(variant, is_super) {
      variant.is_super = is_super;
      this.changeVariants();
    },

    setBookableAdShop(variant, enabled) {
      variant.is_bookable_ad_shop = enabled;
      this.changeVariants();
    },

    getAttributeValue(row, column) {
      let selectedIDs = row.attributes[column.name];
      let defaultIDs =
        column.props && column.props.value ? column.props.value : '';

      // Either grab the existing attribute value or the column default
      return selectedIDs || defaultIDs;
    },

    changeAttributeValue(row, column, value) {
      row.attributes[column.name] = value;

      this.changeVariants();
    },

    /**
     * Add an attribute column to the product
     */
    addColumn(name) {
      this.change({
        additional_attribute_columns: this.product.additional_attribute_columns.concat(
          name
        )
      });

      this.showAddColumnDialog = false;
    },

    /**
     * Remove an Attribute Column from the product
     */
    removeColumn(name) {
      let additional_attribute_columns = cloneDeep(
        this.product.additional_attribute_columns
      ); // eslint-disable-line

      remove(additional_attribute_columns, n => n === name);

      this.change({ additional_attribute_columns });
    },

    /**
     * Gets all the required props to pass to the Component that renders this Column
     *
     * @param column
     */
    getColumnProps(column) {
      let props = {};

      if (column.props) {
        props = cloneDeep(column.props);

        if (props.bind) {
          // Map all the requested data bindings to the required prop for the component that renders this column
          for (let key of Object.keys(props.bind)) {
            props[props.bind[key]] = this.product[key];
          }

          delete props.bind;
        }
      }

      // TODO: Refactor after Attributes converted to relational table
      if (
        this.property.is_digital_content &&
        (column.name === AttributeColumnName.DIMENSIONS ||
          column.name === AttributeColumnName.FULFILLMENTS)
      ) {
        props.disabled = true;
      }

      return props;
    },

    addVariant() {
      const variant = {
        name: '',
        is_template: false,
        is_enabled: true,
        is_bookable_ad_shop: true,
        enabled_rate_classes: [RateClass.Standard],
        rateSheets: [],
        quantity: 0,
        unit: {},
        attributes: this.attributes || {} // Set to product type attributes if they exist
      };

      if (this.property.is_digital_content) {
        variant.attributes.Fulfillments = [this.digitalFulfillmentMethodId];
      }

      this.variants.push(variant);

      this.changeVariants();
    },

    removeVariant(variant) {
      remove(this.variants, variant);
      this.changeVariants();
    },

    copyVariant(variant) {
      let newVariant = cloneDeep(variant);

      delete newVariant.id;

      newVariant.is_template = false;

      newVariant.name = this.getCopyName(newVariant.name);

      this.variants.push(newVariant);
      this.changeVariants();
    },

    /**
     * Creates a unique Copy name given the original name
     */
    getCopyName(name) {
      name = name.replace(/ - Copy.*/, '') + ' - Copy';

      let newName = name;
      let count = 2;

      while (find(this.variants, { name: newName })) {
        newName = `${name} (${count++})`;
      }

      return newName;
    },

    setFulfillmentMethods(data) {
      const digital = data.fulfillmentMethods.find(
        a => a.name === 'Digital Ad'
      );
      if (this.isNew || this.product.fulfillmentMethods.length === 0) {
        this.digitalFulfillmentMethodId = digital.id;

        this.$emit('update:product', {
          ...this.product,
          fulfillmentMethods: [digital]
        });
      } else {
        this.digitalFulfillmentMethodId = this.product.fulfillmentMethods[0].id;
      }
    },

    validate(callback) {
      return this.$refs.form.validate(callback);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.hover-input /deep/ {
  .el-input__inner {
    transition: all 0.3s;
    border-color: transparent;
    background: transparent;

    &:active,
    &:focus {
      border-color: $color-gray;
      background: white;
    }
  }

  .el-select {
    .el-input__inner {
      transition: all 0.3s;
      padding-right: 0.3em;
    }

    .el-input__suffix {
      opacity: 0;
      transition: all 0.3s;
    }
  }
}

.hover-input:hover /deep/ {
  .el-input__inner {
    border-color: $color-gray;
    background: white;
  }

  .el-select {
    .el-input__inner {
      padding-right: 35px;
    }

    .el-input__suffix {
      opacity: 1;
    }
  }
}

.remove-column {
  opacity: 0;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.el-table /deep/ {
  td.p-0,
  th.p-0 {
    padding: 0 !important;

    .cell {
      padding: 0 !important;
    }
  }

  th {
    &:hover {
      .remove-column {
        opacity: 1;
      }
    }
  }
}

.menu-item {
  padding: 0 0.3em;

  & + .menu-item {
    border-top: 1px solid $color-gray;
    margin-top: 0.5em;
    padding-top: 0.5em;
  }

  a {
    color: $color-dark-gray;
    display: block;

    svg {
      margin-right: 0.8em;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
