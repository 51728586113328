<template>
  <div class="inventory-report">
    <template v-if="preview">
      <div
        class="percent-booked"
        :class="getBookedClass(percentBookedQuantity)"
      >
        <span>Booked {{ units }}:</span>
        <span class="font-bold ml-2"
          >{{ report.booked_quantity | number(0) }} /
          {{
            report.total_quantity >= SKU_UNLIMITED
              ? '∞'
              : report.total_quantity | number(0)
          }}
          <template v-if="percentBookedQuantity !== null">
            ({{ percentBookedQuantity | percent(1) }})
          </template>
        </span>
      </div>
    </template>
    <template v-else>
      <div class="font-bold text-center text-lg">
        {{
          report.total_quantity >= SKU_UNLIMITED
            ? 'Unlimited'
            : report.total_quantity
        }}
        total {{ units }} in inventory
      </div>
      <div class="font-semibold text-center text-base">
        {{
          report.available_quantity >= SKU_UNLIMITED
            ? 'Unlimited'
            : report.available_quantity
        }}
        {{ units }} available over {{ report.total_dates }} dates
        <span class="text-dark-silver text-sm"
          >({{ report.min_date | date }} to {{ report.max_date | date }})</span
        >
      </div>
      <div
        class="percent-booked-quantity text-center flex items-start justify-center mt-5"
      >
        <div class="booked-quantity-details w-48">
          <div>
            Booked {{ units }}
            <template v-if="percentBookedQuantity !== null"
              >({{ percentBookedQuantity | percent(1) }})
            </template>
          </div>
          <div :class="getBookedClass(percentBookedQuantity)">
            <div class="font-bold">
              {{ report.booked_quantity | number(0) }} Total
            </div>
            <div>{{ report.booked_quantity_local | number(0) }} Local</div>
            <div>
              {{ report.booked_quantity_national | number(0) }} National
            </div>
          </div>
        </div>
        <div class="w-100">
          <inventory-local-vs-national-pie-chart
            class="ml-5"
            :percent-local="percentBookedQuantityLocal"
            :percent-national="percentBookedQuantityNational"
          />
        </div>
      </div>
      <div
        v-if="percentImpressionsBooked !== null"
        class="percent-impressions-booked text-center mt-5 flex items-start justify-center"
      >
        <div class="booked-impressions-details w-48">
          <div>
            Booked Impressions ({{ percentImpressionsBooked | percent(1) }})
          </div>
          <div
            class="font-bold mt-2"
            :class="getBookedClass(percentImpressionsBooked)"
          >
            <div class="font-bold">
              {{ report.booked_impressions | number(0) }} Total
            </div>
            <div>{{ report.booked_impressions_local | number(0) }} Local</div>
            <div>
              {{ report.booked_impressions_national | number(0) }} National
            </div>
          </div>
        </div>
        <div class="w-100">
          <inventory-local-vs-national-pie-chart
            v-if="report.booked_impressions > 0"
            class="ml-5"
            :percent-local="percentBookedImpressionsLocal"
            :percent-national="percentBookedImpressionsNational"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InventoryLocalVsNationalPieChart from '@/components/Supplier/Analytics/InventoryLocalVsNationalPieChart';

const SKU_UNLIMITED = 999999999;

export default {
  components: {
    InventoryLocalVsNationalPieChart
  },
  props: {
    preview: Boolean,
    report: {
      type: Object,
      required: true
    }
  },
  data() {
    return { SKU_UNLIMITED };
  },
  computed: {
    units() {
      return 'Ads';
    },
    percentBookedQuantity() {
      return this.report.total_quantity >= SKU_UNLIMITED
        ? null
        : this.report.booked_quantity / this.report.total_quantity;
    },
    percentImpressionsBooked() {
      return this.report.total_impressions
        ? this.report.booked_impressions / this.report.total_impressions
        : null;
    },
    percentBookedQuantityNational() {
      return (
        this.report.booked_quantity_national /
        (this.report.booked_quantity || 1)
      );
    },
    percentBookedQuantityLocal() {
      return (
        this.report.booked_quantity_local / (this.report.booked_quantity || 1)
      );
    },
    percentBookedImpressionsNational() {
      return (
        this.report.booked_impressions_national /
        (this.report.booked_impressions || 1)
      );
    },
    percentBookedImpressionsLocal() {
      return (
        this.report.booked_impressions_local /
        (this.report.booked_impressions || 1)
      );
    }
  },
  methods: {
    getBookedClass(percent) {
      if (percent < 0.8) {
        return 'text-green';
      } else if (percent < 1) {
        return 'text-yellow';
      } else if (percent >= 1) {
        return 'text-red';
      }
    }
  }
};
</script>
