<template>
  <el-form
    ref="form"
    :disabled="disabled"
    :model="product"
    :rules="rules"
    class="mediakit-product-general"
  >
    <div class="flex mt-3">
      <div class="flex-grow w-1/2">
        <custom-el-form-item
          label="Product Name"
          tooltip="This is what the buyer will see when browsing your Ad Shop. Choose a product name that will make sense to your buyers"
          label-class="font-semibold text-md"
          prop="name"
          :disabled="disabled"
        >
          <el-input
            :value="product.name"
            class="w-full"
            @input="change({ name: $event })"
          />
        </custom-el-form-item>
      </div>

      <div class="flex-grow ml-8 w-1/2">
        <custom-el-form-item
          label="Product Type"
          tooltip="Your product type won't be shown to buyers. This classification is to help flytedesk know how to sell your inventory nationally."
          label-class="font-semibold text-md"
          prop="typeEdges"
          :disabled="disabled"
        >
          <mediakit-types-query products :medium-types="medium.typeEdges">
            <template slot-scope="{ isLoading, data: types }">
              <mediakit-select-type
                :types="types"
                :value="product.typeEdges"
                @change="change({ typeEdges: $event })"
              />
            </template>
          </mediakit-types-query>
        </custom-el-form-item>
      </div>
    </div>

    <div class="flex">
      <div class="flex-grow w-1/2">
        <custom-el-form-item
          v-if="$can('manage_super_objects')"
          label="Toggle Super Media Kit"
          label-class="font-semibold text-md"
          :disabled="disabled"
        >
          <custom-el-switch
            green-red
            active-text="Super Media Kit"
            inactive-text="Standard Media Kit"
            :value="product.is_super"
            @change="change({ is_super: $event })"
          />
        </custom-el-form-item>
      </div>
      <div v-if="$can('manage_campuses')" class="flex-grow w-1/2 ml-8">
        <custom-el-form-item
          label="Campus"
          label-class="font-semibold text-md"
          :disabled="disabled"
        >
          <el-select
            :value="product.campus_id"
            placeholder="Choose Campus"
            @input="change({ campus_id: $event })"
          >
            <el-option
              v-for="campus in campuses"
              :key="`campus-${campus.id}`"
              :label="campus.name"
              :value="campus.id"
            />
          </el-select>
        </custom-el-form-item>
      </div>
    </div>

    <template v-if="$feature(FeatureFlags.PLACEMENT_SETTINGS)">
      <custom-el-form-item
        v-if="$can('manage_digital_properties') && isDigitalContent"
        label="Ad Unit Placement"
        label-class="font-semibold text-md"
        :disabled="disabled"
      >
        <div>
          <router-link
            :to="{
              name: 'supplier.mediakit.placements',
              params: {
                property_id: property.id
              }
            }"
          >
            Manage Placement Settings
          </router-link>
        </div>
      </custom-el-form-item>
    </template>

    <custom-el-form-item
      class="mt-5"
      label="Product Description"
      label-class="font-semibold text-md"
      :disabled="disabled"
    >
      <el-input
        type="textarea"
        :rows="5"
        placeholder="Add more information about this product"
        :value="product.description"
        @input="change({ description: $event })"
      />
    </custom-el-form-item>

    <div class="flex -mx-3 my-4">
      <div class="block p-3 flex-1">
        <custom-el-form-item
          label="Product Photo(s)"
          tooltip="Add photos to help your buyers get a better idea of the product you're offering. If your product has several variations, try adding examples of each."
          label-class="font-semibold text-md"
          no-click-focus
          :disabled="disabled"
        >
          <upload-box
            v-model="photoList"
            :disabled="disabled"
            allow-primary
            :primary-file="product.primaryPhoto"
            @change="changePhotos"
            @primary="changePrimary"
          />
        </custom-el-form-item>
      </div>

      <div class="block p-3 w-1/4">
        <custom-el-form-item
          label="Edit Thumbnail"
          label-class="font-semibold text-md"
          no-click-focus
        >
          <div class="primary-photo">
            <transition name="fade" mode="out-in">
              <img
                v-if="product.primaryPhoto"
                :key="`photo-${product.primaryPhoto.id}`"
                :src="product.primaryPhoto.url"
              />

              <el-tooltip
                v-else
                key="no-photo"
                content="Choose a photo from the left to set your product thumbnail."
                effect="light"
                placement="left"
              >
                <div class="no-primary-photo has-content-centered">
                  <icon :icon="picture" class="picture-icon" />
                </div>
              </el-tooltip>
            </transition>
          </div>
        </custom-el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { FeatureFlags } from '@/constants';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import MediakitSelectType from '@/components/Core/MediaKit/MediaKitSelectType';
import UploadBox from '@/components/Core/UploadBox';
import { MediakitTypesQuery } from '@/components/Queries';

import { infoCircled, picture } from '@/vendor/icons';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';

export default {
  components: {
    CustomElSwitch,
    CustomElFormItem,
    MediakitSelectType,
    UploadBox,

    // Queries
    MediakitTypesQuery
  },
  props: {
    disabled: Boolean,
    isDigitalContent: Boolean,
    medium: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      // Consts
      FeatureFlags,

      // State
      photoList: [],
      productTypes: [],

      rules: {
        name: [
          {
            required: true,
            message: 'Please provide a name',
            trigger: 'change'
          },
          {
            message: 'Must be less than 60 characters',
            max: 60,
            trigger: 'change'
          }
        ],
        typeEdges: [
          {
            required: true,
            message: 'You must select at least 1 Product Type',
            trigger: 'change'
          }
        ]
      },

      // Icons
      infoCircled,
      picture
    };
  },

  computed: {
    campuses() {
      return this.$store.getters['auth/campuses'];
    }
  },

  mounted() {
    this.photoList = this.product.photos;
  },

  methods: {
    change(data) {
      this.$emit('update:product', { ...this.product, ...data });
    },

    changePhotos(photos) {
      this.photoList = photos;
      this.change({ photos });
    },

    changePrimary(file) {
      this.change({
        primary_photo_id: file.id,
        primaryPhoto: file
      });
    },

    validate(callback) {
      return this.$refs.form.validate(callback);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.primary-photo {
  width: 11.5em;
  height: 7.6em;
}

.no-primary-photo {
  width: 100%;
  height: 100%;
  background: $color-gray;

  .picture-icon {
    color: white;
    font-size: 6em;
  }
}
</style>
