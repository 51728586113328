<template>
  <el-tooltip effect="light" :content="content">
    <div :class="iconClass">
      <icon :icon="superIcon" />
    </div>
  </el-tooltip>
</template>

<script>
import { starFull as superIcon } from '@/vendor/icons';

export default {
  props: {
    content: {
      type: String,
      default: 'Super Media Kit Item'
    },
    iconClass: {
      type: String,
      default: 'text-blue'
    }
  },

  data() {
    return {
      superIcon
    };
  }
};
</script>
