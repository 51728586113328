<template>
  <div class="mediakit-property pt-5 relative">
    <mediakit-query
      :property-id="$route.params.id"
      @data="property = $event.property"
      @medium="medium = $event"
    >
      <template slot-scope="{ isLoading }">
        <transition name="fade" mode="out-in">
          <div v-if="property">
            <sticky-header>
              <div class="flex">
                <div class="block p-3 flex-1">
                  <h2>
                    {{ property.name }}
                    <mediakit-super-icon v-if="property.is_super" />
                    <span class="pad-left-xxs">
                      <tooltip-icon
                        :is-disabled="
                          !$can('pub_edit_mediakit_settings_or_schedules')
                        "
                        :icon="cog1"
                        icon-class="icon-md text-dark-silver cursor-pointer"
                        reference-class="pl-2"
                        @click="isEditing = 'general'"
                      />
                    </span>
                  </h2>

                  <div class="sub-header">
                    <ul class="horizontal-list text-md">
                      <li>{{ collectionCount }} Collection</li>
                      <li>{{ productCount }} Products</li>
                    </ul>
                  </div>
                </div>

                <div class="block p-3 flex-1 text-right">
                  <property-mutation :medium-id="medium.id">
                    <template slot-scope="{ updateProperty }">
                      <el-tooltip
                        effect="light"
                        :disabled="disabledParents.length === 0"
                      >
                        <div slot="content" class="status-messages">
                          <div
                            v-for="msg in disabledParents"
                            :key="`msg-${msg.msg}`"
                          >
                            <a @click="$router.push(msg.route)">
                              {{ msg.msg }}
                            </a>
                          </div>
                        </div>

                        <custom-el-switch
                          :disabled="!$can('pub_disable_anything_in_mediakit')"
                          :class="
                            disabledParents.length > 0
                              ? 'toggle-yellow-red'
                              : 'toggle-green-red'
                          "
                          left
                          :value="property.is_enabled"
                          :active-text="
                            disabledParents.length > 0
                              ? 'This property is enabled but not visible to buyers'
                              : 'This property is enabled and visible to buyers'
                          "
                          inactive-text="This property is disabled and not visible to buyers"
                          @change="
                            updateProperty(property, { is_enabled: $event })
                          "
                        />
                      </el-tooltip>
                    </template>
                  </property-mutation>
                </div>
              </div>
            </sticky-header>

            <div class="mediakit-content p-8">
              <mediakit-card-list :items="property.collections">
                <template slot="enabled-text">
                  Enabled Collections
                </template>
                <template slot="disabled-text">
                  Disabled Collections
                </template>

                <mediakit-collection-card
                  slot="new-card"
                  :medium="medium"
                  :property="property"
                  is-new
                />

                <collection-mutation
                  slot="card"
                  slot-scope="{ item }"
                  :property-id="property.id"
                >
                  <template
                    slot-scope="{
                      isDeleting,
                      createCollection,
                      updateCollection,
                      deleteCollection
                    }"
                  >
                    <mediakit-collection-card
                      :class="{ 'opacity-30': isDeleting }"
                      :medium="medium"
                      :property="property"
                      :collection="item"
                      @view="goToCollection(item)"
                      @copy="collectionToCopy = item"
                      @update="updateCollection(item, $event)"
                      @delete="deleteCollection(item.id)"
                    />
                  </template>
                </collection-mutation>
              </mediakit-card-list>
            </div>
          </div>

          <loader-content v-else-if="isLoading" class="p-5">
            <loader-heading class="is-width-xl p-3" :thickness="1.2" />
            <loader-grid
              :item-width="19"
              column-class="w-1/4"
              class="margin-top"
            />
          </loader-content>

          <el-alert
            v-else
            type="error"
            title="Property was not found"
            class="text-center"
            :closable="false"
          />
        </transition>

        <mediakit-property-dialog
          v-if="isEditing"
          :medium="medium"
          :property="property"
          @close="isEditing = false"
        />

        <mediakit-collection-dialog
          v-if="collectionToCopy"
          :medium="medium"
          :property="property"
          :collection="collectionToCopy"
          is-copying
          @close="collectionToCopy = false"
        />
      </template>
    </mediakit-query>
  </div>
</template>

<script>
import { countPath } from '@/utils/helpers';

import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import {
  LoaderContent,
  LoaderGrid,
  LoaderHeading
} from '@/components/Core/Loaders';
import MediakitCardList from '../Common/MediakitCardList';
import MediakitCollectionCard from '../Collection/MediakitCollectionCard';
import MediakitCollectionDialog from '../Collection/MediakitCollectionDialog';
import MediakitPropertyDialog from './MediakitPropertyDialog';
import TooltipIcon from '@/components/Core/TooltipIcon';
import StickyHeader from '@/components/Core/Layout/StickyHeader.vue';
import { MediakitQuery } from '@/components/Queries';
import { CollectionMutation, PropertyMutation } from '@/components/Mutations';

import { cog1 } from '@/vendor/icons';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';

export default {
  components: {
    MediakitSuperIcon,
    CustomElSwitch,
    LoaderContent,
    LoaderGrid,
    LoaderHeading,
    MediakitCardList,
    MediakitCollectionCard,
    MediakitCollectionDialog,
    MediakitPropertyDialog,
    TooltipIcon,
    StickyHeader,

    // Queries
    MediakitQuery,
    CollectionMutation,
    PropertyMutation
  },

  data() {
    return {
      medium: false,
      property: false,
      isEditing: false,
      collectionToCopy: false,

      // Icons
      cog1
    };
  },

  computed: {
    collectionCount() {
      return this.property.collections.length;
    },

    productCount() {
      return countPath(this.property.collections, ['products']);
    },

    disabledParents() {
      let statuses = [];

      if (!this.medium.is_enabled) {
        statuses.push({
          msg: 'The Medium ' + this.medium.name + ' is disabled.',
          route: {
            name: 'supplier.mediakit.medium',
            params: { id: this.medium.id }
          }
        });
      }

      return statuses;
    }
  },

  methods: {
    goToCollection(collection) {
      this.$router.push({
        name: 'supplier.mediakit.collection',
        params: { id: collection.id }
      });
    }
  }
};
</script>
