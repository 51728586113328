var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"picture-card"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"banner is-position-top"},[_vm._t("banner")],2),_vm._t("picture",[(_vm.primaryPicture)?_c('div',{staticClass:"picture-list flex is-ancestor m-0"},[_c('div',{staticClass:"p-0 primary-picture",class:{
            'has-siblings':
              _vm.additionalPictures && _vm.additionalPictures.length > 0
          }},[_c('div',{staticClass:"m-0"},[_c('loading-image',{staticClass:"picture",attrs:{"src":_vm.primaryPicture,"height":_vm.pictureHeight}})],1)]),(_vm.additionalPictures)?_c('div',{staticClass:"flex flex-1 flex-col p-0 additional-pictures"},_vm._l((_vm.additionalPictures),function(picture,index){return _c('div',{key:("picture-" + (picture.id)),staticClass:"m-0",class:{
              'pt-1/2':
                index === _vm.additionalPictures.length - 1 &&
                _vm.additionalPictures.length > 1
            }},[_c('loading-image',{staticClass:"picture",attrs:{"src":picture,"height":_vm.additionalPictureHeight}})],1)}),0):_vm._e()]):[_vm._t("empty",[_c('loading-image',{staticClass:"picture",attrs:{"src":""}})])]]),_c('div',{staticClass:"header-overlay flex"},[_vm._t("header-overlay")],2)],2),_c('div',{staticClass:"content"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }