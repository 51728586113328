<template>
  <el-form
    ref="form"
    :model="product"
    class="mediakit-product-additional"
    :disabled="disabled"
  >
    <custom-el-form-item
      label="Additional photos or files"
      tooltip="These will appear at the bottom of the product page. This is a good place to add extra information like a campus map or detailed product policies, if relevant."
      label-class="font-semibold text-md"
      no-click-focus
      class="pad-bottom-xxl"
      :disabled="disabled"
    >
      <upload-box
        v-model="fileList"
        :disabled="disabled"
        @change="changePhotos"
      />
    </custom-el-form-item>

    <custom-el-form-item
      label="Additional Information"
      tooltip="This will appear at the bottom of the product page, next to any additional photos or files you add above."
      label-class="font-semibold text-md"
      prop="additional_information"
      class="margin-top"
      :disabled="disabled"
    >
      <el-input
        type="textarea"
        :rows="5"
        placeholder="Add any extra information about this product you'd like buyers to see"
        :value="product.additional_information"
        @input="change({ additional_information: $event })"
      />
    </custom-el-form-item>
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import UploadBox from '@/components/Core/UploadBox';

export default {
  components: {
    CustomElFormItem,
    UploadBox
  },
  props: {
    disabled: Boolean,
    medium: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      fileList: []
    };
  },

  mounted() {
    this.fileList = this.product.additionalFiles;
  },

  methods: {
    change(data) {
      this.$emit('update:product', { ...this.product, ...data });
    },

    changePhotos(additionalFiles) {
      this.fileList = additionalFiles;

      this.change({ additionalFiles });
    },

    validate(callback) {
      return this.$refs.form.validate(callback);
    }
  }
};
</script>
