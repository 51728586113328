<template>
  <collection-mutation :property-id="property.id" @done="$emit('close')">
    <template
      slot-scope="{
        isSaving,
        createCollection,
        updateCollection,
        copyCollection
      }"
    >
      <edit-dialog
        :title="title"
        :is-saving="isSaving"
        :is-disabled="!changes"
        :class="{ 'p-0': showSchedule }"
        @save="submit(createCollection, updateCollection, copyCollection)"
        @close="$emit('close')"
      >
        <el-tabs
          v-if="showSchedule"
          type="border-card"
          :value="activeTab"
          stretch
          class="tab-buttons"
        >
          <el-tab-pane label="General" name="general">
            <mediakit-collection-settings
              ref="collectionSettings"
              :medium="medium"
              :property="property"
              :collection="collection"
              @change="
                change({
                  ...$event,
                  schedule: (changes || collection).schedule
                })
              "
            />
          </el-tab-pane>

          <el-tab-pane label="Schedule" name="schedule">
            <mediakit-collection-schedule
              ref="collectionSchedule"
              :schedule-type="property.scheduleType"
              :collection="collection"
              @change="change({ schedule: $event })"
              @init="initialUpdate({ schedule: $event })"
            />
          </el-tab-pane>
        </el-tabs>

        <mediakit-collection-settings
          v-else
          ref="collectionSettings"
          :medium="medium"
          :property="property"
          :collection="collection"
          :is-copying="isCopying"
          @change="change"
        />

        <template v-if="isCopying" slot="save-text">Copy</template>
      </edit-dialog>
    </template>
  </collection-mutation>
</template>

<script>
import { extend } from 'lodash';

import EditDialog from '@/components/Core/EditDialog';
import MediakitCollectionSchedule from './MediakitCollectionSchedule';
import MediakitCollectionSettings from './MediakitCollectionSettings';

import { CollectionMutation } from '@/components/Mutations';

export default {
  components: {
    EditDialog,
    MediakitCollectionSchedule,
    MediakitCollectionSettings,

    // Queries
    CollectionMutation
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    collection: { type: Object, default: null },
    isCopying: Boolean,
    tab: { type: String, default: null }
  },

  data() {
    return {
      activeTab: this.tab || 'general',
      changes: false,
      initialChanges: false,
      isValid: true
    };
  },

  computed: {
    isCreating() {
      return !this.collection.id;
    },

    showSchedule() {
      return !this.isCreating && !this.isCopying;
    },

    title() {
      if (this.isCopying) {
        return 'Copy ' + this.collection.name;
      } else if (this.isCreating) {
        return 'Create New Collection';
      } else {
        return this.collection.name + ' Settings';
      }
    }
  },

  methods: {
    change(changes) {
      this.changes = extend(this.changes || {}, changes);
    },

    initialUpdate(changes) {
      this.initialChanges = changes;
    },

    async submit(create, update, copy) {
      if (this.changes) {
        await this.$refs.collectionSettings.validate(async isValid => {
          this.isValid = isValid;

          if (isValid) {
            // incorporate the changes sent initially
            this.changes = extend(
              this.initialChanges || {},
              this.changes || {}
            );
            if (this.isCreating) {
              await create(this.changes);
            } else if (this.isCopying) {
              await copy(this.collection, this.changes);
            } else {
              await update(this.collection, this.changes);
            }
          } else {
            this.activeTab = 'general';
            this.$message.error(
              'Please fix any errors and try submitting again.'
            );
          }
        });
      }
    }
  }
};
</script>
