<template>
  <el-form class="mediakit-medium-settings">
    <custom-el-form-item
      type="columns"
      label="Medium Type"
      label-class="font-semibold"
    >
      <template slot="help">
        Your medium type won’t be shown to buyers. This classification is to
        help flytedesk know how to sell your inventory nationally.
      </template>

      <mediakit-types-query>
        <template slot-scope="{ isLoading, data: mediakitTypes }">
          <media-kit-select-type
            v-if="!isLoading"
            v-model="editable.typeEdges"
            :types="mediakitTypes"
            :multiple-limit="1"
            @change="change"
          />
        </template>
      </mediakit-types-query>
    </custom-el-form-item>

    <custom-el-form-item
      type="columns"
      label="Medium Name"
      class="mt-8"
      label-class="font-semibold"
    >
      <template slot="help">
        This name should help buyers know what kind of product you’re selling.
        For example, “print” or “digital” distinguish the overall format of the
        products within those categories.
      </template>

      <el-input v-model="editable.name" @change="change" />
    </custom-el-form-item>

    <custom-el-form-item
      v-if="$can('manage_super_objects')"
      type="columns"
      prop="is_super"
      class="margin-top-xl"
      label="Toggle Super Media Kit"
      label-class="font-semibold text-md"
    >
      <custom-el-switch
        v-model="editable.is_super"
        green-red
        active-text="Super Media Kit"
        inactive-text="Standard Media Kit"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      v-if="$can('manage_mediakit_templates')"
      type="columns"
      label="Lock Template"
      class="mt-8"
      label-class="font-semibold"
    >
      <template slot="help">
        If locked, then changes to the curated Media Kit template will not
        automatically be applied to this Medium. You will need to manually apply
        them with the Apply Template button
      </template>

      <custom-el-switch
        v-model="editable.is_template_locked"
        class="toggle-green-gray"
        active-text="Locked"
        inactive-text="Unlocked"
        @change="change"
      />

      <div class="mt-5">
        <el-button
          :disabled="isApplyingTemplate"
          class="button-blue"
          @click="showConfirmApplyDialog = true"
        >
          <template v-if="isApplyingTemplate">
            Applying...
          </template>
          <template v-else>
            Apply Template
          </template>
        </el-button>

        <confirm-dialog
          v-if="showConfirmApplyDialog"
          title="Are you sure you want to Apply the Media Kit Template?"
          :is-saving="isApplyingTemplate"
          width="30em"
          @close="showConfirmApplyDialog = false"
          @confirm="$emit('apply-template')"
        >
          Are you sure you want to apply the changes to the Media Kit template
          for {{ medium.name }}?
        </confirm-dialog>
      </div>

      <div class="mt-5">
        <el-button
          :disabled="isSavingAsTemplate"
          class="button-red"
          @click="showConfirmSaveTemplateDialog = true"
        >
          <template v-if="isSavingAsTemplate">
            Saving Template...
          </template>
          <template v-else>
            Set Template As Default
          </template>
        </el-button>

        <confirm-dialog
          v-if="showConfirmSaveTemplateDialog"
          title="Save Medium Template as Default?"
          confirm-text="Overwrite Template"
          confirm-class="button-red"
          :is-saving="isSavingAsTemplate"
          width="40em"
          @close="showConfirmSaveTemplateDialog = false"
          @confirm="$emit('save-template')"
        >
          Are you sure you want to use {{ medium.name }} as the default template
          for all publishers?

          <el-alert class="mt-5" title="Warning!" type="error">
            This will overwrite the existing Media Kit template for this Medium
            Type {{ medium.type }}
          </el-alert>
        </confirm-dialog>
      </div>
    </custom-el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import MediaKitSelectType from '@/components/Core/MediaKit/MediaKitSelectType';
import { MediakitTypesQuery } from '@/components/Queries';
import CustomElSwitch from '../../../Custom/CustomElSwitch';
import ConfirmDialog from '../../../Core/ConfirmDialog';

export default {
  name: 'MediakitMediumSettings',

  components: {
    ConfirmDialog,
    CustomElSwitch,
    CustomElFormItem,
    MediaKitSelectType,

    // Queries
    MediakitTypesQuery
  },
  props: {
    medium: { type: Object, default: null },
    isApplyingTemplate: Boolean,
    isSavingAsTemplate: Boolean
  },

  data() {
    let editable = {
      typeEdges: [],
      name: '',
      properties: [],
      is_enabled: true,

      ...cloneDeep(this.medium)
    };

    return {
      editable,
      showConfirmApplyDialog: false,
      showConfirmSaveTemplateDialog: false
    };
  },

  methods: {
    change() {
      this.$emit('change', this.editable);
    }
  }
};
</script>
