<template>
  <div class="mediakit-medium-card">
    <template v-if="isNew">
      <mediakit-new-card @edit="mediumToEdit = {}">
        Create New Medium
      </mediakit-new-card>
    </template>

    <template v-else>
      <mediakit-card
        :enabled="medium.is_enabled"
        :pictures="pictures"
        :can-delete="!medium.is_template"
        @view="$emit('view')"
        @edit="mediumToEdit = medium"
        @enable="$emit('update', { is_enabled: true })"
        @disable="$emit('update', { is_enabled: false })"
        @delete="$emit('delete')"
      >
        <h3 class="text-md">
          {{ medium.name }}
          <mediakit-super-icon v-if="medium.is_super" />
        </h3>
        <ul class="horizontal-list text-dark-silver text-xs">
          <li>{{ propertyCount }} Properties</li>
          <li>{{ collectionCount }} Collections</li>
          <li>{{ productCount }} Products</li>
        </ul>

        <template slot="disabled-text">Medium Disabled</template>
      </mediakit-card>
    </template>

    <mediakit-medium-dialog
      v-if="mediumToEdit"
      :medium="mediumToEdit"
      @close="mediumToEdit = null"
    />
  </div>
</template>

<script>
import { countPath, flattenPath } from '@/utils/helpers';

import MediakitCard from '../Common/MediakitCard';
import MediakitMediumDialog from './MediakitMediumDialog';
import MediakitNewCard from '../Common/MediakitNewCard';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';

export default {
  name: 'MediakitMediumCard',

  components: {
    MediakitSuperIcon,
    MediakitCard,
    MediakitMediumDialog,
    MediakitNewCard
  },
  props: {
    medium: { type: Object, default: null },
    isNew: Boolean
  },

  data() {
    return {
      isSaving: false,
      mediumToEdit: null
    };
  },

  computed: {
    propertyCount() {
      return this.medium.properties.length;
    },

    collectionCount() {
      return countPath(this.medium.properties, ['collections']);
    },

    productCount() {
      return countPath(this.medium.properties, ['collections', 'products']);
    },

    /**
     * The list of up to 3 pictures to feature
     */
    pictures() {
      return flattenPath(this.medium.properties, [
        'collections',
        'products',
        'primaryPhoto'
      ]).slice(0, 3);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.mediakit-medium-card {
  width: 19em;
}
</style>
