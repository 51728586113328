<template>
  <attribute-column-mutation>
    <template slot-scope="{ loading, createAttributeColumn }">
      <el-dialog
        title="Add Attribute"
        :visible="true"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="30em"
        @close="$emit('close')"
      >
        <div class="text-center">
          Add an attribute to indicate the differences between variants.<br />
          Buyers will see these attributes listed when selecting a product.
        </div>

        <div class="margin-top text-center">
          <el-autocomplete
            v-model="newAttributeName"
            class="inline-input w-full"
            :fetch-suggestions="searchInactiveColumns"
            value-key="name"
            placeholder="What attribute would you like to add?"
          >
            <template slot-scope="{ item }">
              <div class="column-list-item">
                <icon
                  v-if="item.is_curated"
                  class="text-blue"
                  :icon="flytedesk"
                />
                {{ item.name }}
              </div>
            </template>
          </el-autocomplete>
        </div>

        <template slot="footer">
          <el-button @click="$emit('close')">Cancel</el-button>
          <el-button
            class="button-blue"
            @click="addColumn(createAttributeColumn)"
            >Add
          </el-button>
        </template>
      </el-dialog>
    </template>
  </attribute-column-mutation>
</template>

<script>
import { AttributeColumnMutation } from '@/components/Mutations';

import { find } from 'lodash';
import { flytedesk } from '@/vendor/icons';

export default {
  components: {
    AttributeColumnMutation
  },

  props: {
    columns: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      newAttributeName: '',

      // Icons
      flytedesk
    };
  },

  methods: {
    searchInactiveColumns(q, cb) {
      let columns = [];

      let regex = new RegExp(q, 'i');

      for (let col of this.columns) {
        if (col.name.match(regex)) {
          columns.push(col);
        }
      }

      cb(columns);
    },

    addColumn(createAttributeColumn) {
      if (!find(this.columns, { name: this.newAttributeName })) {
        createAttributeColumn({
          name: this.newAttributeName,
          component: 'ElInput',
          props: null
        });
      }

      this.$emit('add', this.newAttributeName);
      this.newAttributeName = '';
    }
  }
};
</script>
