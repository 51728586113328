<template>
  <div class="local-vs-national-pie-chart text-center">
    <div class="flex justify-center w-32 m-auto">
      <div class="flex w-full justify-between">
        <span class="font-bold text-dark-green w-12">Local</span>
        <span>vs</span>
        <span class="font-bold text-dark-blue w-12">National</span>
      </div>
    </div>

    <div class="mt-3 flex items-center justify-center">
      <doughnut-chart
        class="relative w-100"
        :chart-data="localVsNationalChartData"
        :options="localVsNationalChartOptions"
      >
        <template #tooltip="{ tooltipModel }">
          <custom-chart-tooltip :tooltip-model="tooltipModel" />
        </template>
      </doughnut-chart>
    </div>
  </div>
</template>

<script>
import { colors } from '@/tailwind';
import DoughnutChart from '@/components/Core/Charts/DoughnutChart';
import CustomChartTooltip from '@/components/Core/Charts/CustomChartTooltip';

export default {
  components: { CustomChartTooltip, DoughnutChart },
  props: {
    percentLocal: {
      type: Number,
      required: true
    },
    percentNational: {
      type: Number,
      required: true
    }
  },
  computed: {
    localVsNationalChartData() {
      return {
        labels: ['National', 'Local'],
        datasets: [
          {
            data: [
              (this.percentNational * 100).toFixed(1),
              (this.percentLocal * 100).toFixed(1)
            ],
            backgroundColor: [colors['dark-blue'], colors['dark-green']]
          }
        ]
      };
    },
    localVsNationalChartOptions() {
      return {
        cutoutPercentage: 0
      };
    }
  }
};
</script>
