<template>
  <div class="auto-inject-settings">
    <transition-group tag="div" name="fadeLeft" class="relative">
      <div :key="'auto-inject-enabled'" class="auto-inject-enabled">
        <custom-el-switch
          :value="autoInject.enabled"
          green-gray
          active-text="Auto-Inject Enabled"
          inactive-text="Auto-Inject Disabled"
          @change="change({ enabled: $event })"
        />
      </div>
      <div
        v-if="autoInject.enabled"
        :key="'auto-inject-xpath'"
        class="auto-inject-xpath mt-5"
      >
        <label-content
          label="Xpath Location"
          label-class="font-semibold text-md"
        >
          <div slot="help">
            <div>
              Xpath location to Auto-inject this digital Ad Unit (ie:
              /html/body)
            </div>
            <div>
              <a target="_blank" href="https://devhints.io/xpath"
                >Xpath Cheat Sheet</a
              >
            </div>
          </div>
        </label-content>
        <el-input
          :value="autoInject.xpath"
          class="mt-2"
          @input="change({ xpath: $event })"
        />

        <label-content
          class="mt-3"
          label="Reject Parent Xpath"
          label-class="font-semibold text-md"
        >
          <div slot="help">
            This will reject any nodes (and their descendants) that match this
            Xpath.
          </div>
        </label-content>

        <el-input
          :value="autoInject.xpathReject"
          class="mt-2"
          @input="change({ xpathReject: $event })"
        />
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-force'"
        class="auto-inject-force mt-5"
      >
        <label-content
          label="Force Auto-Injection"
          label-class="font-semibold text-md"
          help="If this ad unit is already manually installed on the page, this will force injecting an additional ad unit"
        />
        <custom-el-switch
          :value="autoInject.force"
          class="mt-3"
          green-red
          active-text="Force inject additional unit"
          inactive-text="Skip injection if ad unt present"
          @change="change({ force: $event })"
        />
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-algorithm'"
        class="auto-inject-algorithm mt-5"
      >
        <label-content
          label="Injection Algorithm"
          label-class="font-semibold text-md"
        />

        <el-select
          :value="autoInject.algorithm"
          class="mt-3"
          @change="change({ algorithm: $event })"
        >
          <el-option
            v-for="algorithm in Algorithms"
            :key="algorithm.name"
            :label="algorithm.name"
            :value="algorithm.value"
          />
        </el-select>

        <transition name="fade" mode="out-in">
          <div
            :key="'algorithm-description-' + autoInject.algorithm"
            class="text-dark-silver text-sm mt-3"
          >
            {{ algorithmDescription }}
          </div>
        </transition>
      </div>
      <div
        v-if="
          autoInject.enabled &&
            autoInject.xpath &&
            autoInject.algorithm === 'in-content'
        "
        :key="'auto-inject-in-content-options'"
        class="mt-5 ml-5"
      >
        <div class="auto-inject-min-spacing">
          <label-content
            label="In-Content Minimum Spacing (px)"
            label-class="font-semibold text-md"
            help="The Ad Unit will appear at
            least this many pixels from the top of the injected node's parent,
            or from any other injected ad units"
          />
          <el-input
            v-mask.integer=""
            class="w-40"
            placeholder="0"
            :value="autoInject.minSpacing"
            @input="change({ minSpacing: $event })"
          >
            <div slot="suffix">px</div>
          </el-input>
        </div>
        <div class="auto-inject-node-offset mt-3">
          <label-content
            label="In-Content Node Offset"
            label-class="font-semibold text-md"
            help="The Ad Unit will be injected at least the given number of pixels after the nth matching xpath node.
            For example, setting count to 3, spacing to 100px and xpath to //p, the unit will
            be injected 100px below the 3rd <p> tag found inside the target node location (designated by all other Targeting xpath settings)."
          />
          <div class="mt-3">
            <custom-el-switch
              :value="autoInject.nodeOffset.enabled"
              green-gray
              active-text="Enabled"
              inactive-text="Disabled"
              @change="changeNodeOffset({ enabled: $event })"
            />
          </div>
          <transition name="fade" mode="out-in">
            <div
              v-if="autoInject.nodeOffset.enabled"
              class="flex mt-3 items-center"
            >
              <el-input
                v-mask.integer=""
                class="w-24 flex-shrink-0"
                placeholder="0"
                :value="autoInject.nodeOffset.spacing"
                @input="changeNodeOffset({ spacing: $event })"
              >
                <div slot="suffix">px</div>
              </el-input>
              <span class="mx-3 flex-shrink-0 text-md">Below the</span>
              <el-input
                v-mask.integer="{ min: 1, max: 999 }"
                class="w-20 flex-shrink-0"
                placeholder="0"
                :value="autoInject.nodeOffset.count"
                @input="changeNodeOffset({ count: $event })"
              >
                <div slot="suffix">
                  {{ tagCountQualifier }}
                </div>
              </el-input>
              <el-input
                class="ml-3 flex-grow"
                placeholder="xpath (ie: //p)"
                :value="autoInject.nodeOffset.xpath"
                @input="changeNodeOffset({ xpath: $event })"
              />
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-hide-on-mobile'"
        class="auto-inject-hide-on-mobile mt-5"
      >
        <label-content
          label="Hide on mobile devices"
          label-class="font-semibold text-md"
          help="If enabled, the Ad Unit will be hidden on mobile screen sizes. It will only be seen on desktop screen sizes (greater than 768px width)."
        />
        <custom-el-switch
          :key="'mobile-button-toggle'"
          :value="!!autoInject.minScreenSize"
          class="mt-3"
          green-gray
          active-text="Hidden on mobile"
          inactive-text="Shown on mobile"
          @change="change({ minScreenSize: $event ? 768 : 0 })"
        />
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-closeable'"
        class="auto-inject-closeable mt-5"
      >
        <label-content
          label="Closeable Ad Unit"
          label-class="font-semibold text-md"
          help="If enabled, the Ad Unit will have an 'x' close button that will hide the Ad Unit"
        />
        <custom-el-switch
          :key="'close-button-toggle'"
          :value="autoInject.closeable"
          class="mt-3"
          green-gray
          active-text="Close Button Visible"
          inactive-text="No Close Button"
          @change="change({ closeable: $event })"
        />
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-ad-label'"
        class="auto-inject-ad-label mt-5"
      >
        <label-content
          label="Advertisement Label"
          label-class="font-semibold text-md"
          help="Advertisement label will be displayed in or around the Ad"
        />
        <el-select
          :key="'ad-label-select'"
          :value="autoInject.adLabel.position"
          class="mt-3"
          @change="changeAdLabel({ position: $event })"
        >
          <el-option
            v-for="position in AdLabelPositions"
            :key="'ad-label-position-' + position.value"
            :value="position.value"
            :label="position.name"
          />
        </el-select>

        <transition name="fade" mode="out-in">
          <div v-if="autoInject.adLabel.position" class="mt-3 flex">
            <div>
              <el-input
                :value="autoInject.adLabel.text"
                class="w-72"
                :style="{ color: autoInject.adLabel.color }"
                placeholder="Advertisement Label Text"
                @change="changeAdLabel({ text: $event })"
              />
            </div>

            <div class="ml-3">
              <el-color-picker
                v-mask.hexColor
                :value="autoInject.adLabel.color"
                :predefine="AdLabelColors"
                @change="changeAdLabel({ color: $event })"
              />
            </div>
          </div>
        </transition>
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-allow-list'"
        class="auto-inject-allow-list mt-5"
      >
        <label-content
          label="Allowed / Blocked URLs"
          label-class="font-semibold text-md"
          help="Enter new line separated lists of regular expressions that match URLs you want to allow or block. For allowed, if the URL matches ANY regular expression, the unit will be auto-injected, unless it matches a regular expression in the block list. If allow list is empty, all URLs will auto-inject unless matching a blocked URL."
        />
        <div class="mt-3 flex">
          <div class="flex-grow">
            <h5 class="font-semibold">Allowed</h5>
            <el-input
              type="textarea"
              :value="autoInject.allowedUrls"
              class="w-full mt-3"
              @input="change({ allowedUrls: $event })"
            />
          </div>

          <div class="flex-grow ml-3">
            <h5 class="font-semibold">Blocked</h5>
            <el-input
              type="textarea"
              :value="autoInject.blockedUrls"
              class="w-full mt-3"
              @input="change({ blockedUrls: $event })"
            />
          </div>
        </div>
        <div class="mt-3 flex items-center">
          <div class="flex-shrink-0 mr-3">URL Test:</div>
          <el-input
            v-model="testUrl"
            class="w-full"
            :class="isUrlAllowed ? 'is-allowed' : 'is-blocked'"
          />
          <div :class="isUrlAllowed ? 'text-green' : 'text-red'" class="ml-3">
            <icon :icon="isUrlAllowed ? allowedIcon : blockedIcon" />
          </div>
        </div>
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-daily-visitor-limit'"
        class="auto-inject-daily-visitor-limit mt-5"
      >
        <label-content
          label="Daily Visitor Limit"
          label-class="font-semibold text-md"
          help="The maximum number of times a single visitor will see each ad running in this ad unit"
        />
        <el-input
          v-mask.integer
          :value="autoInject.dailyVisitorLimit"
          class="w-40 mt-3"
          @input="changeInt('dailyVisitorLimit', $event)"
        />
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-delay'"
        class="auto-inject-delay mt-5"
      >
        <label-content
          label="Active Delay (ms)"
          label-class="font-semibold text-md"
          help="Set delay on setting the flytead-active class, enabling delayed pop-ups, animations, etc."
        />
        <el-input
          v-mask.integer
          :value="autoInject.delay"
          class="w-40 mt-3"
          @input="changeInt('delay', $event)"
        />
      </div>
      <div
        v-if="autoInject.enabled && autoInject.xpath"
        :key="'auto-inject-css'"
        class="auto-inject-css mt-5"
      >
        <label-content
          label="Injected Styles"
          label-class="font-semibold text-md"
          help="Customize the placement of the ad unit with CSS"
        />
        <prism-code
          :value="autoInject.css"
          language="css"
          class="w-full min-h-40 block mt-3"
          contenteditable
          @input="change({ css: $event })"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import LabelContent from '@/components/Core/LabelContent';
import PrismCode from '@/components/Vendor/PrismCode';
import { pick } from 'lodash';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import { AdLabelColors, AdLabelPositions, Algorithms } from '@/constants';
import { cancel as blockedIcon, ok as allowedIcon } from '@/vendor/icons';

export default {
  components: {
    CustomElSwitch,
    PrismCode,
    LabelContent
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    property: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      testUrl: '',

      // Consts
      AdLabelColors,
      AdLabelPositions,
      Algorithms,

      // Icons
      allowedIcon,
      blockedIcon
    };
  },

  computed: {
    tagCountQualifier() {
      switch (+this.autoInject.nodeOffset.count) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    },
    isUrlAllowed() {
      const allowList = this.autoInject.allowedUrls
        .split('\n')
        .filter(a => !!a);
      const blockList = this.autoInject.blockedUrls
        .split('\n')
        .filter(b => !!b);

      if (blockList.length > 0) {
        for (let blocked of blockList) {
          if (this.testUrl.match(new RegExp(blocked))) {
            return false;
          }
        }
      }

      if (allowList.length > 0) {
        for (let allowed of allowList) {
          if (this.testUrl.match(new RegExp(allowed))) {
            return true;
          }
        }

        // If URL did not match any allowed, then we reject the URL
        return false;
      } else {
        // If not blocked and allow list is empty, we accept the URL
        return true;
      }
    },
    algorithmDescription() {
      let algorithm = Algorithms.find(
        a => a.value === this.autoInject.algorithm
      );

      return algorithm ? algorithm.description : '';
    },
    autoInject() {
      let defaults = {
        adLabel: {
          position: '',
          text: 'Advertisement',
          color: '#000000'
        },
        algorithm: Algorithms[0].value,
        allowedUrls: '',
        blockedUrls: '',
        closeable: false,
        closeableOverlay: false,
        css: '',
        dailyVisitorLimit: 0,
        delay: 0,
        enabled: false,
        force: false,
        minScreenSize: 0,
        minSpacing: 150,
        nodeOffset: {
          enabled: false,
          xpath: '//p',
          count: 3,
          spacing: 0
        },
        xpath: '',
        xpathReject: ''
      };

      let value = {
        ...defaults,
        ...this.defaultSettings,
        ...pick(this.value || {}, Object.keys(defaults))
      };

      // If the product type has changed, lets update the default settings
      if (value.productType !== this.defaultSettings.productType) {
        value = { ...value, ...this.defaultSettings };

        // Always keep any set values for the xpath
        if (this.value.xpath) {
          value.xpath = this.value.xpath;
        }
      }

      return value;
    },
    defaultSettings() {
      // find the first type that has auto_inject settings, and use as default
      for (let typeEdge of this.product.typeEdges) {
        if (typeEdge.type.settings && typeEdge.type.settings.auto_inject) {
          return {
            productType: typeEdge.type.value,
            ...typeEdge.type.settings.auto_inject
          };
        }
      }

      return {};
    }
  },

  watch: {
    autoInject(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.$emit('input', this.autoInject);
      }
    }
  },

  methods: {
    changeInt(key, value) {
      value = parseInt(value) || 0;
      if (value !== this.autoInject[key]) {
        this.change({ [key]: value });
      }
    },
    changeAdLabel(adLabel) {
      this.change({ adLabel: { ...this.autoInject.adLabel, ...adLabel } });
    },
    changeNodeOffset(nodeOffset) {
      this.change({
        nodeOffset: { ...this.autoInject.nodeOffset, ...nodeOffset }
      });
    },
    change(data) {
      this.$emit('input', { ...this.autoInject, ...data });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.is-allowed {
  /deep/ .el-input__inner {
    color: $color-green;
    border-color: $color-green;
    background: lighten($color-green, 35%);
  }
}

.is-blocked {
  /deep/ .el-input__inner {
    color: $color-red;
    border-color: $color-red;
    background: lighten($color-red, 30%);
  }
}
</style>
