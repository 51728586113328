<template>
  <div
    class="mediakit-card is-clickable"
    :class="{ 'is-saving': saving }"
    @click="$emit('edit')"
  >
    <picture-card :pictures="pictures" :picture-height="pictureHeight">
      <div class="flex items-center">
        <div class="block flex-1 info-column" :class="{ 'pr-0': showSettings }">
          <slot />
        </div>
        <div
          v-if="showSettings"
          class="block py-3 pr-3 pl-0 text-right edit-column parent-hover"
        >
          <tooltip-icon
            :is-disabled="!$can('pub_edit_mediakit_settings_or_schedules')"
            :icon="cog1"
            icon-class="animated rotate loop slow icon-md text-dark-silver cursor-pointer"
          />
        </div>
      </div>

      <loading-image slot="empty" class="picture" :height="pictureHeight">
        <template slot="empty">
          <div class="empty-picture">
            <div>No product photos</div>
            <div>
              <icon :icon="frownO" />
            </div>
          </div>
        </template>
      </loading-image>

      <template slot="header-overlay">
        <div
          class="overlay-actions is-position-top-right pad-right-sm"
          @click.stop
        >
          <slot name="overlay-actions">
            <template v-if="enabled">
              <tooltip-icon
                v-if="canCopy"
                reference-class="py-2 px-1 cursor-pointer hover:opacity-50"
                tooltip-while-enabled
                placement="top"
                icon-class="text-white text-lg"
                :is-disabled="!$can('pub_create_mediakit_items')"
                :icon="docs"
                @click="$emit('copy')"
              >
                <div slot="enabled">
                  Copy
                </div>
              </tooltip-icon>
              <tooltip-icon
                tooltip-while-enabled
                placement="top"
                reference-class="py-2 px-1 cursor-pointer hover:opacity-50"
                icon-class="text-white text-lg"
                :is-disabled="!$can('pub_disable_anything_in_mediakit')"
                :icon="disable"
                @click="$emit('disable')"
              >
                <div slot="enabled">
                  Disable
                </div>
              </tooltip-icon>
            </template>

            <tooltip-icon
              v-if="canDelete"
              class="py-2 px-1"
              tooltip-while-enabled
              placement="top"
              icon-class="text-white text-lg cursor-pointer hover:opacity-50"
              :is-disabled="!$can('pub_disable_anything_in_mediakit')"
              :icon="trashEmpty"
              @click="$emit('delete')"
            >
              <div slot="enabled">
                Delete
              </div>
            </tooltip-icon>
          </slot>
        </div>

        <div
          class="overlay-content has-content-centered"
          @click="$emit('view')"
        >
          <slot name="overlay-content">
            <div
              v-if="saving"
              class="action-button button-clear-white el-button"
            >
              Updating...
            </div>
            <div v-else>
              <el-button
                v-if="enabled"
                class="action-button thick button-clear-white"
              >
                View
              </el-button>
              <el-button
                v-else
                class="action-button thick button-clear-white"
                :disabled="!$can('pub_disable_anything_in_mediakit')"
                @click.stop="$emit('enable')"
                >Enable
              </el-button>
            </div>
          </slot>
        </div>
      </template>

      <template slot="banner">
        <div v-if="!enabled" class="disabled pad-xxs">
          <slot name="disabled-text">
            Product Disabled
          </slot>
        </div>
      </template>
    </picture-card>
  </div>
</template>

<script>
import LoadingImage from '@/components/Core/Loading/LoadingImage';
import PictureCard from '@/components/Core/PictureCard';
import TooltipIcon from '@/components/Core/TooltipIcon';

import { cog1, disable, docs, frownO, trashEmpty } from '@/vendor/icons';

export default {
  components: {
    LoadingImage,
    PictureCard,
    TooltipIcon
  },
  props: {
    saving: Boolean,
    enabled: Boolean,
    canCopy: Boolean,
    canDelete: Boolean,
    pictures: { type: Array, default: () => [] },
    pictureHeight: {
      type: Number,
      default: 11
    },
    settingsColumn: Boolean
  },

  data() {
    return {
      // Icons
      cog1,
      disable,
      docs,
      frownO,
      trashEmpty
    };
  },

  computed: {
    showSettings() {
      return this.settingsColumn !== false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.is-saving {
  opacity: 0.7;
}

.empty-picture {
  padding-top: 2.5em;
}

.action {
  display: inline-block;
  padding: 0.5em 0.2em;
  font-size: 1.2em;
  color: white;

  &:hover {
    opacity: 0.5;
  }
}

.action-button {
  min-width: 10em;

  &:hover {
    color: white;
  }
}

.disabled {
  background: $color-red;
  color: white;
  text-align: center;
}
</style>
