<template>
  <property-mutation :medium-id="medium.id">
    <template slot-scope="{ isSaving, createProperty, updateProperty }">
      <edit-dialog
        :title="title"
        :is-saving="isSaving"
        :is-disabled="!changes"
        @save="onSaveProperty(createProperty, updateProperty)"
        @close="$emit('close')"
      >
        <mediakit-property-settings
          :medium="medium"
          :property="property"
          @change="changes = $event"
        />
      </edit-dialog>
    </template>
  </property-mutation>
</template>

<script>
import EditDialog from '@/components/Core/EditDialog';
import MediakitPropertySettings from './MediakitPropertySettings';
import { PropertyMutation } from '@/components/Mutations';

export default {
  components: {
    EditDialog,
    MediakitPropertySettings,

    // Queries
    PropertyMutation
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    property: { type: Object, default: null }
  },

  data() {
    return {
      changes: null
    };
  },

  computed: {
    isCreating() {
      return !this.property.id;
    },

    title() {
      return this.isCreating
        ? 'Create New Property'
        : this.property.name + ' Settings';
    }
  },
  methods: {
    async onSaveProperty(createProperty, updateProperty) {
      if (this.isCreating) {
        await createProperty(this.changes);
      } else {
        await updateProperty(this.property, this.changes);
      }

      this.$emit('close');
    }
  }
};
</script>
