<template>
  <el-select
    :key="selectKey"
    :placeholder="placeholder"
    multiple
    :multiple-limit="multipleLimit"
    :value="sortedSelected"
    allow-create
    filterable
    :disabled="disabled"
    :loading="!types || isLoading"
    default-first-option
    class="w-full"
    value-key="type.id"
    @change="change"
  >
    <template slot="tag-text" slot-scope="{ item }">
      {{ item.currentLabel }}
      <span v-if="item.disabled" class="pad-left-sm">
        <curated-lock-icon />
      </span>
    </template>

    <curated-option-groups :options="formattedTypes" nested-type>
      <el-option
        :key="`fdt-${type.type.id}`"
        slot-scope="{ option: type }"
        :label="type.type.value"
        :value="type"
        :disabled="isLocked(type)"
      />
    </curated-option-groups>
  </el-select>
</template>

<script>
import { find, sortBy, uniqueId } from 'lodash';

import CuratedLockIcon from '@/components/Core/MediaKit/CuratedLockIcon';
import CuratedOptionGroups from '@/components/Core/CuratedOptionGroups';

export default {
  components: {
    CuratedOptionGroups,
    CuratedLockIcon
  },
  props: {
    placeholder: {
      type: String,
      default: 'Choose Type'
    },
    types: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    multipleLimit: {
      type: Number,
      default: 0
    },
    disabled: Boolean,
    isLoading: Boolean
  },

  data() {
    return {
      customTypes: []
    };
  },

  computed: {
    /**
     * This forces the el-select to re-render when the list of values changes, as the option tags don't seem to update properly
     */
    selectKey() {
      return uniqueId('mst-' + this.customTypes.length);
    },

    sortedSelected() {
      return sortBy(this.value, a => !a.is_template);
    },

    formattedTypes() {
      let types = [];

      if (this.types) {
        for (let type of this.types) {
          types.push({
            type
          });
        }
      }

      // Add in any recent user created types (if they are not already added)
      for (let type of this.customTypes) {
        if (!find(types, { type: { id: type.type.id } })) {
          types.push(type);
        }
      }

      return types;
    }
  },

  methods: {
    isLocked({ value }) {
      let locked = find(this.value, { type: { value } });

      return locked ? locked.is_template : false;
    },

    change(value) {
      let selectedTypes = [];

      for (let item of value) {
        if (typeof item === 'string') {
          let newType = {
            is_template: false,
            type: {
              id: uniqueId('type-'),
              value: item,
              is_curated: false,

              // For non-MediumType objects, we need this field
              medium_type_id: '',

              // If this is a MediumType, we need these fields
              propertyTypes: [],
              collectionTypes: [],
              productTypes: []
            }
          };

          // Add new type to the selected types list
          selectedTypes.push(newType);

          // Add the new type as a customType so it shows up in the list
          this.customTypes.push(newType);
        } else {
          selectedTypes.push(item);
        }
      }

      this.$emit('input', selectedTypes);
      this.$emit('change', selectedTypes);
    }
  }
};
</script>
