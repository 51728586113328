<template>
  <div>
    <el-option-group
      v-for="group in groups"
      :key="`cog-${group.key}`"
      :label="group.label"
    >
      <template v-for="option in group.options">
        <slot :option="option" />
      </template>
    </el-option-group>
  </div>
</template>

<script>
import { filter, uniqueId } from 'lodash';

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    curatedLabel: {
      type: String,
      default: 'flytedesk Types'
    },
    customLabel: {
      type: String,
      default: 'My Custom Types'
    },
    nestedType: Boolean
  },

  computed: {
    groups() {
      return [
        {
          key: uniqueId(this.curatedLabel),
          label: this.curatedLabel,
          options: this.curatedOptions
        },
        {
          key: uniqueId(this.customLabel),
          label: this.customLabel,
          options: this.customOptions
        }
      ];
    },

    customOptions() {
      return filter(
        this.options,
        this.nestedType
          ? { type: { is_curated: false } }
          : { is_curated: false }
      );
    },

    curatedOptions() {
      return filter(
        this.options,
        this.nestedType ? { type: { is_curated: true } } : { is_curated: true }
      );
    }
  }
};
</script>
