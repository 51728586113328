<template>
  <div class="mediakit-collection-card">
    <template v-if="isNew">
      <mediakit-new-card @edit="collectionToEdit = {}">
        Create New Collection
      </mediakit-new-card>
    </template>

    <template v-else>
      <mediakit-card
        :pictures="pictures"
        :enabled="collection.is_enabled"
        can-copy
        :can-delete="!collection.is_template"
        @edit="collectionToEdit = collection"
        @enable="$emit('update', { is_enabled: true })"
        @disable="$emit('update', { is_enabled: false })"
        @copy="$emit('copy')"
        @delete="$emit('delete')"
        @view="$emit('view')"
      >
        <h3 class="text-md">
          {{ collection.name }}
          <mediakit-super-icon v-if="collection.is_super" />
        </h3>
        <ul class="horizontal-list text-dark-silver text-md">
          <li>{{ productCount }} Products</li>
        </ul>

        <template slot="disabled-text">
          Collection Disabled
        </template>
      </mediakit-card>
    </template>

    <mediakit-collection-dialog
      v-if="collectionToEdit"
      :medium="medium"
      :property="property"
      :collection="collectionToEdit"
      @close="collectionToEdit = null"
    />
  </div>
</template>

<script>
import { flattenPath } from '@/utils/helpers';

import MediakitCard from '../Common/MediakitCard';
import MediakitNewCard from '../Common/MediakitNewCard';
import MediakitCollectionDialog from './MediakitCollectionDialog';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';

export default {
  components: {
    MediakitSuperIcon,
    MediakitCard,
    MediakitNewCard,
    MediakitCollectionDialog
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    collection: { type: Object, default: null },
    isNew: Boolean
  },

  data() {
    return {
      collectionToEdit: null
    };
  },

  computed: {
    productCount() {
      return this.collection.products.length;
    },

    pictures() {
      return flattenPath(this.collection.products, ['primaryPhoto']).slice(
        0,
        3
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.mediakit-collection-card {
  transition: all 0.5s;
  width: 19em;
}
</style>
