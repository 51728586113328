<template>
  <div class="flex collection-reach-number">
    <div :data-value="reachValue" class="p-3 w-2/5">
      <el-input
        v-model="reachValue"
        v-mask.number
        placeholder="00,000"
        :disabled="locked"
      >
        <curated-lock-icon v-if="locked" slot="suffix" class="el-input__icon" />
      </el-input>
    </div>
    <div class="p-3 w-3/5">
      <el-date-picker
        v-if="!reach.is_primary"
        v-model="dateRange"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :picker-options="{ disabledDate }"
        format="MM/dd/yyyy"
        @range-change="changeRange"
      />
    </div>

    <a
      v-if="!reach.is_primary"
      class="delete is-left text-red"
      @click="$emit('delete')"
    >
      <icon :icon="trashEmpty" />
    </a>
  </div>
</template>

<script>
import moment from 'moment';
import CuratedLockIcon from '@/components/Core/MediaKit/CuratedLockIcon';
import { trashEmpty } from '@/vendor/icons';
import { dateDb } from '@/utils/filters';

export default {
  components: {
    CuratedLockIcon
  },
  props: {
    reach: {
      type: Object,
      required: true
    },
    reaches: { type: Array, default: () => [] },
    locked: Boolean
  },

  data() {
    return {
      reachValue: this.reach.value,
      dateRange: this.reach.date_start
        ? [this.reach.date_start, this.reach.date_end]
        : [],
      selectedStartDate: false,

      // Icons
      trashEmpty
    };
  },

  computed: {
    /**
     * The other related reach objects that are not the current reach object
     *
     * @returns Array
     */
    otherReaches() {
      let otherReaches = [];

      for (let reach of this.reaches) {
        // Only check other reach objects and only if they have a date range
        if (reach.id !== this.reach.id && reach.date_start) {
          otherReaches.push(reach);
        }
      }

      return otherReaches;
    },

    /**
     * The closest start date of another reach object to the currently selected start of the date range
     *
     * @returns Moment
     */
    nextStartDate() {
      let selectedDate = moment(this.selectedStartDate);
      let disabledMinDate = null;

      for (let reach of this.otherReaches) {
        let dateStart = moment(reach.date_start);

        if (selectedDate.isBefore(dateStart)) {
          // If the new dateStart is before the current disabled min date, then set the dateStart as the new minimum
          if (!disabledMinDate || dateStart.isBefore(disabledMinDate)) {
            disabledMinDate = dateStart;
          }
        }
      }

      return disabledMinDate;
    },

    /**
     * The closest end date of another reach object to the currently selected start of the date range
     *
     * @returns Moment
     */
    previousEndDate() {
      let selectedDate = moment(this.selectedStartDate);
      let disabledMaxDate = null;

      // Disable any selectedDates that are inside the range of another reach object
      for (let reach of this.otherReaches) {
        let dateEnd = moment(reach.date_end);

        if (selectedDate.isAfter(dateEnd)) {
          // If the new dateStart is before the current disabled min date, then set the dateStart as the new minimum
          if (!disabledMaxDate || dateEnd.isAfter(disabledMaxDate)) {
            disabledMaxDate = dateEnd;
          }
        }
      }

      return disabledMaxDate;
    }
  },
  watch: {
    reachValue() {
      this.change();
    },
    dateRange() {
      this.change();
    }
  },

  methods: {
    disabledDate(date) {
      if (this.reaches) {
        date = moment(date);

        if (this.selectedStartDate) {
          // Disable the date if it is after the start of the closest chosen date range
          if (date.isSameOrAfter(this.nextStartDate)) {
            return true;
          } else if (date.isSameOrBefore(this.previousEndDate)) {
            // Disable the date if it is before the end of the closest chosen date range
            return true;
          }
        } else {
          // Disable any selectedDates that are inside the range of another reach object
          for (let reach of this.reaches) {
            // Only check other reach objects and only if they have a date range
            if (reach.id !== this.reach.id && reach.date_start) {
              if (
                date.isSameOrAfter(reach.date_start) &&
                date.isSameOrBefore(reach.date_end)
              ) {
                return true;
              }
            }
          }
        }
      }

      return false;
    },

    changeRange(range) {
      this.selectedStartDate = range.minDate;
    },

    change() {
      let reach = {
        id: this.reach.id,
        value: this.reachValue
      };

      if (this.dateRange && this.dateRange.length > 0) {
        reach.date_start = dateDb(this.dateRange[0]);
        reach.date_end = dateDb(this.dateRange[1]);
      } else {
        reach.date_start = null;
        reach.date_end = null;
      }

      this.selectedStartDate = false;
      this.$emit('change', reach);
    }
  }
};
</script>

<style lang="scss" scoped>
.delete {
  position: absolute;
  top: 0.75em;
  left: -1.7em;
  padding: 0.7em;
  opacity: 0;
  transition: all 0.3s;
}

.collection-reach-number {
  position: relative;
  margin-bottom: 0;

  &:hover {
    .delete {
      opacity: 1;
    }
  }
}
</style>
