<template>
  <fulfillment-method-mutation @data="afterSave">
    <template
      slot-scope="{ loading, createFulfillmentMethod, updateFulfillmentMethod }"
    >
      <el-form
        ref="form"
        :model="editable"
        :rules="rules"
        class="fulfillment-method-settings"
        :disabled="isDisabled"
      >
        <div class="fulfillment-section">
          <div class="flex -mx-3 -mt-3 mb-3">
            <div class="block p-3 flex-1">
              <custom-el-form-item
                label="Template"
                label-class="font-semibold text-md"
                no-click-focus
                :disabled="isDisabled"
              >
                <fulfillment-methods-query
                  @data="fulfillmentMethods = $event.fulfillmentMethods"
                >
                  <template slot-scope="{ isLoading }">
                    <el-select
                      v-model="selectedFulfillmentId"
                      filterable
                      class="w-full"
                      :loading="!!isLoading"
                      placeholder="Create New Template"
                      :popper-append-to-body="false"
                      @change="setEditable(selectedTemplate)"
                    >
                      <curated-option-groups
                        curated-label="flytedesk Templates"
                        custom-label="My Custom Templates"
                        :options="fulfillmentMethods"
                      >
                        <el-option
                          :key="`option-${option.id}`"
                          slot-scope="{ option }"
                          :value="option.id"
                          value-key="id"
                          :label="option.name"
                        />
                      </curated-option-groups>
                    </el-select>
                  </template>
                </fulfillment-methods-query>
              </custom-el-form-item>
            </div>

            <div class="block p-3 flex-1">
              <custom-el-form-item
                label="Display Name"
                tooltip="The name"
                label-class="font-semibold text-md"
                prop="display_name"
                :disabled="isDisabled"
              >
                <el-input v-model="editable.display_name" />
              </custom-el-form-item>
            </div>
          </div>

          <custom-el-form-item
            label="Description"
            label-class="font-semibold text-md"
            prop="description"
            :disabled="isDisabled"
          >
            <el-input
              v-model="editable.description"
              type="textarea"
              placeholder="Use this field to give your buyers more information about this fulfillment method"
              rows="4"
            />
          </custom-el-form-item>
        </div>

        <div class="fulfillment-section">
          <div class="flex pad-vert-sm">
            <div class="block p-3 flex-1">
              <custom-el-form-item
                type="inline"
                label="Assets Required:"
                label-class="font-semibold text-md"
                prop="requires_assets"
              >
                <el-switch
                  v-model="editable.requires_assets"
                  active-text="Yes"
                  inactive-text="No"
                />
              </custom-el-form-item>

              <custom-el-form-item
                label="Recommended File Types:"
                label-class="font-semibold text-md"
                prop="recommendedFileTypes"
                class="margin-top recommended-file-types"
                no-click-focus
              >
                <el-checkbox-group
                  v-model="editable.recommendedFileTypes"
                  :disabled="!editable.requires_assets"
                >
                  <el-checkbox
                    v-for="fileType in fileTypes"
                    :key="`file-type-${fileType}`"
                    :value="fileType"
                    :label="fileType"
                    class="w-1/6 pad-vert-xxs"
                  />
                </el-checkbox-group>
              </custom-el-form-item>
            </div>

            <div class="block p-3 flex-1">
              <custom-el-form-item
                type="columns"
                label="Requires Approval:"
                tooltip="The Publisher must first approve every ad before it is accepted to run."
                label-class="font-semibold text-md whitespace-no-wrap"
                middle
                prop="requires_acceptance"
              >
                <el-switch
                  v-model="editable.requires_acceptance"
                  active-text="Yes"
                  inactive-text="No"
                />
              </custom-el-form-item>

              <custom-el-form-item
                type="columns"
                label="Shipping Required:"
                label-class="font-semibold text-md"
                middle
                prop="requires_shipping"
                class="margin-top"
              >
                <el-switch
                  v-model="editable.requires_shipping"
                  active-text="Yes"
                  inactive-text="No"
                />
              </custom-el-form-item>

              <el-input
                v-model="editable.shipping_address"
                :disabled="!editable.requires_shipping"
                :placeholder="
                  editable.requires_shipping
                    ? 'Enter Shipping Address'
                    : 'Shipping Not Required'
                "
                class="margin-top-sm"
              />

              <custom-el-form-item
                type="columns"
                label="Allow Text:"
                label-class="font-semibold text-md"
                middle
                prop="requires_text"
                class="margin-top"
              >
                <el-switch
                  v-model="editable.requires_text"
                  active-text="Yes"
                  inactive-text="No"
                />
              </custom-el-form-item>

              <custom-el-form-item
                type="columns"
                :label="'Supplier responsible\nfor printing:'"
                label-class="font-semibold text-md"
                middle
                prop="requires_printing"
                class="margin-top"
              >
                <el-switch
                  v-model="editable.requires_printing"
                  active-text="Yes"
                  inactive-text="No"
                />
              </custom-el-form-item>
            </div>
          </div>
        </div>

        <div class="fulfillment-section">
          <custom-el-form-item
            type="inline"
            label="Deadline"
            label-class="font-semibold text-md"
            middle
            prop="deadline_days"
          >
            <el-input
              v-mask.integer="{ min: 1 }"
              :value="editable.deadline_days"
              class="is-width-xs"
              placeholder="0"
              @input="editable.deadline_days = +$event"
            />
            <div class="is-inline-center pad-left-sm">
              business days before run
            </div>
          </custom-el-form-item>
        </div>

        <div class="fulfillment-section">
          <custom-el-form-item
            type="inline"
            label="Fee"
            label-class="font-semibold text-md"
            middle
            prop="price"
          >
            <el-input
              v-model="editable.price"
              v-mask.currency
              class="is-width-xs"
              placeholder="$0.00"
            />
            <div class="is-inline-center pad-left-sm">per order</div>
          </custom-el-form-item>
        </div>

        <div class="fulfillment-section text-right">
          <template v-if="!loading">
            <el-button class="button-white" @click="$emit('close')">
              Cancel
            </el-button>
            <el-button
              class="button-blue"
              @click="save(createFulfillmentMethod, updateFulfillmentMethod)"
            >
              Select
              <template v-if="isModified">
                &amp; Save Changes
              </template>
            </el-button>
          </template>

          <loading-button v-else />
        </div>

        <el-dialog
          title="Save Template As..."
          :visible.sync="showSaveAsDialog"
          width="30em"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
        >
          <div>
            What would you like to save your new Fulfillment Method template as?
          </div>

          <el-input
            v-model="editable.name"
            placeholder="Template Name"
            class="margin-top-sm"
          />

          <div class="flex -mx-3 mt-4 -mb-3">
            <template v-if="!loading">
              <div class="block p-3 flex-1">
                <el-button
                  class="button-white w-full"
                  @click="showSaveAsDialog = false"
                  >Cancel
                </el-button>
              </div>
              <div class="block p-3 flex-1">
                <el-button
                  class="button-blue w-full"
                  @click="createFulfillmentMethod(editable)"
                  >Save
                </el-button>
              </div>
            </template>

            <template v-else>
              <loading-button class="block p-3 flex-1" />
            </template>
          </div>
        </el-dialog>

        <el-dialog
          title="Overwrite Template?"
          :visible.sync="showOverwriteDialog"
          width="30em"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
        >
          <div>
            Do you want to overwrite the template <b>{{ editable.name }}</b
            >?
          </div>

          <div class="text-red">
            Warning! This will change the template for all products that are
            using this template.
          </div>

          <div class="flex -mx-3 mt-4 -mb-3">
            <template v-if="!loading">
              <div class="block p-3 flex-1">
                <el-button
                  class="button-white w-full"
                  @click="
                    (showSaveAsDialog = true) && (showOverwriteDialog = false)
                  "
                >
                  No
                </el-button>
              </div>
              <div class="block p-3 flex-1">
                <el-button
                  class="button-blue w-full"
                  @click="updateFulfillmentMethod(editable, editable)"
                >
                  Overwrite {{ editable.name }}
                </el-button>
              </div>
            </template>

            <template v-else>
              <loading-button class="block p-3" />
            </template>
          </div>
        </el-dialog>
      </el-form>
    </template>
  </fulfillment-method-mutation>
</template>

<script>
import { cloneDeep, extend, find, isEqual } from 'lodash';

import CuratedOptionGroups from '@/components/Core/CuratedOptionGroups';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import { FulfillmentMethodMutation } from '@/components/Mutations';
import { FulfillmentMethodsQuery } from '@/components/Queries';

export default {
  name: 'FulfillmentMethodSettings',

  components: {
    CustomElFormItem,
    CuratedOptionGroups,
    LoadingButton,

    // Queries
    FulfillmentMethodMutation,
    FulfillmentMethodsQuery
  },
  props: {
    isDisabled: Boolean,
    fulfillment: {
      type: Object,
      required: true
    }
  },

  data() {
    let editable = {
      name: '',
      is_curated: false,
      display_name: '',
      description: '',
      requires_assets: false,
      requires_shipping: false,
      requires_text: false,
      requires_printing: false,
      requires_acceptance: true,
      recommendedFileTypes: [],
      shipping_address: '',
      deadline_days: 5,
      price: 0,

      ...cloneDeep(this.fulfillment)
    };

    this.format(editable);

    return {
      editable,
      showSaveAsDialog: false,
      showOverwriteDialog: false,
      selectedFulfillmentId: this.fulfillment.id,
      fulfillmentMethods: [],

      rules: {
        display_name: [
          {
            required: true,
            trigger: 'blur',
            message: 'Display Name is required.'
          }
        ]
      }
    };
  },

  computed: {
    isCreating() {
      return (
        !this.selectedTemplate ||
        this.selectedTemplate.name !== this.editable.name
      );
    },

    fileTypes() {
      return [
        'JPEG',
        'PNG',
        'PDF',
        'CSV',
        'GIF',
        'EPS',
        'SVG',
        'TIF',
        'Mp4',
        'MOV',
        'Mp3'
      ];
    },

    selectedTemplate() {
      return this.format(
        cloneDeep(
          find(this.fulfillmentMethods, { id: this.selectedFulfillmentId })
        )
      );
    },

    isModified() {
      return !isEqual(this.selectedTemplate, this.editable);
    }
  },

  methods: {
    format(fields) {
      if (fields) {
        // Make sure the price is a number, and if 0, set to empty string
        fields.price = +fields.price || '';
      }

      return fields;
    },

    setEditable(editable) {
      extend(this.editable, editable);

      this.format(this.editable);
    },

    save() {
      this.$refs.form.validate(isValid => {
        if (isValid) {
          if (this.isCreating) {
            // If We are creating a new Template, ask the user to give it a name
            this.showSaveAsDialog = true;
          } else {
            // If we are modifying an existing template, check if it is curated or a user created
            if (this.isModified) {
              if (this.editable.is_curated) {
                // If it is a curated template, ask the user to save the template with a new name
                this.showSaveAsDialog = true;
              } else {
                // If the user has changed one of their templates, ask them if they want to overwrite the existing template
                this.showOverwriteDialog = true;
              }
            } else {
              // If the user chose an existing template and didn't make any changes, just emit the change now, nothing to save
              this.$emit('change', this.editable);
            }
          }
        }
      });
    },

    afterSave(data) {
      this.$emit(
        'change',
        data.mutateMediakit.mutateFulfillmentMethod.fulfillmentMethod
      );
      this.showOverwriteDialog = false;
      this.showSaveAsDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.fulfillment-method-settings {
  max-height: 85vh;
  overflow-y: auto;
}

.fulfillment-section {
  padding: 1em;

  & + .fulfillment-section {
    border-top: 1px solid $color-gray;
  }
}

.recommended-file-types {
  max-width: 20em;
}
</style>
