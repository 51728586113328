var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mediakit-medium pt-5 relative"},[_c('mediakit-query',{attrs:{"medium-id":_vm.$route.params.id},on:{"data":function($event){_vm.medium = $event.medium}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.medium)?_c('div',[_c('sticky-header',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"block p-3 flex-1"},[_c('h2',[_vm._v(" "+_vm._s(_vm.medium.name)+" "),(_vm.medium.is_super)?_c('mediakit-super-icon'):_vm._e(),_c('span',{staticClass:"pad-left-xs"},[_c('tooltip-icon',{attrs:{"is-disabled":!_vm.$can('pub_edit_mediakit_settings_or_schedules'),"icon":_vm.cog1,"icon-class":"icon-md text-dark-silver cursor-pointer","reference-class":"pl-2"},on:{"click":function($event){_vm.isEditing = true}}})],1)],1),_c('div',{staticClass:"sub-header"},[_c('ul',{staticClass:"horizontal-list text-md"},[_c('li',[_vm._v(_vm._s(_vm.propertyCount)+" Properties")]),_c('li',[_vm._v(_vm._s(_vm.collectionCount)+" Collections")]),_c('li',[_vm._v(_vm._s(_vm.productCount)+" Products")])])])]),_c('div',{staticClass:"block p-3 flex-1 text-right"},[_c('medium-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateMedium = ref.updateMedium;
return [_c('custom-el-switch',{key:"medium-status",staticClass:"toggle-green-red",attrs:{"disabled":!_vm.$can('pub_disable_anything_in_mediakit'),"left":"","value":_vm.medium.is_enabled,"active-text":"This medium is enabled and visible to buyers","inactive-text":"This medium is disabled and not visible to buyers"},on:{"change":function($event){return updateMedium(_vm.medium, { is_enabled: $event })}}})]}}],null,true)})],1)])]),_c('div',{staticClass:"mediakit-content m-8"},[_c('mediakit-card-list',{attrs:{"items":_vm.medium.properties},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var item = ref.item;
return _c('property-mutation',{attrs:{"medium-id":_vm.medium.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isDeleting = ref.isDeleting;
var updateProperty = ref.updateProperty;
var deleteProperty = ref.deleteProperty;
return [_c('mediakit-property-card',{class:{ 'opacity-30': isDeleting },attrs:{"medium":_vm.medium,"property":item},on:{"view":function($event){return _vm.goToProperty(item)},"update":function($event){return updateProperty(item, $event)},"delete":function($event){return deleteProperty(item)}}})]}}],null,true)})}}],null,true)},[_c('template',{slot:"enabled-text"},[_vm._v(" Enabled Properties ")]),_c('template',{slot:"disabled-text"},[_vm._v(" Disabled Properties ")]),_c('mediakit-property-card',{attrs:{"slot":"new-card","medium":_vm.medium,"is-new":""},slot:"new-card"})],2)],1)],1):(isLoading)?_c('loader-content',{staticClass:"p-5"},[_c('loader-heading',{staticClass:"is-width-xl p-3",attrs:{"thickness":1.2}}),_c('loader-grid',{staticClass:"margin-top",attrs:{"item-width":19,"column-class":"w-1/4"}})],1):_c('el-alert',{staticClass:"text-center",attrs:{"type":"error","title":"Medium was not found","closable":false}})],1)]}}])}),(_vm.isEditing)?_c('mediakit-medium-dialog',{attrs:{"medium":_vm.medium},on:{"close":function($event){_vm.isEditing = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }