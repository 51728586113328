<template>
  <div class="mediakit-property-card">
    <template v-if="isNew">
      <mediakit-new-card @edit="propertyToEdit = {}">
        Create New Property
      </mediakit-new-card>
    </template>

    <template v-else>
      <mediakit-card
        :pictures="pictures"
        :enabled="property.is_enabled"
        :can-delete="!property.is_template"
        @edit="propertyToEdit = property"
        @enable="$emit('update', { is_enabled: true })"
        @disable="$emit('update', { is_enabled: false })"
        @copy="$emit('copy')"
        @delete="$emit('delete')"
        @view="$emit('view')"
      >
        <h3 class="text-md">
          {{ property.name }}
          <mediakit-super-icon v-if="property.is_super" />
        </h3>
        <ul class="horizontal-list text-dark-silver text-md">
          <li>{{ collectionCount }} Collections</li>
          <li>{{ productCount }} Products</li>
        </ul>

        <template slot="disabled-text">
          Property Disabled
        </template>
      </mediakit-card>
    </template>

    <mediakit-property-dialog
      v-if="propertyToEdit"
      :medium="medium"
      :property="propertyToEdit"
      @close="propertyToEdit = null"
    />
  </div>
</template>

<script>
import { countPath, flattenPath } from '@/utils/helpers';

import MediakitCard from '../Common/MediakitCard';
import MediakitNewCard from '../Common/MediakitNewCard';
import MediakitPropertyDialog from './MediakitPropertyDialog';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';

export default {
  components: {
    MediakitSuperIcon,
    MediakitCard,
    MediakitNewCard,
    MediakitPropertyDialog
  },
  props: {
    medium: { type: Object, default: null },
    property: { type: Object, default: null },
    isNew: Boolean
  },

  data() {
    return {
      propertyToEdit: false
    };
  },

  computed: {
    collectionCount() {
      return this.property.collections.length;
    },

    productCount() {
      return countPath(this.property.collections, ['products']);
    },

    pictures() {
      return flattenPath(this.property.collections, [
        'products',
        'primaryPhoto'
      ]).slice(0, 3);
    }
  }
};
</script>

<style lang="scss" scoped>
.mediakit-property-card {
  width: 19em;
}
</style>
