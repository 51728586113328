<template>
  <div class="mediakit-medium pt-5 relative">
    <mediakit-query
      :medium-id="$route.params.id"
      @data="medium = $event.medium"
    >
      <template slot-scope="{ isLoading }">
        <transition name="fade" mode="out-in">
          <div v-if="medium">
            <sticky-header>
              <div class="flex">
                <div class="block p-3 flex-1">
                  <h2>
                    {{ medium.name }}
                    <mediakit-super-icon v-if="medium.is_super" />
                    <span class="pad-left-xs">
                      <tooltip-icon
                        :is-disabled="
                          !$can('pub_edit_mediakit_settings_or_schedules')
                        "
                        :icon="cog1"
                        icon-class="icon-md text-dark-silver cursor-pointer"
                        reference-class="pl-2"
                        @click="isEditing = true"
                      />
                    </span>
                  </h2>

                  <div class="sub-header">
                    <ul class="horizontal-list text-md">
                      <li>{{ propertyCount }} Properties</li>
                      <li>{{ collectionCount }} Collections</li>
                      <li>{{ productCount }} Products</li>
                    </ul>
                  </div>
                </div>

                <div class="block p-3 flex-1 text-right">
                  <medium-mutation>
                    <template slot-scope="{ updateMedium }">
                      <custom-el-switch
                        key="medium-status"
                        :disabled="!$can('pub_disable_anything_in_mediakit')"
                        class="toggle-green-red"
                        left
                        :value="medium.is_enabled"
                        active-text="This medium is enabled and visible to buyers"
                        inactive-text="This medium is disabled and not visible to buyers"
                        @change="updateMedium(medium, { is_enabled: $event })"
                      />
                    </template>
                  </medium-mutation>
                </div>
              </div>
            </sticky-header>

            <div class="mediakit-content m-8">
              <mediakit-card-list :items="medium.properties">
                <template slot="enabled-text">
                  Enabled Properties
                </template>
                <template slot="disabled-text">
                  Disabled Properties
                </template>

                <mediakit-property-card
                  slot="new-card"
                  :medium="medium"
                  is-new
                />

                <property-mutation
                  slot="card"
                  slot-scope="{ item }"
                  :medium-id="medium.id"
                >
                  <template
                    slot-scope="{ isDeleting, updateProperty, deleteProperty }"
                  >
                    <mediakit-property-card
                      :class="{ 'opacity-30': isDeleting }"
                      :medium="medium"
                      :property="item"
                      @view="goToProperty(item)"
                      @update="updateProperty(item, $event)"
                      @delete="deleteProperty(item)"
                    />
                  </template>
                </property-mutation>
              </mediakit-card-list>
            </div>
          </div>

          <loader-content v-else-if="isLoading" class="p-5">
            <loader-heading class="is-width-xl p-3" :thickness="1.2" />
            <loader-grid
              :item-width="19"
              column-class="w-1/4"
              class="margin-top"
            />
          </loader-content>

          <el-alert
            v-else
            type="error"
            title="Medium was not found"
            class="text-center"
            :closable="false"
          />
        </transition>
      </template>
    </mediakit-query>

    <mediakit-medium-dialog
      v-if="isEditing"
      :medium="medium"
      @close="isEditing = false"
    />
  </div>
</template>

<script>
import { countPath } from '@/utils/helpers';
import { cog1 } from '@/vendor/icons';

import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import MediakitCardList from '../Common/MediakitCardList';
import MediakitMediumDialog from './MediakitMediumDialog';
import MediakitPropertyCard from '../Property/MediakitPropertyCard';
import TooltipIcon from '@/components/Core/TooltipIcon';
import StickyHeader from '@/components/Core/Layout/StickyHeader.vue';
import {
  LoaderContent,
  LoaderGrid,
  LoaderHeading
} from '@/components/Core/Loaders';
import { MediakitQuery } from '@/components/Queries';
import { MediumMutation, PropertyMutation } from '@/components/Mutations';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';

export default {
  components: {
    MediakitSuperIcon,
    CustomElSwitch,
    LoaderContent,
    LoaderGrid,
    LoaderHeading,
    MediakitCardList,
    MediakitMediumDialog,
    MediakitPropertyCard,
    MediakitQuery,
    MediumMutation,
    TooltipIcon,
    PropertyMutation,
    StickyHeader
  },

  data() {
    return {
      medium: null,
      isEditing: false,

      // Icons
      cog1
    };
  },

  computed: {
    propertyCount() {
      return this.medium.properties.length;
    },

    collectionCount() {
      return countPath(this.medium.properties, ['collections']);
    },

    productCount() {
      return countPath(this.medium.properties, ['collections', 'products']);
    }
  },

  methods: {
    /**
     * Navigate to the requested Property (or Collection if there is only 1 collection)
     *
     * @param property
     */
    goToProperty(property) {
      if (property && property.collections.length === 1) {
        let collection = property.collections[0];

        this.$router.push({
          name: 'supplier.mediakit.collection',
          params: { id: collection.id }
        });
      } else {
        this.$router.push({
          name: 'supplier.mediakit.property',
          params: { id: property.id }
        });
      }
    }
  }
};
</script>
