<template>
  <div class="mediakit-product-card">
    <template v-if="isNew">
      <mediakit-new-card class="product-new-card" @edit="$emit('edit')">
        Create New Product
      </mediakit-new-card>
    </template>

    <mediakit-card
      v-else
      :saving="saving"
      :pictures="pictures"
      :picture-height="13.7"
      :settings-column="false"
      :enabled="product.is_enabled"
      can-copy
      :can-delete="!product.is_template"
      @view="$emit('edit')"
      @edit="$emit('edit')"
      @enable="$emit('update', { is_enabled: true })"
      @disable="$emit('update', { is_enabled: false })"
      @copy="$emit('copy')"
      @delete="$emit('delete')"
    >
      <div class="product-info">
        <div class="flex m-0">
          <div class="block p-0">
            <h3 class="text-md">
              {{ product.name }}
              <mediakit-super-icon v-if="product.is_super" />
            </h3>
            <div class="text-pewter text-sm">{{ product.sku }}</div>
          </div>
          <div class="block flex-1 text-right p-0">
            <div class="price-range text-dark-silver text-md">
              <min-max-price
                :rate-sheets="activeVariantRateSheets"
                :collection="collection"
              />
            </div>
          </div>
        </div>
        <div>
          <ul class="ml-.5 horizontal-list text-dark-silver text-md">
            <li>
              {{ variantCount || 'No' }}
              {{ variantCount == 1 ? 'variant' : 'variants' }}
            </li>
          </ul>
        </div>
        <div
          v-if="inventoryReport && $can('manage_national_inventory')"
          class="mt-2"
        >
          <el-popover trigger="hover" placement="bottom">
            <div slot="reference">
              <div class="text-sm">
                <inventory-report preview :report="inventoryReport" />
              </div>
            </div>
            <inventory-report :report="inventoryReport" />
          </el-popover>
        </div>
      </div>

      <template slot="disabled-text">
        Product Disabled
      </template>
    </mediakit-card>
  </div>
</template>

<script>
import MediakitCard from '../Common/MediakitCard';
import MediakitNewCard from '../Common/MediakitNewCard';
import MinMaxPrice from '../Common/MinMaxPrice';

import { filter } from 'lodash';
import { flattenPath } from '@/utils/helpers';
import MediakitSuperIcon from '@/components/Supplier/Mediakit/MediakitSuperIcon';
import InventoryReport from '@/components/Supplier/Analytics/InventoryReport';

export default {
  components: {
    InventoryReport,
    MediakitSuperIcon,
    MinMaxPrice,
    MediakitCard,
    MediakitNewCard
  },
  props: {
    collectionId: { type: String, default: null },
    collection: {
      type: Object,
      default: null
    },
    product: { type: Object, default: null },
    inventoryReport: {
      type: Object,
      default: null
    },
    isNew: Boolean,
    saving: Boolean
  },

  computed: {
    pictures() {
      return this.product.primaryPhoto ? [this.product.primaryPhoto] : [];
    },

    variantCount() {
      return this.product.variants.length;
    },

    activeVariants() {
      return filter(this.product.variants, { is_enabled: true });
    },

    activeVariantRateSheets() {
      return flattenPath(this.activeVariants, ['rateSheets']);
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

.price-range {
  line-height: 1em;
}

.mediakit-product-card,
.product-new-card {
  transition: all 0.5s;
  width: 16em;
  min-height: 18em;
}

.mediakit-product {
  cursor: default;
}
</style>
